import { formToJSON } from 'axios'
import React, { useEffect, useState } from 'react'
import { FaArrowCircleLeft } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { CustomCard } from '../../../Components/CustomCard'
import Flex from '../../../Components/Flex'
import BackButton from '../../../Components/Form/BackButton'
import { TopTitle } from '../../../Components/Form/TopTitle'
import request from '../../../utils/request'
import AddForm from './Partials/AddForm'
import Viewplanner from './Partials/Viewplanner'

const AddIncomeplanner = () => {

    const [learnerData, setLearnerData] = useState([])
    const {id} = useParams()
    const navigate  = useNavigate()

    const URL = `income_planner/profile_income_planning_details/${id}/`

    const GetPlanner = (values) => {
        request.get(`${URL}`, values)
            .then(function (response) {
                setLearnerData(response.data?.Table)

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    useEffect(() => {
        GetPlanner();
    }, [])


    return (
        <div>
            <Flex gap={'15px'}>
                <BackButton text={<FaArrowCircleLeft style={{ fontSize: '26px' }} />} onClick={() => navigate(`/View/${id}`)} />
                <TopTitle Heading={'Income Planner'} />
            </Flex>
            <CustomCard>
                <AddForm GetPlanner={GetPlanner} />
                <Viewplanner learnerData={learnerData} />
            </CustomCard>

        </div>
    )
}

export default AddIncomeplanner