import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import Page from '../../Modules/Page/Container/index'
import { adminAuthenticated, advisorAuthenticated, authenticated, authenticated2, authenticated3, superUserAutentciacted } from '../config/user'
import Flex from '../../Components/Flex'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import initializeApp from '../../utils/initializeApp'
import request from '../../utils/request'
import { LogOutSuccess } from '../../Modules/Auth/actions'

const PageFlex = styled(Flex)`
  overflow: hidden;
  `
const AuthPage = ({ isAuthenticated }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [authenticatedRoutes, setAuthenticatedRoutes] = useState([]);

  const UserRole = useSelector((state) => state?.auth?.token?.role)
  const UserRoleSuper = useSelector((state) => state?.auth?.token?.super)
  const SuperUser = useSelector((state) => state?.auth?.token?.role)
    console.log(SuperUser)

  const location = useLocation();
  const from = location.state?.from?.pathname || '/signin'

  useEffect(() => {

    if (isAuthenticated) {
      if (UserRole === 'User') {
        setAuthenticatedRoutes(authenticated);
        navigate('/')
      } else if (SuperUser === 'Advisor') {
        setAuthenticatedRoutes(superUserAutentciacted);
        navigate('/')
      }
      else if (SuperUser === 'Admin') {
        setAuthenticatedRoutes(superUserAutentciacted);
        navigate('/')
      }

      else if (UserRoleSuper === true) {
        setAuthenticatedRoutes(superUserAutentciacted);
        navigate('/')

      }
      else {
        console.log('user');
      }
    }
    else {

      toast('Welcome ePlan Advisors !')
      navigate('/signin')
    }

  }, [isAuthenticated, UserRole, SuperUser, from])


  return (
    <>
      <PageFlex>
        {isAuthenticated && (
          <>
            <Page>
              <Routes>
                {authenticatedRoutes.map(({ routePath, Component }) => {
                  return (
                    <Route
                      key={routePath}
                      path={routePath}
                      element={<Component />}
                    ></Route>
                  )
                })}
              </Routes>
            </Page>
          </>
        )}
      </PageFlex>
    </>

  )
}

export default AuthPage


