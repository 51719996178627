import React, { useEffect, useState } from 'react'
import MyEplanBarChart from './BarChart'
import MyEplanPieChart from './PieChart'
import MyeForm from './FormDetails.js'
import request from '../../utils/request'
import Button from '../../Components/Form/Button'
import { useNavigate, useParams } from 'react-router-dom'
import TableDetails from './BarChart/TableDetails'

const MyEplanTotalDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams()

  const [currentData, setCurrentData] = useState([])
  const [graphtData, setGraphtData] = useState([])

  const GETURL = `e_plan/profile_my_eplan/${id}`

  const GetPlanDetails = () => {
    request.get(`${GETURL}`)
      .then((response) => {
        setCurrentData(response.data?.Table)
        setGraphtData(response.data?.Graph)
      })
      .catch(error => {
        console.log(error);
      })
  }

  useEffect(() => {
    GetPlanDetails()
  }, [])

  return (
    <div>
      <MyEplanPieChart currentData={currentData} graphtData={graphtData} /><br />
      <MyeForm GetPlanDetails={GetPlanDetails} /><br /><br />

      <TableDetails currentData={currentData} />

      {/* <MyEplanBarChart  /> */}
      {/* <Button.Success text={'Back'} onClick={() => navigate('/')} style={{ margin: '20px 0px' }} /> */}

    </div>
  )
}

export default MyEplanTotalDetails