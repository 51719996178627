
import { Col, Tooltip } from 'antd';
import React from 'react';
import { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Flex from '../../../Components/Flex';
import { Row } from '../../../Components/Row';
import { BacCard, DashCard, Tablestyledd, TopBox } from './Style';
import { THEME } from '../../../theme';
import { TableIconHolder } from '../../../Components/Styled';
import { useDispatch, useSelector } from 'react-redux';
import { parographData } from '../../../Assets/DashboardData';
import request from '../../../utils/request';
import { useEffect } from 'react';
import { PlanningGet, RejinPalnningDeatails, setPlanningRules } from '../../PlannningDetailsTotal/actions';
import { setCompanyProfile } from '../../People/actions';

const UserDashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const company = useSelector((state) => state?.companyprofile?.companyprofile) || {};


    const Rule100 = useSelector((state) => state?.planningrules?.planningrules) || {};

    const GetPeople = async () => {
        await request.get(`people/add_people_new/`)
            .then(function (response) {
                dispatch(setCompanyProfile(response.data))
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetPlanning = () => {
        request.get('people/add_people/')
            .then(function (response) {
                dispatch(RejinPalnningDeatails(response.data))
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        GetPlanning();
        GetPeople();
    }, [])

    const authenticateduser = [
        {
            key: 1,
            h1: 'My Profile',
            routePath: 'Home',
            routePath1: 'Viewperson',
            routePath2: 'SuperView',
            description: 'Are you sure to Add Profile',
        },

        {
            key: 2,
            h1: 'My Income',
            routePath: 'Myincome',
            routePath1: 'view_income',

        },
        {
            key: 3,
            h1: 'My Liability',
            routePath: 'Liabilities',
            routePath1: 'View_Liabilities',

        },
        {
            key: 4,
            h1: 'My Assets',
            routePath: 'Assetsmain',
            routePath1: 'view_Assets',


        },
        {
            key: 5,
            h1: 'My NetWorth',
            routePath: 'Networth',
            routePath1: 'Networth',

        },
        {
            key: 6,
            h1: 'Rule of 100',
            routePath: 'Planingdetails',
            routePath1: 'ViewPlanning',


        },
        // {
        //     key: 7,
        //     h1: 'Basic',
        //     routePath: 'Basic',
        //     routePath1: 'BasicView',

        // },

    ]

    return (
        <div>
            <TopBox>
                <h1>THE RULE OF 100</h1>
            </TopBox>
            <Tablestyledd>
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ background: '#38761d', color: 'white' }}>PROTECTED<br />ePLAN Pension</th>
                            <th style={{ background: '#ff0', color: 'black' }}>BANK<br />Bank Money</th>
                            <th style={{ background: '#f00', color: 'white' }}>WALL STREET <br />Growth with Risk</th>
                        </tr>
                        <tr>
                            <th style={{ background: '#93c47d', color: 'white' }}>Safety!</th>
                            <th style={{ background: '#fff2cc', color: 'black' }}>Losing Safely</th>
                            <th style={{ background: '#ea9999', color: 'white' }}>Growth/Risk</th>
                        </tr>
                    </thead>
                    <tbody>
                        {parographData.map(({ protectedcontent, protectedcontent2, bank, bank2, wallstreet, wallstreet2 }, i) => (
                            <tr key={i} >
                                <td>{protectedcontent}<br></br><br></br>{protectedcontent2}</td>
                                <td>{bank}<br></br><br></br>{bank2}</td>
                                <td>{wallstreet}<br></br><br></br>{wallstreet2}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Tablestyledd>

            <div style={{ margin: '20px 0px' }} >
                <Row gutter={[16, 16]}>
                    {authenticateduser && authenticateduser.map(({ h1, routePath, routePath1, key}, i) => {
                        const shouldShowPlusIcon = (routePath === 'Home') && (company && Object.keys(company).length === 0);
                        const planningButton = (routePath === 'Planingdetails') && (Rule100 && Object.keys(Rule100).length === 0);

                        return (
                            <Col key={i} span={24} md={8}>
                                <BacCard>
                                    <h1 onClick={() => navigate(routePath1)}>{h1}</h1>
                                    <Flex end>
                                        {(routePath === 'Home' || routePath === 'Planingdetails') ? (
                                            <TableIconHolder color={THEME.white} size={'22px'}>
                                                {(shouldShowPlusIcon || planningButton) && (
                                                    <AiOutlinePlus
                                                        onClick={() => navigate(routePath)}
                                                    />
                                                )}
                                            </TableIconHolder>
                                        ) :
                                            <TableIconHolder color={THEME.white} size={'22px'}>

                                                <AiOutlinePlus
                                                    onClick={() => navigate(routePath)}
                                                />

                                            </TableIconHolder>

                                        }
                                    </Flex>
                                </BacCard>
                            </Col>
                        );
                    })}
                </Row>

            </div>
        </div>
    );
};

export default UserDashboard;