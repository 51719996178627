import { Col, Form, Row, Upload as Uploded } from 'antd'
import { Children, useState } from 'react';
import { useEffect } from 'react';
import { Modal } from '../../../../Components/Modal';
import request from '../../../../utils/request';
import Button from '../../../../Components/Form/Button';
import Input from '../../../../Components/Form/Input';
import Flex from '../../../../Components/Flex';
import { setCompanyProfile } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { Editperson } from '../../EplanForm/Partials/Editperson';
import { useNavigate, useParams } from 'react-router-dom';
import { Childrens } from '../../../../Assets/DashboardData';
import { Viewcard } from './style';
import { TopTitle } from '../../../../Components/Form/TopTitle';
import { CustomCard } from '../../../../Components/CustomCard';
import { CustomPageFormSubTitle } from '../../../../Components/Form/FormSub';
import BackButton from '../../../../Components/Form/BackButton';
import { FaArrowCircleLeft } from 'react-icons/fa';


export const SuperViewPeople = (record) => {

    const [viewPeople, setViewPeople] = useState({})
    const [childData, setChildData] = useState(Childrens)
    const [adminView, setAdminView] = useState([])

    const { id } = useParams()

    const dispatch = useDispatch();
    const navigate = useNavigate();
    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const URL = 'people/edit_people_new/'
    const [form] = Form.useForm();

    const onFinish = (values, id) => {
        if (GetPeople) {
            GetPeople();
        };
        EditPeople(values, values.id);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        form.setFieldsValue({ id: viewPeople.id })
        form.setFieldsValue({ person_name: viewPeople.person_name })
        form.setFieldsValue({ person_age: viewPeople.person_age })
        form.setFieldsValue({ person_retire_age: viewPeople.person_retire_age })
        form.setFieldsValue({ person_dob: viewPeople.person_dob })
        form.setFieldsValue({ partner_name: viewPeople.partner_name })
        form.setFieldsValue({ partner_age: viewPeople.partner_age })
        form.setFieldsValue({ partner_retire_age: viewPeople.partner_retire_age })
        form.setFieldsValue({ partner_dob: viewPeople.partner_dob })
        form.setFieldsValue({ income_goal: viewPeople.income_goal })

        if(childData){
            childData.forEach((item) => {
                form.setFieldsValue({
                    ['child_name' + item.id]: item.child_name,
                    ['child_age' + item.id]: item.child_age,
                });
            });
        }
      
    }, [viewPeople,childData])


    useEffect(() => {
        form.setFieldsValue({ id: adminView.id })
    }, [adminView])

    useEffect(() => {
        GetPeople();
    }, [])

    const GetPeople = () => {
        request.get(`advisor/view_consumer_profile/${id}`)
            .then(function (response) {
                setViewPeople(response.data.people)
                setChildData(response.data?.people?.children)
                dispatch(setCompanyProfile(response.data))


            })
            .catch(function (error) {
                console.log(error);
            });
    }
    useEffect(() => {
        GetAdvisorView()
    }, [])

    const GetAdvisorView = () => {
        request.get('advisor/view_consumer/')
            .then(function (response) {
                setAdminView(response.data)
            })
            .catch(function (error) {
                console.error(error)
            })
    }

    const handlePeople = () => {
        GetPeople();
        handleOk()
    }
    const EditPeople = (values) => {
        setModalTitle("Update Profile");
        setModalContent(<Editperson handlerecords={values} handlePeople={handlePeople} setViewPeople={setViewPeople} handleOk={handleOk} />);
        showModal();
    }


    return (
        <div>
            <Flex gap={'15px'}>
          <BackButton text={<FaArrowCircleLeft style={{ fontSize: '26px' }} />} onClick={()=>navigate(`/View/${id}`)} />
          <TopTitle Heading={'My Profile'} />
        </Flex>
            <CustomCard>
                <Form
                    form={form}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}

                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">

                    <Row gutter={[24, 24]}>

                        <Col span={24} md={12}>
                            <Input label={'Name ehhlo'} placeholder={' Client Name'} name={'person_name'} disabled
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Your Name!',
                                    }
                                ]}
                            />
                            <Input name={'id'} display={'none'} />

                        </Col>
                        <Col span={24} md={12}>
                            <Input label={'Age '} placeholder={'Age'} name={'person_age'} disabled
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Your Age!',
                                    },

                                ]} />
                        </Col>
                        <Col span={24} md={12}>
                            <Input label={'Retirement Age '} placeholder={'Retirement Age'} name={'person_retire_age'} disabled
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Your Retirement Age!',
                                    }
                                ]}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <Input label={'Spouse Name'} placeholder={'Spouse'} name={'partner_name'} disabled

                            />
                        </Col>
                        <Col span={24} md={12}>
                            <Input label={'Spouse Age'} placeholder={'Spouse Age'} name={'partner_age'} disabled
                            />
                        </Col>

                        <Col span={24} md={12}>
                            <Input label={'Spouse Retirement Age '} placeholder={'Spouse Retirement Age'} name={'partner_retire_age'} disabled
                            />
                        </Col>
                        <Col span={24} md={12}>
                        {childData && childData.length > 0 && (
                        <CustomPageFormSubTitle Heading={'Children Details'} />
                        )}
                    <div>
                    {childData && childData.length > 0 && (
                        childData.map((value, i) => (

                            <div key={i}>
                                <Viewcard>
                                    <Input label={'Children Name'} name={'child_name' + value.id} disabled placeholder="Children Name" />
                                    <Input label={'Children Age'} name={'child_age' + value.id} disabled placeholder="Children Age" />
                                </Viewcard>

                            </div>


                      ))
                    )}
                    </div>
                        </Col>
                        {/* <Col span={24} md={12}>
                        <Input
                            label={
                                <>
                                    Income Goal<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }
                            placeholder={'Income Goal'} name={'income_goal'} disabled
                        />
                    </Col> */}
                    </Row>
                    {/* <Button.Success text={'Back'} onClick={() => navigate(`/View/${id}`)} style={{ margin: '20px 0px' }} /> */}
                    <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={800} modalTitle={modalTitle} modalContent={modalContent} />

                </Form>
            </CustomCard>
        </div>


    )
}