import { Form } from 'antd';
import React, { useEffect } from 'react'
import Input from '../../../Components/Form/Input';
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';
import request from '../../../utils/request';
import { toast } from 'react-toastify';

const DeleteAdvisor = ({ handlerecords,handleCheckReq }) => {

    const [form] = Form.useForm();

    const onFinish = (values) => {
        DeleteOk(values, values.id)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const onRest = () =>{
        handleCheckReq();
    }
//==================Delete ===================================================

    const DeleteOk = (values, id) => {

        request.delete(`api/advisor_user_edit/${id}`, values)
            .then(function (response) {
                if(handleCheckReq){
                    handleCheckReq();
                }
                toast.success('Deleted Successfully')
            })
            .catch(function (error) {
                console.log(error);
                toast.error('Faild')
            });
    }
 
    useEffect(() => {
        form.setFieldsValue(handlerecords)
    }, [handlerecords])
    

    useEffect(() => {
        form.setFieldsValue({ id: handlerecords.id })
    }, [handlerecords])

    return (
        <div>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <Input name={'id'} display={'none'}/>
                <Flex center>
                    <h1 style={{ fontSize: '18px', color: '#685e5e' }}>Do you Want to delete these Profile?</h1>
                </Flex>

                <Flex center gap={'40px'} style={{ margin: '50px 0px' }}>
                    <Button.Success text={'Yes'} htmlType={'submit'} />
                    <Button.Danger text={'No'} onClick={onRest} />
                </Flex>
            </Form>
        </div>
    )
}

export default DeleteAdvisor