import React from 'react'
import FormMain from './Form'
import { CustomCard } from '../../../Components/CustomCard'

const MyeForm = ({GetPlanDetails}) => {
    return (
        <div>
            <CustomCard>
                <FormMain GetPlanDetails={GetPlanDetails} />
            </CustomCard>
        </div>
    )
}

export default MyeForm