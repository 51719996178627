import PageNotFound from '../Components/PageNotFound'
import UserSignin from '../../Modules/Auth/Components/UserSignin'
import AddAssetsMain from '../../Modules/AddAssets/index'
import MyEplanOptimal from '../../Modules/MyEplainOptimal'
import TotalAssetsAllocation from '../../Modules/TotalAssetsAllocation'
import MyLiabilities from '../../Modules/MyLiabilities'
import MyIncome from '../../Modules/MyIncome'
import MyEplanTotalDetails from '../../Modules/MyEPlanDetails'
import EPlanForm from '../../Modules/People/EplanForm/Container/index'
import ViewPeopleDetails from '../../Modules/People/EViewEplan'
import PlanningDetailsMain from '../../Modules/PlannningDetailsTotal/PlanningDetails/Container/index'
import PlanningViewDetails from '../../Modules/PlannningDetailsTotal/ViewDetails'
import AddIncomeplanner from '../../Modules/IncomePlanner/AddPlanner'
import ViewLiabilities from '../../Modules/MyLiabilities/ViewLiabilities'
import YoungAccum from '../../Modules/YoungAccum'
import RetireAccum from '../../Modules/RetireAccum'
import suriveDetails from '../../Modules/Survive'
import Dashboard from '../../Modules/HomePage/Partials/Dashboard'
import Mynetworth from '../../Modules/MyNetworth'
import Adminrole from '../../Modules/UserRole.js/Partials/Adminrole'
import Register from '../../Modules/Auth/Container/subindex'
import Advisor from '../../Modules/UserRole.js/Partials/Advisor'
import ViewIncome from '../../Modules/MyIncome/Partials/ViewIncome'
import AssetsViewtable from '../../Modules/AddAssets/Partials/AssetsViewtable'
import { SuperViewPeople } from '../../Modules/People/EViewEplan/Partials/SuperView'
import ConsumerView from '../../Modules/MyIncome/Partials/ConsumerView'
import ConsumerViewAssets from '../../Modules/AddAssets/Partials/ConsumerAssets'
import ConsumerNetworthView from '../../Modules/MyNetworth/Consumer'
import ConsumerLiabilities from '../../Modules/MyLiabilities/ViewLiabilities/Consumerliabilities'
import ConsumerPlanningViewDetails from '../../Modules/PlannningDetailsTotal/ConsumerViewplanning/Partials'
import UserRoleFetcher from '../../utils/UserRoleFetcher'
import { useContext } from 'react'
import UserDashboard from '../../Modules/HomePage/Partials/UserDashboard'
import UserIncomeView from '../../Modules/MyIncome/Partials/UserIncomeView'
import UserAssets from '../../Modules/AddAssets/Partials/UserAssets'
import Basic from '../../Modules/Basic'


export const anonymous = [
  {
    routePath: '/signin',
    Component: UserSignin,
  },
]

export const superUserAutentciacted = [
  {
    routePath: '/',
    Component: Adminrole,
  },
  {
    routePath: '/View/:id',
    Component: Dashboard,

  },
  {
    routePath: 'Viewperson',
    Component: ViewPeopleDetails,
  },
  {
    routePath: 'View/:id/SuperView',
    Component: SuperViewPeople,
  },
  {
    routePath: 'View/:id/Consumer_LiabilityView',
    Component: ConsumerLiabilities,
  },
  {
    routePath: 'View/:id/Consumer_View',
    Component: ConsumerView,
  },
  {
    routePath: 'View/:id/Consumer_AssetsView',
    Component: ConsumerViewAssets,
  },


  {
    routePath: 'View_Assets',
    Component: AssetsViewtable,
  },
  {
    routePath: 'EplanOptomal',
    Component: MyEplanOptimal,
  },
  {
    routePath: 'Networth',
    Component: Mynetworth,
  },
  {
    routePath: 'View/:id/Consumer_NetworthView',
    Component: ConsumerNetworthView,
  },

  {
    routePath: 'Planingdetails',
    Component: PlanningDetailsMain,
  },
  {
    routePath: 'View/:id/Consumer_ViewPlanning',
    Component: ConsumerPlanningViewDetails,
  },
  {
    routePath: 'rule_of_100',
    Component: PlanningViewDetails,
  },
  {
    routePath: 'View/:id/EplanDetails',
    Component: MyEplanTotalDetails,
  },
  {
    routePath: 'TotalAssetsAllocation',
    Component: TotalAssetsAllocation,
  },
  {
    routePath: 'Survive',
    Component: suriveDetails,
  },
  {
    routePath: 'View/:id/IncomePlanner',
    Component: AddIncomeplanner,
  },
  {
    routePath: 'YoungAccum',
    Component: YoungAccum,
  },
  {
    routePath: 'RetireAccum',
    Component: RetireAccum,
  },
  {
    routePath: 'register',
    Component: Register,
  },

  {
    routePath: 'advisorlist',
    Component: Advisor,
  },
  {
    routePath: '*',
    Component: PageNotFound,
  },

]

export const authenticated = [
  {
    routePath: '/',
    Component: UserDashboard,

  },
  {
    routePath: 'Viewperson',
    Component: ViewPeopleDetails,
  },
  {
    routePath: 'Myincome',
    Component: MyIncome,
  },
  {
    routePath: 'view_income',
    Component: UserIncomeView,
  },
  {
    routePath: 'Assetsmain',
    Component: AddAssetsMain,
  },
  {
    routePath: 'View_Assets',
    Component: UserAssets,
  },

  {
    routePath: 'Home',
    Component: EPlanForm,
  },
  {
    routePath: 'Liabilities',
    Component: MyLiabilities,
  },
  {
    routePath: 'View_Liabilities',
    Component: ViewLiabilities,
  },
  {
    routePath: 'EplanOptomal',
    Component: MyEplanOptimal,
  },
  {
    routePath: 'Networth',
    Component: Mynetworth,
  },
  {
    routePath: 'Planingdetails',
    Component: PlanningDetailsMain,
  },
  {
    routePath: 'ViewPlanning',
    Component: PlanningViewDetails,
  },
  {
    routePath: 'EplanDetails',
    Component: MyEplanTotalDetails,
  },
  {
    routePath: 'TotalAssetsAllocation',
    Component: TotalAssetsAllocation,
  },
  {
    routePath: 'Survive',
    Component: suriveDetails,
  },
  {
    routePath: 'IncomePlanner',
    Component: AddIncomeplanner,
  },
  {
    routePath: 'YoungAccum',
    Component: YoungAccum,
  },
  {
    routePath: 'RetireAccum',
    Component: RetireAccum,
  },
  {
    routePath: 'register',
    Component: Register,
  },

  {
    routePath: 'AdminConsumerList',
    Component: Adminrole,
  },
  {
    routePath: 'advisorlist',
    Component: Advisor,
  },
  {
    routePath: 'Basic',
    Component: Basic,
  },
  {
    routePath: '*',
    Component: PageNotFound,
  },
]

export const authenticated2 = [
  // {
  //   routePath: '/',
  //   Component: EPlanForm,
  // },
  {
    routePath: '*',
    Component: PageNotFound,
  },
]


export const authenticated3 = [

  {
    key: 1,
    h1: 'My Profile',
    routePath2: 'SuperView',
    description: 'Are you sure to Add Profile',


  },

  {
    key: 2,
    h1: 'My Income',
    routePath2: 'Consumer_View',
    // Component:ViewIncome,
    description: 'Are you sure to Add Income',

  },
  {
    key: 3,
    h1: 'My Liability',
    routePath2: 'Consumer_LiabilityView',
    description: 'Are you sure to Liabilities',

  },
  {
    key: 4,
    h1: 'My Assets',
    routePath2: 'Consumer_AssetsView',
    description: 'Are you sure to Add Assets',
  },
  {
    key: 5,
    h1: 'My NetWorth',
    routePath2: 'Consumer_NetworthView',
    description: 'Are you sure to Networth',
  }, 
  {
    key: 6,
    h1: 'Rule of 100',
    routePath2: 'Consumer_ViewPlanning',
    description: 'Are you sure to My-ePlan',


  },
  {
    key: 7,
    h1: 'Income Planner',
    routePath2: 'IncomePlanner',
    description: 'Are you sure to Income Planner',
  },
  {
    key: 8,
    h1: 'My e-Plan',
    routePath2: 'EplanDetails',
    description: 'Are you sure to My-ePlan',


  },



]





