import React from 'react'
import { useState } from 'react'
import { Modal } from '../../../Components/Modal'
import { Table } from '../../../Components/Table'
import { customFormat } from '../../../utils/customFormat'
import DeleteLiabilities from '../../MyLiabilities/Partials/DeleteLiabilities'
import LiabilitiesFom from '../../MyLiabilities/Partials/LiabilitiesForm'
import { Overhid } from './style'

const ViewTotalLiabilities = ({tableData ,HandleGetAssets,liability}) => {

      //   ======  Modal Title and Content ========
      const [modalTitle, setModalTitle] = useState("");
      const [modalContent, setModalContent] = useState(null);
      const [isModalOpen, setIsModalOpen] = useState(false);
  
  
      const showModal = () => {
          setIsModalOpen(true);
      };
      const handleOk = () => {
          setIsModalOpen(false);
      };
      const handleCancel = () => {
          setIsModalOpen(false);
      };
    const columns = [
        {
            title: 'SI No',
            render: (text, value, index) => index + 1,

        },
        {
            title: 'Client Name',
            dataIndex: 'client_name',

        },
        {
            title: 'Type',
            dataIndex: 'type',

        },
        {
            title: 'Account',
            dataIndex: 'account',

        },
        {
            title: 'Monthly Payments',
            dataIndex: 'monthly_payments',
            render: (value) =>  `$ ${customFormat(value)}`, 
        },
        {
            title: 'Current Balance',
            dataIndex: 'current_balance',
            render: (value) =>  `$ ${customFormat(value)}`, 
        },
        // {
        //     title: 'Action',
        //     render: (record) => {
        //         return (

        //             <Flex spaceEvenly>
        //                 <Button.Success onClick={() => { EditLiabilatityTable(record); }} text={<AiOutlineEdit style={{ fontSize: '20px' }} />} />
        //                 <Button.Danger onClick={() => { DeleteLiabilatityTable(record) }} text={<AiOutlineDelete style={{ fontSize: '20px' }} />} />
        //             </Flex>
        //         )
        //     }

        // },

    ]
    const EditLiabilatityTable = (record) => {
        setModalTitle("Update Liabilities");
        setModalContent(<LiabilitiesFom  datas={record} handleOk={handleOk} HandleGetAssets={HandleGetAssets}/>);
        showModal();
    }
    const DeleteLiabilatityTable = (record) => {
        setModalTitle("Delete Liabilities");
        setModalContent(<DeleteLiabilities  datass={record} HandleGetAssets={HandleGetAssets} handleOk={handleOk}/>);
        showModal();
    }
    return (
        <div>
            <Overhid>
                <Table columns={columns} data={tableData} />
            </Overhid>
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={500} modalTitle={modalTitle} modalContent={modalContent} />

        </div>
    )
}

export default ViewTotalLiabilities