import React from 'react'
import ViewTotalAssets from './Partials/ViewTotalAssets'
import ViewTotalLiabilities from './Partials/ViewTotalLiabilities'
import NetworthTotalCard from './Partials/NetworthTotalCard'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { TopTitle } from '../../../Components/Form/TopTitle'
import { CustomCard } from '../../../Components/CustomCard'
import request from '../../../utils/request'
import Button from '../../../Components/Form/Button'
import Flex from '../../../Components/Flex'
import BackButton from '../../../Components/Form/BackButton'
import { FaArrowCircleLeft } from 'react-icons/fa'


const ConsumerNetworthView = ({ liability }) => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [dataSource, setDataSource] = useState([])
    const [tableData, setTableData] = useState([])

    const [assetsData, setAssetsData] = useState([])

    const HandleGetAssets = () => {
        request.get(`advisor/view_consumer_profile/${id}`)
            .then(function (response) {
                setDataSource(response.data?.networth?.asset)
                setTableData(response.data?.networth?.liability)
                setAssetsData(response.data?.networth?.networth)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        HandleGetAssets();
    }, [])
    return (
        <div>

            <Flex gap={'15px'}>
                <BackButton text={<FaArrowCircleLeft style={{ fontSize: '26px' }} />} onClick={() => navigate(`/View/${id}`)} />
                <TopTitle Heading={'My Networth'} />
            </Flex>
            <CustomCard>
                <NetworthTotalCard datas={assetsData} /><br />

                <TopTitle Heading={'Assets'} />
                <ViewTotalAssets dataSource={dataSource} HandleGetAssets={HandleGetAssets} />

                <TopTitle Heading={'Liability'} />
                <ViewTotalLiabilities tableData={tableData} HandleGetAssets={HandleGetAssets} liability={liability} />
                {/* <Button.Success text={'Back'} onClick={() => navigate(`/View/${id}`)} /> */}
            </CustomCard>
        </div>
    )
}

export default ConsumerNetworthView


