import { Col, Form } from 'antd';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';
import Input from '../../../Components/Form/Input';
import { Row } from '../../../Components/Row';
import request from '../../../utils/request';

const TypeEditForm = ({ GetTypesData, handleOk, GetTabelTypes, datas, HandleGetAssets,handleCancel,setInitialChange }) => {

    const [form] = Form.useForm();

    const onFinish = (values) => {
        const record = { ...values }
        let result = {
            type: record.type,
            people: record.people,
            id: record.id,

        }
        HandleGetAssets();
        EditFormTypes(result, datas.id);
        handleOk()

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        form.setFieldsValue(datas)
    }, [datas])

    const EditFormTypes = (record, id) => {
        request.put(`liabilites/edit_type/${id}/`, record)
            .then(function (response) {
                setInitialChange(response.data?.type)
                GetTabelTypes();
                HandleGetAssets();
                GetTypesData();
                if (response.status === 226) {
                    toast.warn('Type Already exists');
                }
                else {
                    toast.success('Add Successfully')
                }

            })
            .catch(function (error) {
                console.log(error);
                // toast.error('Failed to add type');
            });
    }
    useEffect(() => {
        form.setFieldsValue({ id: datas.id })
    }, [datas])

    const onRest = () => {
        handleOk();
        // form.resetFields();
        GetTabelTypes();
    }

    return (
        <div>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}

                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <Row gutter={[12, 12]}>

                    <Col span={24} md={24}>

                        <Input label={'Type'}
                            placeholder={'Type'} name={'type'}
                            rules={[
                                {
                                    required: true,
                                    message: 'This is required field!'
                                }
                            ]} />
                    </Col>
                    <Input name={'people'} display={'none'} />
                    <Input name={'id'} display={'none'} />
                </Row>
                <Flex center gap={'20px'} style={{ margin: '30px' }}>
                    <Button.Success text={'SAVE'} htmlType={'submit'} />
                    <Button.Danger text={'CANCEL'} onClick={onRest} />
                </Flex>

            </Form>
        </div>
    )
}

export default TypeEditForm