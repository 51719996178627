import { SIGN_IN_SUCCESS, LOGOUT_SUCCESS ,ADMIN_REGISTER_SUCCESS,DASHBOARD_VIEW} from './actions'

const initialState = {
  token: null,
  supertoken:null,
  pageview:null,
}

export default (state = initialState, action) => {
  //console.log('reducer')
  switch (action.type) {
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        token: action.token,
      }
      case ADMIN_REGISTER_SUCCESS:
        return {
          ...state,
          supertoken: action.supertoken,
        }
        case DASHBOARD_VIEW:
          return {
            ...state,
            pageview: action.pageview,
          }
      case LOGOUT_SUCCESS:
      return {}
    default:
      return state
  }
}
