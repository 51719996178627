import React from 'react'
import { FaArrowCircleLeft } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CustomCard } from '../../../Components/CustomCard'
import Flex from '../../../Components/Flex'
import BackButton from '../../../Components/Form/BackButton'
import { TopTitle } from '../../../Components/Form/TopTitle'
import { SuperViewPeople } from './Partials/SuperView'
import { ViewPeoples } from './Partials/viewPeoples'


const ViewPeopleDetails = () => {

const navigate = useNavigate()
  return (
    <div>
      <>
        <Flex gap={'15px'}>
          <BackButton text={<FaArrowCircleLeft style={{ fontSize: '26px' }} />} onClick={() => navigate(`/`)} />
          <TopTitle Heading={'My Profile'} />
        </Flex>
        <CustomCard>
          <ViewPeoples />
        </CustomCard>
      </>
    </div>
  )
}

export default ViewPeopleDetails