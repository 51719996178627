import { Col, Form, Row, Upload as Uploded } from 'antd'
import { toast } from 'react-toastify';
import Button from '../../../../Components/Form/Button';
import Flex from '../../../../Components/Flex';
import { CustomInputNumber } from '../../../../Components/Form/CustomInputNumber';
import Input from '../../../../Components/Form/Input';
import request from '../../../../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Select } from '../../../../Components/Form/Select';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { CustomPageFormSubTitle } from '../../../../Components/Form/FormSub';


export const ClientDetails = ({ handlePeople, setCompanyProfile, id }) => {


    const navigate = useNavigate();

    const URLS = 'people/add_people_new/'

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const onFinish = (values) => {
        HandlePostPerson(values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onReset = () => {
        form.resetFields();
    }

    const HandlePostPerson = (values) => {
        request.post(`${URLS}`, values)
            .then((response) => {
                console.log(response);
                if (response.status === 201) {
                    form.resetFields();
                    navigate('/Viewperson');
                    toast.success('Successfully Added the Profile Details');
                    dispatch(setCompanyProfile(response.data));

                }
                else if (response.status === 208) {
                    toast.warn('User Profile already exists!');
                }
                else{
                    toast.success('Successfully Added the Profile Details');
                }
            })
            .catch(error => {
                console.log(error);
             
                    if (error.response.data) {
                        toast.error('Failed');
                    }

                
            });
    };


    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}

            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">

            <Row gutter={[24, 24]}>
                <Col span={24} md={12}>
                    <Input label={'Name'} placeholder={' Client Name'} name={'person_name'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Your Name!',
                            }
                        ]}
                    />
                </Col>
                <Col span={24} md={12}>
                    <CustomInputNumber label={'Age '} placeholder={'Age'} name={'person_age'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Your Age!',
                            },

                        ]} />
                </Col>
                <Col span={24} md={12}>
                    <CustomInputNumber label={' Retirement Age '} placeholder={'Retirement Age'} name={'person_retire_age'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Your Retirement Age!',
                            }
                        ]}
                    />
                </Col>
                {/* <Col span={24} md={12}>
                    <CustomDatePicker label={'D.O.B'} onChange={handleOnChange} name={'person_dob'}
                        rules={[{
                            required: 'true',
                            message: 'Please Enter your DOB !'
                        },]} />
        
                </Col> */}

                <Col span={24} md={12}>
                    <Input label={'Spouse Name'} placeholder={'Spouse'} name={'partner_name'}
                    />
                </Col>
                <Col span={24} md={12}>
                    <CustomInputNumber label={'Spouse Age'} placeholder={'Spouse Age'} name={'partner_age'}
                    />
                </Col>

                <Col span={24} md={12}>
                    <CustomInputNumber label={'Spouse Retirement Age '} placeholder={'Spouse Retirement Age'} name={'partner_retire_age'}
                    />
                </Col>
                <Col span={24} md={12}>
                    <CustomPageFormSubTitle Heading={'Children Details'} />
                    <Form.List name="children"  >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Row gutter={[24, 24]}>
                                        <Col span={10}>
                                            <Input label={'Children Name '} placeholder="Children Name" name={[name, 'child_name']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'This is required field!',
                                                    }
                                                ]}
                                            />
                                        </Col>
                                        <Col span={10}>
                                            <CustomInputNumber label={'Children Age'} placeholder="Children Age" name={[name, 'child_age']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'This is required field!',
                                                    }
                                                ]}
                                            />

                                        </Col>
                                        <Col span={10} style={{ margin: '0px ' }}>
                                            {/* {fields.length > 1 && ( */}
                                            <Button.Danger
                                                style={{ marginLeft: '8px' }}
                                                text={<MinusCircleOutlined />}
                                                onClick={() => remove(name)}
                                            />
                                            {/* )} */}
                                        </Col>

                                    </Row>
                                ))}
                                <Form.Item style={{ margin: '20px 10px' }} >
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Children Details
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Col>
                <Col span={24} md={12}>
                    <CustomInputNumber
                        label={
                            <>
                                Income Goal<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                            </>
                        }
                        placeholder={'Income Goal'} name={'income_goal'}
                    />
                </Col>
            </Row>
            <Flex center gap={'20px'} style={{ margin: '20px 0px' }}>
                {/* <Button.Success text={'Back'} onClick={() => navigate('/')} /> */}
                <Button.Primary text={'SUBMIT'} htmlType={'submit'} />
                <Button.Danger text={'CANCEL'} onClick={() => onReset()} />

            </Flex>
        </Form>

    )
}