import React from 'react'
import Button from '../../../Components/Form/Button'
import styled from 'styled-components'
import { Form, Input } from 'antd'
import Flex from '../../../Components/Flex'
import request from '../../../utils/request'
import Label from '../../../Components/Form/Label'
import { useForm } from 'antd/es/form/Form'
import { toast } from 'react-toastify'
import { Navigate, useNavigate } from 'react-router-dom'
import { Select } from '../../../Components/Form/Select'

const InputWrapper = styled.div`
  padding-bottom: 25px;

`
const Header = styled.div`
  color:#fff;
  margin-bottom:20px;
`

const SignupForm = ({ handleSignIn }) => {

  const [form] = useForm();

  const URLS = 'api/register'

  const navigate = useNavigate();

  const onFinish = values => {
    HandlePostPerson(values)
  }

  const HandlePostPerson = (values) => {
    request.post(`${URLS}`, values).then((response) => {
      console.log(response.data);
      if (response.status === 201) {
        form.resetFields();
        toast.success('Your Profile Added Successfully')
        // navigate('/SignInForm')

      }
      else {
        form.resetFields();
        toast.warning('Email Id Already exists!')
      }
    }).catch(error => {
      console.log(error);
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data) {
            toast.warn(error.response.data?.email[0]);
          }
        } else {
          toast.error('Something Went Wrong');
        }
      } else if (error.request) {
        console.log(error.request);
        toast.error('Request Error');
      } else {
        console.log('Error', error.message);
        toast.error('Connection Error');
      }
    });
  }
  const UseRoleOption = [
    {
      label: 'Admin',
      value: 'Admin',

    },
    {
      label: 'Advisor',
      value: 'Advisor',

    },
    {
      label: 'User',
      value: 'User',

    },
  ]


  return (
    <Form onFinish={onFinish}
      form={form}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}>

      <Flex center>
        <Header>
          {/* <h1 style={{ fontSize: '30px', }}>SignUp</h1> */}
        </Header>
      </Flex>
      <InputWrapper style={{ padding: '5px 20px' }}>
        <Form.Item
          style={{ marginBottom: '0' }}
          name="name"
          label={<Label>User Name</Label>}
          rules={[
            { required: true, message: 'Please enter your Name' },
          ]}
        >
          <Input size='large' placeholder="Name" />
        </Form.Item>
      </InputWrapper>

      <InputWrapper style={{ padding: '5px 20px' }}>
        <Form.Item
          style={{ marginBottom: '0' }}
          name="email"
          label={<Label>Email</Label>}
          rules={[
            { required: true, message: 'Please enter your email address' },
          ]}
        >
          <Input type={'email'} size='large' placeholder="Email" />
        </Form.Item>
      </InputWrapper>

      <InputWrapper style={{ padding: '5px 20px' }}>
        <Form.Item
          style={{ marginBottom: '0' }}
          name="password"
          label={<Label>Password</Label>}
          rules={[
            {
              required: true,
              message: 'Please enter a password',
            },
          ]}
        >
          <Input.Password size='large' placeholder="Password" style={{ borderRadius: '8px' }} />
        </Form.Item>
      </InputWrapper>
      
      <Form.Item style={{ padding: '5px 20px' }}>
        <Flex center gap={'20px'} style={{ margin: '20px 0px' }}>
          <Button.Success text={'Signup'} htmlType="submit" />
          <Button.Danger text={'Cancel'} htmlType="reset" />
        </Flex>
      </Form.Item>

    </Form>
  )
}

export default SignupForm
