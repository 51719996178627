import React from 'react'
import { TopTitle } from '../../../../Components/Form/TopTitle'
import ChartMain from './Chart'
import TableDetail from './TableDetails'

const PlanningBarChart = ({graphtData, currentData}) => {
    return (
        <div>
            {/* <TopTitle Heading={'Total Assets Allocation'} /> */}
            <ChartMain graphtData={graphtData} /><br /><br />
      
        </div>
    )
}

export default PlanningBarChart