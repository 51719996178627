import React from 'react'
import Button from '../../../Components/Form/Button'
import styled from 'styled-components'
import { Form, Input } from 'antd'
import Flex from '../../../Components/Flex'
import { useNavigate } from 'react-router-dom'
import Label from '../../../Components/Form/Label'
import { useForm } from 'antd/es/form/Form'
import Checkbox from '../../../Components/Form/Checkbox'
import { useState } from 'react'

const InputWrapper = styled.div`
  padding-bottom: 25px;

`
const Header = styled.div`
  color:#fff;
  margin-bottom:20px;
`


const SignInForm = ({ handleSignIn }) => {

  const [form] = useForm();

  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  

  const onFinish = (values) => {
    
    const Checkboxvalues = { ...values, keeping: isChecked ? true:false};

    if(handleSignIn){
      // form.resetFields()
    handleSignIn(Checkboxvalues);

    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Form onFinish={onFinish}
      labelCol={{
        span: 24,
      }}
      form={form}
      wrapperCol={{
        span: 24,
      }}>

      <Flex center>
        <Header>
          {/* <h1 style={{ fontSize: '30px', }}>SignIn</h1> */}
        </Header>
      </Flex>

      <InputWrapper style={{ padding: '5px 20px' }}>
        <Form.Item
          style={{ marginBottom: '0' }}
          name="email"
          label={<Label>Email</Label>}
          rules={[
            { required: true, message: 'Please enter your email address' },
          ]}
        >
          <Input type={'email'} size='large' placeholder="Email" />
        </Form.Item>
      </InputWrapper>
      <InputWrapper style={{ padding: '5px 20px' }}>
        <Form.Item
          style={{ marginBottom: '0' }}
          name="password"
          label={<Label>Password</Label>}
          rules={[
            {
              required: true,
              message: 'Please enter a password',
            },
          ]}
        >
          <Input.Password size='large' placeholder="Password" style={{ borderRadius: '8px' }} />
        </Form.Item>
      </InputWrapper>
      <InputWrapper style={{ padding: '5px 20px' }}>

     <Checkbox name={'keeping'} label={'Keep Logged In'} checked={isChecked}  onClick={handleCheckboxChange} />

      <Form.Item style={{ padding: '5px 20px' }}>
        <Flex center gap={'20px'} style={{ margin: '20px 0px' }}>
          <Button.Success text={'Login'} htmlType="submit" />

          <Button.Danger text={'Cancel'} htmlType="reset" />
        </Flex>
       <Flex center style={{cursor:'pointer'}} >
       <h1 style={{textDecoration: 'underline'}} >Forgot Password</h1>
       </Flex>
      </Form.Item>
      </InputWrapper>

    </Form>
  )
}

export default SignInForm
