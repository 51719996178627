import React from 'react'
import { Col, Form } from 'antd';
import { Row } from '../../../Components/Row';
import { CustomInputNumber } from '../../../Components/Form/CustomInputNumber';
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';
import { TopTitle } from '../../../Components/Form/TopTitle';
import { useEffect } from 'react';
import { useState } from 'react';
import request from '../../../utils/request';
import { Modal } from '../../../Components/Modal';
import { toast } from 'react-toastify';
import Input from '../../../Components/Form/Input';


const SurviveForm = () => {

    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log(values, 'Success');
    };

    const onFinishFailed = (errorInfo) => {
        toast.error('Please Fill the Details');
        console.log('Failed:', errorInfo);
    };

    const onReset = () => {
        form.resetFields();
   
    };
    
    //==================other Assets calculation =====//
    const calculateOtherAssets = () => {
        const totalEstateValue = parseFloat(form.getFieldValue('total_estate')) || 0;
        const insuranceValue = parseFloat(form.getFieldValue('insurance')) || 0;
        const investableAssetsValue = parseFloat(form.getFieldValue('investable_assets')) || 0;
        // const otherAssetsValue = totalEstateValue - insuranceValue - investableAssetsValue;
        const First1 = totalEstateValue - insuranceValue || 0;
        const otherAssetsValue = First1 - investableAssetsValue || 0;

        return isNaN(otherAssetsValue) ? '' : otherAssetsValue.toFixed(2);
    };

    const handleTotalEstateChange = (value) => {
        form.setFieldsValue({ total_estate: value }, { total_estate: 0 });
        form.setFieldsValue({ other_assets: calculateOtherAssets() });

    };

    const handleInsuranceChange = (value) => {
        form.setFieldsValue({ insurance: value });
        form.setFieldsValue({ other_assets: calculateOtherAssets() });
    };

    const handleInvestableAssetsChange = (value) => {
        form.setFieldsValue({ investable_assets: value });
        form.setFieldsValue({ other_assets: calculateOtherAssets() });
    };

    //===============Portfolio calculation========================//

    const calculatePortfolio = () => {

        const incomerequirement = parseFloat(form.getFieldValue('income_requirement')) || 0;
        const lessOtherSouces = parseFloat(form.getFieldValue('less_other_sources')) || 0;

        const PortfolioValue = incomerequirement - lessOtherSouces || 0;

        return isNaN(PortfolioValue) ? '' : PortfolioValue.toFixed(2);
    };
    const handleincomeRequiements = (value) => {
        form.setFieldsValue({ income_requirement: value });
        form.setFieldsValue({ required_portfolio: calculatePortfolio() });

    };

    const handleLessotherSources = (value) => {
        form.setFieldsValue({ less_other_sources: value });
        form.setFieldsValue({ required_portfolio: calculatePortfolio() });
    };

    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">

            <Row gutter={[12, 12]}>
                <Col span={24} md={8}>
                    <Input label={'Person Name'} placeholder={'Person Name'} name={'person_name'}
                     rules={[
                        {
                            required: true,
                            message: 'This is required field!'
                        }
                    ]}
                     />
                </Col>
                <Col span={24} md={8}>
                    <Input label={'Parner Name'} placeholder={'Partner Name'} name={'partner_name'}
                     rules={[
                        {
                            required: true,
                            message: 'This is required field!'
                        }
                    ]} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={' Current Age'} placeholder={'Current Age'} name={'current_age'} 
                     rules={[
                        {
                            required: true,
                            message: 'This is required field!'
                        }
                    ]}/>
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Income Requirement'}
                        placeholder={'Income Requirement'}
                        name={'income_requirement'}
                        onChange={handleincomeRequiements} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Less Other Sources'}
                        placeholder={'Less Other Sources'}
                        name={'less_other_sources'}
                        onChange={handleLessotherSources} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Net Required Income From Portfolio'}
                        placeholder={'Net Required Income From Portfolio'}
                        name={'required_portfolio'}
                        onChange={calculatePortfolio} disabled />
                </Col>


                <Col span={24} md={8}>
                    <CustomInputNumber label={'Expected Annual Expense Inflation (%)'} placeholder={'Expected Annual Expense Inflation (%) '}
                        name={'person_penson'} />
                </Col>


                <Col span={24} md={8}>
                    <CustomInputNumber label={'Social Security growth rate ($)'}
                        placeholder={'Social Security growth rate'}
                        name={'person_investable_assets'} />
                </Col>
                <Col span={24} md={8}>
                    <CustomInputNumber
                        label={'Total Estate'}
                        placeholder={'Total Estate'}
                        name={'total_estate'}
                        precision={2}
                        onChange={handleTotalEstateChange}
                    />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber
                        label={'Insurance ($)'}
                        placeholder={'Insurance'}
                        name={'insurance'}
                        precision={2}
                        onChange={handleInsuranceChange}
                    />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber
                        label={'Investable Assets ($)'}
                        placeholder={'Investable Assets'}
                        name={'investable_assets'}
                        precision={2}
                        onChange={handleInvestableAssetsChange}
                    />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber
                        label={'Other Assets'}
                        placeholder={'Other Assets'}
                        name={'other_assets'}
                        disabled

                    />
                </Col>
                <Col span={24} md={8}>
                    <CustomInputNumber label={'Other Assets %'} placeholder={'Other Assets %'} name={'person_draw_per'}
                    />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Effective Inc.Tax Rate '} placeholder={'Effective Inc.Tax Rate'} name={'person_emp_match'}
                    />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Person Social Security 1'} placeholder={'Person Social Security 1'} name={'e_plan_pension'}
                    />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Person Social Security 2'} placeholder={'Person Social Security 2'} name={'e_plan_pension'}
                    />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Partner Social Security 1'} placeholder={'Partner Social Security 1'} name={'e_plan_pension'}
                    />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Partner Social Security 1'} placeholder={'Partner Social Security 1'} name={'e_plan_pension'}
                    />
                </Col>
            </Row><br />
            <Flex center gap={'20px'} >
                <Button.Primary text={'SUBMIT'} htmlType={'submit'} />
                <Button.Danger text={'RESET'} onClick={onReset} />
            </Flex>

        </Form>
    )
}

export default SurviveForm