import React, { useEffect, useState } from 'react'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import Flex from '../../../Components/Flex'
import Button from '../../../Components/Form/Button'
import { Modal } from '../../../Components/Modal'
import { Table } from '../../../Components/Table'
import DeleteAssets from '../../AddAssets/Partials/DeleteAssets'
import { EditAssets } from '../../AddAssets/Partials/EditAssets'
import { Row } from '../../../Components/Row'
import { Col } from 'antd'
import { TopTitle } from '../../../Components/Form/TopTitle'
import Label from '../../../Components/Form/Label'
import Input from '../../../Components/Form/Input'
import { useNavigate, useParams } from 'react-router-dom'
import request from '../../../utils/request'
import EditButton from '../../../Components/Form/EditButton'
import DeleteButton from '../../../Components/Form/DeleteButton'
import { customFormat } from '../../../utils/customFormat'

const AssetsViewtable = ({setGetdata, getdata,HandleGetAssets ,dataSource}) => {
    
    const navigate = useNavigate();
        
    const [width,setWidth] = useState(0)
    const {id} = useParams();

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [searchText, setSearchText] = useState([]);

    const handleSearch = (value) => {
        setSearchText(value);
    };
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        HandleGetAssets()
        setIsModalOpen(false);
    };
    useEffect(() => {
        HandleGetAssets();
    }, [])

 
    const columns = [
        {
            title: 'SI No',
            render: (text, value, index) => index + 1,
        },
        {
            title: 'Client Name',
            dataIndex: 'client_name',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.client_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.client_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: 'Account',
            dataIndex: 'account',
        },
        {
            title: 'Risk',
            dataIndex: 'risk',
        },
        {
            title: 'Contribution Amount',
            dataIndex: 'contribution_amt',
            render: (value) =>`$ ${customFormat(value)}` ,
        },
        {
            title: 'Current Balance',
            dataIndex: 'current_bal_amt',
            render: (value) =>`$ ${customFormat(value)}` ,
        },
        {
            title: 'Action',
            render: (record) => {
                return (

                    <Flex gap={'15px'} center >
                        <EditButton onClick={() => { EditAssetsTable(record); }} text={<AiOutlineEdit  />} />
                        <DeleteButton onClick={() => { DeleteAssetsTable(record); }} text={<AiOutlineDelete  />} />
                    </Flex>
                )
            }
        },

    ]
    const EditAssetsTable = (record) => {
        setWidth(600)
        setModalTitle('Edit Details')
        setModalContent(<EditAssets datas={record} setGetdata={setGetdata} getdata={getdata} handleOk={handleOk} HandleGetAssets={HandleGetAssets} />);
        showModal();
    }
    const DeleteAssetsTable = (record) => {
        setWidth(500)
        setModalTitle('')
        setModalContent(<DeleteAssets datass={record} HandleGetAssets={HandleGetAssets} handleOk={handleOk} />);
        showModal();
    }

    return (
        <div style={{ marginBottom: '50px' }}>
             <Row>
                <Col span={24} md={12}>
                    <TopTitle Heading={'Assets'} />
                </Col>
                <Col span={24} md={12}>
                    <Flex end centerVertically>
                        <Label>Search by Client Name : &nbsp;&nbsp;</Label>
                        <Input
                            placeholder="Search by Client Name"
                            value={searchText}
                            onSearch={handleSearch}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </Flex>
                </Col>
            </Row>
            <Table columns={columns} data={dataSource} />
            {/* <Button.Success text={'Back'} onClick={()=>navigate('/')}  /> */}
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={width} modalTitle={modalTitle} modalContent={modalContent} />

        </div>
    )
}

export default AssetsViewtable