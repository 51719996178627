import React, { useEffect, useState } from 'react'
import Button from '../../../../Components/Form/Button';
import Flex from '../../../../Components/Flex';
import { TopTitle } from '../../../../Components/Form/TopTitle';
import { Row } from '../../../../Components/Row';
import { Col, Form } from 'antd';
import { CustomInputNumber } from '../../../../Components/Form/CustomInputNumber';
import Input from '../../../../Components/Form/Input';
import request from '../../../../utils/request';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const EditInnerPlan = ({ handleEdit, GetPlanner ,trigger}) => {

    const [form] = Form.useForm();
    const { id } = useParams()

    const URLSPUT = 'income_planner/profile_edit_income_planner'

    const [plannerget, setPlannerget] = useState({})

    const GETEDIT = 'income_planner/profile_get_income_planner'

    useEffect(() => {
        form.setFieldsValue({ person_name: plannerget.person_name })
        form.setFieldsValue({ person_age: plannerget.person_age })
        form.setFieldsValue({ person_desire_age: plannerget.person_desire_age })
        form.setFieldsValue({ person_social: plannerget.person_social })
        form.setFieldsValue({ person_income_goal: plannerget.person_income_goal })
        form.setFieldsValue({ person_total_household_income: plannerget.person_total_household_income })
        form.setFieldsValue({ person_cola_percent: plannerget.person_cola_percent })
        form.setFieldsValue({ person_penson: plannerget.person_penson })
        form.setFieldsValue({ person_inflation_percent: plannerget.person_inflation_percent })
        form.setFieldsValue({ person_contribution: plannerget.person_contribution })
        form.setFieldsValue({ person_401k_balance: plannerget.person_401k_balance })
        form.setFieldsValue({ person_ror_percent: plannerget.person_ror_percent })
        form.setFieldsValue({ person_draw_per: plannerget.person_draw_per })
        form.setFieldsValue({ person_emp_match: plannerget.person_emp_match })
        form.setFieldsValue({ id: plannerget.id })
        form.setFieldsValue({ people: plannerget.people })

        form.setFieldsValue({ partner_name: plannerget.partner_name })
        form.setFieldsValue({ partner_age: plannerget.partner_age })
        form.setFieldsValue({ partner_desire_age: plannerget.partner_desire_age })
        form.setFieldsValue({ partner_social: plannerget.partner_social })
        form.setFieldsValue({ partner_cola_percent: plannerget.partner_cola_percent })
        form.setFieldsValue({ partner_penson: plannerget.partner_penson })
        form.setFieldsValue({ partner_inflation_percent: plannerget.partner_inflation_percent })
        form.setFieldsValue({ partner_contribution: plannerget.partner_contribution })
        form.setFieldsValue({ partner_401k_balance: plannerget.partner_401k_balance })
        form.setFieldsValue({ partner_ror_percent: plannerget.partner_ror_percent })
        form.setFieldsValue({ partner_emp_match: plannerget.partner_emp_match })
        form.setFieldsValue({ partner_income_goal: plannerget.partner_income_goal })
        form.setFieldsValue({ e_penson: plannerget.e_penson })
        form.setFieldsValue({ partner_draw_per: plannerget.partner_draw_per })
        form.setFieldsValue({ partner_total_household_income: plannerget.partner_total_household_income })
    }, [plannerget])


    useEffect(() => {
        GetPlannerEditss()
    }, [trigger])

    const GetPlannerEditss = () => {
        request.get(`${GETEDIT}/${id}/`)
            .then(function (response) {
                setPlannerget(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const EditDetails = (values, id) => {
        request.put(`${URLSPUT}/${id}/`, values)
            .then((response) => {
                form.resetFields();
                handleEdit()
                GetPlanner();
                GetPlannerEditss()
                toast.info('Updated Details Successfully')
            }).catch(error => {
                console.log(error);
            });
    }

    const onFinish = (values) => {
        const record = { ...values };
        let result = {
            people: record.people,
            person_name: record.person_name,
            person_age: record.person_age,
            person_desire_age: record.person_desire_age,
            person_income_goal: record.person_income_goal,
            person_total_household_income: record.person_total_household_income,
            person_social: record.person_social,
            person_cola_percent: record.person_cola_percent,
            person_penson: record.person_penson,
            person_inflation_percent: record.person_inflation_percent,
            person_401k_balance: record.person_401k_balance,
            person_contribution: record.person_contribution,
            person_ror_percent: record.person_ror_percent,
            person_draw_per: record.person_draw_per,
            person_emp_match: record.person_emp_match,
            partner_name: record.partner_name,
            partner_age: record.partner_age,
            partner_desire_age: record.partner_desire_age,
            partner_income_goal: record.partner_income_goal,
            partner_total_household_income: record.partner_total_household_income,
            partner_social: record.partner_social,
            partner_cola_percent: record.partner_cola_percent,
            partner_penson: record.partner_penson,
            partner_ror_percent: record.partner_ror_percent,
            partner_inflation_percent: record.partner_inflation_percent,
            partner_401k_balance: record.partner_401k_balance,
            partner_contribution: record.partner_contribution,
            partner_draw_per: record.partner_draw_per,
            partner_emp_match: record.partner_emp_match,
            e_penson: record.e_penson

        }

        EditDetails(result, values.id)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onReset = () => {
        form.resetFields();
    }

    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}

            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Row gutter={[24, 24]}>
                <Col span={24} md={12}>
                    <Row gutter={[24, 24]}>
                        <Col span={24} md={12}>

                            <Input label={'Person Name'} placeholder={'Person Name'} name={'person_name'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Your Name!',
                                    }
                                ]} />
                            <Input name={'people'} display={'none'} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Age'} placeholder={'Age'} name={'person_age'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Your Age!',
                                    },

                                ]} />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Desired Age'} placeholder={'Desired Age'}
                                name={'person_desire_age'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Your Desired Age!',
                                    }
                                ]}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={
                                    <>
                                        Goal<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                    </>
                                }
                                placeholder={'Goal'} name={'person_income_goal'}
                                rules={[{
                                    required: 'true',
                                    message: 'Please Enter your Goal !'
                                },]} />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={
                                    <>
                                        Annual Income<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                    </>
                                }
                                placeholder={'Annual Income'} name={'person_total_household_income'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={
                                    <>
                                        SOC 1<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                    </>
                                }
                                placeholder={'SOC 1'} name={'person_social'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'COLA %'} placeholder={'COLA %'} name={'person_cola_percent'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={
                                    <>
                                        Pension 1<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }
                                placeholder={'Pension 1'} name={'person_penson'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Inflation %'} placeholder={'Inflation'} name={'person_inflation_percent'}
                            // rules={[{
                            //     required: 'true',
                            //     message: 'This is required field!'
                            // },]}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={
                                    <>
                                        401k Balance<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }
                                placeholder={'401k Balance'} name={'person_401k_balance'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Contributions %'} placeholder={'Contributions'} name={'person_contribution'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'ROR %'} placeholder={'ROR %'} name={'person_ror_percent'}
                            // rules={[{
                            //     required: 'true',
                            //     message: 'This is required field!'
                            // },]}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'DRAW %'} placeholder={'DRAW %'} name={'person_draw_per'}
                            // rules={[{
                            //     required: 'true',
                            //     message: 'This is required field!'
                            // },]}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'EMP Match %'} placeholder={'EMP Match'} name={'person_emp_match'}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24} md={12}>

                    {/* // ==========  partner ======== */}


                    <Row gutter={[24, 24]}>
                        <Col span={24} md={12}>

                            <Input label={'Partner Name'} placeholder={'Name'} name={'partner_name'}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please Enter Your Name!',
                            //     }
                            // ]} 
                            />
                            <Input name={'id'} display={'none'} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Age'} placeholder={'Age'} name={'partner_age'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Desired Age'} placeholder={'Desired Age'} name={'partner_desire_age'}

                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={
                                    <>
                                        Goal<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }

                                placeholder={'Goal'} name={'partner_income_goal'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={
                                    <>
                                        Annual Income<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }
                                placeholder={'Annual Income'} name={'partner_total_household_income'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={
                                    <>
                                        SOC 2<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }
                                placeholder={'SOC 2'} name={'partner_social'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'COLA %'} placeholder={'COLA %'} name={'partner_cola_percent'}
                            // rules={[{
                            //     required: 'true',
                            //     message: 'This is required field!'
                            // },]}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={
                                    <>
                                        Pension 2<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }

                                placeholder={'Pension 2'} name={'partner_penson'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Inflation %'} placeholder={'Inflation'} name={'partner_inflation_percent'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={
                                    <>
                                        401k Balance<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }
                                placeholder={'401k Balance'} name={'partner_401k_balance'}
                            // rules={[{
                            //     required: 'true',
                            //     message: 'This is required field!'
                            // },]}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Distribution %'} placeholder={'Distribution'} name={'partner_contribution'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'ROR %'} placeholder={'ROR %'} name={'partner_ror_percent'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'DRAW %'} placeholder={'DRAW %'} name={'partner_draw_per'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'EMP Match %'} placeholder={'EMP Match'} name={'partner_emp_match'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={
                                    <>
                                        EPlan Pension<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }
                                placeholder={'EPlan Pension'} name={'e_penson'}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Flex center gap={'20px'} style={{ margin: '20px 0px' }}>
                <Button.Primary text={'UPDATE'} htmlType={'update'} />
                {/* <Button.Danger text={'RESET'} onClick={() => onReset()} /> */}
            </Flex>
        </Form>
    )
}

export default EditInnerPlan