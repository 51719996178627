import React, { useEffect, useState } from 'react'
import { Table } from '../../../Components/Table'
import { Row } from '../../../Components/Row';
import { Col } from 'antd';
import { TopTitle } from '../../../Components/Form/TopTitle';
import Label from '../../../Components/Form/Label';
import Input from '../../../Components/Form/Input';
import Flex from '../../../Components/Flex';
import { customFormat } from '../../../utils/customFormat';

const BasicTable = () => {

    const [searchText, setSearchText] = useState([]);

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const columns = [
        {
            title: 'SI No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Year',
            dataIndex: 'year',
        },
        {
            title: 'Age',
            dataIndex: 'Client Age',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.age).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.age).includes(value.toUpperCase());
            },
        },
        {
            title: 'Spouse Age',
            dataIndex: 'retire_age',
        },
        {
            title: 'Income Gap',
            dataIndex: 'income_goal',
            render: (value) => ` ${customFormat(value)}`,
        },
        {
            title: 'Income Goal',
            dataIndex: 'retire_ment_income',
            render: (value) => ` ${customFormat(value)}`,
        },
        {
            title: 'Income Gap',
            dataIndex: 'income_gap',
            render: (income_gap, record) => {
                return (
                    <span style={{ color: `${record.income_gap_color}` }}>
                        {
                            customFormat(income_gap)
                        }
                    </span>

                );
            }
        },
        {
            title: 'Retirement Income',
            dataIndex: 'e_penson',
            render: (value) => ` ${customFormat(value)}`,
        },
        {
            title: 'ePlan Pension Income',
            dataIndex: 'social1',
            render: (value) => ` ${customFormat(value)}`,
        },
        {
            title: 'Client Social Security',
            dataIndex: 'social2',
            render: (value) => ` ${customFormat(value)}`,
        },
        {
            title: 'Spouse Social Security',
            dataIndex: 'penson1',
            render: (value) => ` ${customFormat(value)}`,
        },
        {
            title: 'Client Pension',
            dataIndex: 'penson2',
            render: (value) => ` ${customFormat(value)}`,
        },
        {
            title: 'Spouse Pension',
            dataIndex: 'ins_income',
            render: (value) => ` ${customFormat(value)}`,
        },
        {
            title: 'Other Income',
            dataIndex: 'b_d',
            render: (value) => ` ${customFormat(value)}`,
        },
        {
            title: 'Investable Assets Income',
            dataIndex: 'b_up',
            render: (value) => ` ${customFormat(value)}`,
        },
        {
            title: 'Spouse ROTH Income',
            dataIndex: 'growth',
            render: (value) => ` ${customFormat(value)}`,
        },
        {
            title: 'Adjusted Ivestable Assets Income',
            dataIndex: 'annual_contiribution',
            render: (value) => ` ${customFormat(value)}`,
        },
        {
            title: 'Adjusted Ivestable Assets Balance',
            dataIndex: 'stage',
        },
        {
            title: 'Ivestable Assets Balance',
            dataIndex: 'stage',
        },
        {
            title: 'Spouse ROTH Balance',
            dataIndex: 'stage',
        },
        {
            title: 'ePlan Pension Balance',
            dataIndex: 'stage',
        },
    ]

    return (
        <div>
            <Row>
                <Col span={24} md={12}>
                    <TopTitle Heading={''} />
                </Col>
                <Col span={24} md={12}>
                    <Flex end centerVertically>
                        <Label>Search by Age : &nbsp;&nbsp;</Label>
                        <Input
                            placeholder="Search by Age"
                            value={searchText}
                            onSearch={handleSearch}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </Flex>
                </Col>
            </Row>
            <Table columns={columns} />
        </div>
    )
}

export default BasicTable