import React from 'react'
import { CustomCard } from '../../../../../Components/CustomCard'
import FormMain from './Form'


const PlanngForm = ({GetPlanDetails}) => {
    return (
        <div>
            <CustomCard>
                <FormMain GetPlanDetails={GetPlanDetails} />
            </CustomCard>
        </div>
    )
}

export default PlanngForm