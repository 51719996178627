import React from 'react'
import Button from '../../../Components/Form/Button'
import styled from 'styled-components'
import { Form, Input } from 'antd'
import Flex from '../../../Components/Flex'
import request from '../../../utils/request'
import Label from '../../../Components/Form/Label'
import { useForm } from 'antd/es/form/Form'
import { toast } from 'react-toastify'
import { Navigate, useNavigate } from 'react-router-dom'
import { Select } from '../../../Components/Form/Select'
import { CustomCard } from '../../../Components/CustomCard'
import { TopTitle } from '../../../Components/Form/TopTitle'
import { useEffect } from 'react'

const InputWrapper = styled.div`
  padding-bottom: 25px;

`
const Header = styled.div`
  color:#fff;
  margin-bottom:20px;
`

const Register = ({ AdminRegister }) => {

    const [form] = useForm();

    const GetAdvisorView = () => {
        request.get('advisor/view_advisor/')
            .then(function (response) {         
            })
            .catch(function (error) {
                console.error(error)
            })
    }

    const onFinish = values => {
        AdminRegister(values)
        form.resetFields();
        if (GetAdvisorView) {
            GetAdvisorView()

        }

    }

    const UseRoleOption = [
        {
            label: 'Admin',
            value: 'Admin',

        },
        {
            label: 'Advisor',
            value: 'Advisor',

        },
    ]

    return (
        <div style={{ margin: '30px 0px' }}>
            <CustomCard width={'800px'}  >
                <TopTitle Heading={'Register'} />
                <Form onFinish={onFinish}
                    form={form}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}>

                    <Flex center>
                        <Header>
                            {/* <h1 style={{ fontSize: '30px', }}>SignUp</h1> */}
                        </Header>
                    </Flex>
                    <InputWrapper style={{ padding: '5px 20px' }}>
                        <Form.Item
                            style={{ marginBottom: '0' }}
                            name="name"
                            label={<Label>User Name</Label>}
                            rules={[
                                { required: true, message: 'Please enter your Name' },
                            ]}
                        >
                            <Input size='large' placeholder="User Name" />
                        </Form.Item>
                    </InputWrapper>
                    <InputWrapper style={{ padding: '5px 20px' }}>
                        <Form.Item
                            style={{ marginBottom: '0' }}
                            name="user_role"
                            label={<Label>User Role</Label>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select a User Role',
                                },
                            ]}
                        >
                            <Select options={UseRoleOption} size='large' placeholder="User Role"
                            />
                        </Form.Item>
                    </InputWrapper>

                    <InputWrapper style={{ padding: '5px 20px' }}>
                        <Form.Item
                            style={{ marginBottom: '0' }}
                            name="email"
                            label={<Label>Email</Label>}
                            rules={[
                                { required: true, message: 'Please enter your email address' },
                            ]}
                        >
                            <Input type={'email'} size='large' placeholder="Email" />
                        </Form.Item>
                    </InputWrapper>

                    <InputWrapper style={{ padding: '5px 20px' }}>
                        <Form.Item
                            style={{ marginBottom: '0' }}
                            name="password"
                            label={<Label>Password</Label>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a password',
                                },
                            ]}
                        >
                            <Input.Password size='large' placeholder="Password" style={{ borderRadius: '8px' }} />
                        </Form.Item>
                    </InputWrapper>


                    <Form.Item style={{ padding: '5px 20px' }}>
                        <Flex center gap={'20px'} style={{ margin: '20px 0px' }}>
                            <Button.Success text={'Submit'} htmlType="submit" />
                            <Button.Danger text={'Cancel'} htmlType="reset" />
                        </Flex>
                    </Form.Item>

                </Form>
            </CustomCard>
        </div>

    )
}

export default Register
