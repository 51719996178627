import React from 'react'
import { Table } from '../../../Components/Table'

const ViewSurvive = () => {
    const columns = [

        {
            title: 'SI No',
            render: (text, value, index) => index + 1,
        },
        {
            title: 'Age',
            dataIndex: 'type',
        },
        {
            title: 'age',
            dataIndex: 'type',
        },
        {
            title: 'Requirement Income',
            dataIndex: 'type',
        },
        {
            title: 'Social Security',
            dataIndex: 'type',
        },
        {
            title: 'Social Security',
            dataIndex: 'type',
        },
        {
            title: 'Pension opt A',
            dataIndex: 'type',
        },
        {
            title: 'Life Insurance',
            dataIndex: 'type',
        },
        {
            title: 'Income Surplus(Short fall)',
            dataIndex: 'type',
        },
        {
            title: 'YES GIFT',
            dataIndex: 'type',
        },
        {
            title: 'Return',
            dataIndex: 'type',
            children:[
                {
                    title: 'Cross',
                    dataIndex: 'type', 
           
                        children:[
                            {
                                title: 'Net',
                                dataIndex: 'type',   
                            }
                        ]
                
                }
            ],
           
        },
        {
            title: 'Assets',
            dataIndex: 'assets',
        },


    ]

  return (
    <div>
        <Table columns={columns}/>
    </div>
  )
}

export default ViewSurvive