import React from 'react'
import AccumForm from './Partials/Form'
import AccumTableDetails from './Partials/TableDetails'
import { TopTitle } from '../../Components/Form/TopTitle'
import { CustomCard } from '../../Components/CustomCard'

const YoungAccum = () => {
    return (
        <div>
            <TopTitle Heading={'Annual Retirement Summary'} />
            <CustomCard>
                <AccumForm />
            </CustomCard><br />
            <TopTitle Heading={'View Details'} />
            <AccumTableDetails />
        </div>
    )
}

export default YoungAccum