import React from 'react'
import { Table } from '../../../Components/Table'
import { TopTitle } from '../../../Components/Form/TopTitle'

const OptimalAssets = ({optimalData}) => {

    const columns = [
        {
            title: 'Optimal Asset Allocation',
            dataIndex: 'asset_allocation'
        },
        {
            title: 'Amount',
            dataIndex: 'amount'
        },
        {
            title: 'Percent',
            dataIndex: 'per'
        },
    ]

    return (
        <div>
            <TopTitle Heading={'Optimal Asset'} />
            <Table columns={columns} data={optimalData} />
        </div>
    )
}

export default OptimalAssets