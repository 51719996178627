import React from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import styled from 'styled-components';
import { THEME } from '../../theme';

const StyledButton = styled.button`
  color:#0c343d ;
  border: none;
  border-radius: 8px;
  /* border: 1px solid #0c343d; */
  padding: 8px 12px;
  font-size: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.2s, transform 0.2s;

  &:hover {
    background-color: #0c343d ;
    transform: scale(1.05);
    color:white;
  }

  &:focus {
    outline: none;
  }

  & svg{
    font-size:23px;
  }
`;

const ButtonText = styled.span`
  margin-left: 0px;
`;

const EditButton = ({ onClick, text }) => (
  <StyledButton onClick={onClick}>
    <ButtonText>{text}</ButtonText>
  </StyledButton>
);

export default EditButton;

