import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai'
import Flex from '../../../Components/Flex'
import Button from '../../../Components/Form/Button'
import { TopTitle } from '../../../Components/Form/TopTitle'
import { Modal } from '../../../Components/Modal'
import { Table } from '../../../Components/Table'
import request from '../../../utils/request'
import DeleteLiabilities from '../Partials/DeleteLiabilities'
import EditLiabilities from '../Partials/EditLiabilities'
import { Row } from '../../../Components/Row'
import { Col } from 'antd'
import Label from '../../../Components/Form/Label'
import Input from '../../../Components/Form/Input'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../../Components/Form/BackButton'
import { FaArrowCircleLeft } from 'react-icons/fa'
import EditButton from '../../../Components/Form/EditButton'
import DeleteButton from '../../../Components/Form/DeleteButton'
import { CustomCard } from '../../../Components/CustomCard'
import { customFormat } from '../../../utils/customFormat'


const LiabilitiesTables = () => {

    const [tableData, setTableData] = useState([])

    const [getdata, setGetdata] = useState([])

    const [searchText, setSearchText] = useState([]);

    const [selectedClient, setSelectedClient] = useState({});

    const URLS = 'liabilites/add_liabilities/'
    const navigate = useNavigate();

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        HandleGetAssets();

    };

    const handleCloseCancel = () => {
        handleOk();
    }

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const columns = [
        {
            title: 'SI No',
            render: (text, value, index) => index + 1,
        },
        {
            title: 'Client Name',
            dataIndex: 'client_name',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.client_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.client_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',

        },
        {
            title: 'Account',
            dataIndex: 'account',

        },
        {
            title: 'Monthly Payments',
            dataIndex: 'monthly_payments',
            render: (value) =>`$ ${customFormat(value)}` ,

        },
        {
            title: 'Current Balance',
            dataIndex: 'current_balance',
            render: (value) =>`$ ${customFormat(value)}` ,

        },
        {
            title: 'Action',
            render: (record) => {
                return (

                    <Flex gap={'15px'} center  >
                        <EditButton onClick={() => { EditLiabilatityTable(record); }} text={<AiOutlineEdit  />} />
                        <DeleteButton onClick={() => { DeleteLiabilatityTable(record) }} text={<AiOutlineDelete  />} />
                    </Flex>
                )
            }

        },

    ]
    const EditLiabilatityTable = (record) => {
        setModalTitle("Update Liabilities");
        setModalContent(<EditLiabilities handleCloseCancel={handleCloseCancel} getdata={getdata} Getliabilities={Getliabilities} selectedClient={selectedClient}
            HandleGetAssets={HandleGetAssets} Libilititydatas={record} setGetdata={setGetdata} handleOks={handleOk}  />);
        showModal();
    }
    const DeleteLiabilatityTable = (record) => {
        setModalTitle("");
        setModalContent(<DeleteLiabilities datass={record} HandleGetAssets={HandleGetAssets} handleOk={handleOk} />);
        showModal();
    }

    const HandleGetAssets = () => {
        request.get(`${URLS}`)
            .then(function (response) {
                setTableData(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        HandleGetAssets();
    }, [])
    
    const Getliabilities = () => {
        request.get('assets/assets_people_values/')
            .then(function (response) {
                setGetdata(response.data)
                setSelectedClient(response.data)
            })
            .catch(function (error) {
                console.error(error)
            })
    }

    useEffect(() => {
        Getliabilities()
    }, [])

    return (
        <div>
            <Row>
                <Col span={24} md={8}>
                    <Flex gap={'15px'}>
                        <BackButton text={<FaArrowCircleLeft style={{fontSize:'26px'}}  />} onClick={() => navigate('/')} />
                        <TopTitle Heading={'Liabilities'} />
                    </Flex>
                </Col>
            </Row>
            <CustomCard>
                    <Row gutter={[16, 16]} >
                        <Col xs={24} sm={24} md={12}>
                            <Flex centerVertically  gap={'10px'}>
                                <Label>Search by Client Name :</Label>
                                <Input
                                    placeholder="Search by Client Name"
                                    value={searchText}
                                    onSearch={handleSearch}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                            </Flex>
                        </Col>
                        <Col xs={24} sm={24} md={12} >
                            <Flex end>
                            <Button.Primary
                                    text={'Add Liability'}
                                    icon={<AiOutlinePlus style={{ fontSize: '20px' }} />}
                                    onClick={() => navigate('/Liabilities')}
                                />
                            </Flex>                              
                        </Col>
                    </Row>
                </CustomCard>
            <Table columns={columns} data={tableData} />
            {/* <Button.Success text={'Back'} onClick={() => navigate('/')} /> */}

            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={500} modalTitle={modalTitle} modalContent={modalContent} />

        </div>
    )
}

export default LiabilitiesTables