import React, { useEffect, useState } from 'react'
import { Table } from '../../../Components/Table'
import { Row } from '../../../Components/Row';
import { Col } from 'antd';
import { TopTitle } from '../../../Components/Form/TopTitle';
import Label from '../../../Components/Form/Label';
import Input from '../../../Components/Form/Input';
import Flex from '../../../Components/Flex';
import { customFormat } from '../../../utils/customFormat';

const TableDetails = ({ currentData }) => {

    const [searchText, setSearchText] = useState([]);

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const columns = [
        {
            title: 'SI No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Year',
            dataIndex: 'year',
        },
        {
            title: 'Age',
            dataIndex: 'age',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.age).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.age).includes(value.toUpperCase());
            },
        },
        {
            title: 'Desired Age',
            dataIndex: 'retire_age',
        },
        {
            title: 'Income goal',
            dataIndex: 'income_goal',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: 'Retirement Income',
            dataIndex: 'retire_ment_income',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: 'Income Gap',
            dataIndex: 'income_gap',
            render: (income_gap, record) => {
                return (
                    <span style={{ color: `${record.income_gap_color}` }}>
                        {
                            customFormat(income_gap)
                        }
                    </span>

                );
            }
        },
        {
            title: 'ePlan Pension',
            dataIndex: 'e_penson',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: 'Soc 1',
            dataIndex: 'social1',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: 'Soc 2',
            dataIndex: 'social2',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: 'Pension 1',
            dataIndex: 'penson1',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: 'Pension 2',
            dataIndex: 'penson2',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: 'Income',
            dataIndex: 'ins_income',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: 'Balance Down',
            dataIndex: 'b_d',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: 'Balance Up',
            dataIndex: 'b_up',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: 'Growth',
            dataIndex: 'growth',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: 'Annual Contribution',
            dataIndex: 'annual_contiribution',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: 'Stage',
            dataIndex: 'stage',
        },
    ]

    return (
        <div>
            <Row>
                <Col span={24} md={12}>
                    <TopTitle Heading={''} />
                </Col>
                <Col span={24} md={12}>
                    <Flex end centerVertically>
                        <Label>Search by Age : &nbsp;&nbsp;</Label>
                        <Input
                            placeholder="Search by Age"
                            value={searchText}
                            onSearch={handleSearch}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </Flex>
                </Col>
            </Row>
            <Table columns={columns} data={currentData} />
        </div>
    )
}

export default TableDetails