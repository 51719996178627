import React, { useEffect, useState } from 'react'
import Currentchart from './Currentchart'
import CurrentAssets from './CurrentAssets'
import OptimalChart from './Optimalchart'
import OptimalAssets from './OptimalAssets'
import { Col } from 'antd'
import request from '../../../../utils/request'
import { Row } from '../../../../Components/Row'
import { TopTitle } from '../../../../Components/Form/TopTitle'
import Flex from '../../../../Components/Flex'
import Button from '../../../../Components/Form/Button'
import BackButton from '../../../../Components/Form/BackButton'
import { FaArrowCircleLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import PlanningBarChart from '../BarChart'

const PlangPieChart = ({currentData,graphtData}) => {

  const URL = 'e_app/current_asset_allocation/'

  const URLS = 'e_app/optimal_asset_allocation/'

  const navigate = useNavigate();

  const [currentdata, setCurrentdata] = useState([])
  const [optimalData, setoptimalData] = useState([])
  const [graphData, setGraphData] = useState([])
  const [optimalgraphdata, setOptimalgraphdata] = useState([])

  //========================Current Assets=====================================
  const Getitems = () => {
    request.get(`${URL}`)
      .then(function (response) {
        setCurrentdata(response.data?.Table)
        setGraphData(response.data?.Graph)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    Getitems();
  }, [])
  //========================Optimal Assets=====================================
  const GetOptimal = () => {
    request.get(`${URLS}`)
      .then(function (response) {
        setoptimalData(response.data?.Table)
        setOptimalgraphdata(response.data?.Graph)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetOptimal();
  }, [])


  return (
    <div>
      <Row gutter={[12, 12]}>
        <Col span={24} xs={24} sm={12} md={12} lg={12} xl={6} >
          <Flex gap={'15px'}>
            <BackButton text={<FaArrowCircleLeft style={{ fontSize: '26px' }} />} onClick={() => navigate('/')} />
            <TopTitle Heading={'Current Asset'} />
          </Flex>
          <Currentchart graphData={graphData}/>
        </Col>
        <Col span={24}  xs={24} sm={12} md={12} lg={12} xl={6} >
          <TopTitle Heading={'Optimal Asset'} />

          <OptimalChart optimalgraphdata={optimalgraphdata} />
        </Col>
        <Col span={24} xs={24} sm={24} md={24} lg={24} xl={12} >
          {/* <Flex end>
            <Button htmlType={'submit'} style={{
              background: '#3498db', color: 'white', fontWeight: '500', padding: '50x',
              width: '190px', height: '70px', border: 'solid 1px black'
            }} >Schedule Consultation</Button>
          </Flex> */}
          <TopTitle Heading={'Income Gap'} />
          <div style={{ margin: '16px 10px' }}>
            <PlanningBarChart currentData={currentData} graphtData={graphtData} />
          </div>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col sm={24} md={12}>
          <CurrentAssets currentdata={currentdata} />
        </Col>
        <Col sm={24} md={12}>
          <OptimalAssets optimalData={optimalData} />
        </Col>
      </Row>


    </div>
  )
}

export default PlangPieChart