import { SET_PLANNING,DELETE_COMPANY_PROFILE } from "./actions"


const initialState = {
    planningrules: {},
}

export default (state = initialState, action) => {
    switch (action.type) {

        case SET_PLANNING:
            return { ...state, planningrules: action.payload }
            case DELETE_COMPANY_PROFILE:
                return { ...state, planningrules: {} }
        default:
            return state
    }
}
