import { Col, Form } from 'antd';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Flex from '../../../../Components/Flex';
import Button from '../../../../Components/Form/Button';
import { CustomInputNumber } from '../../../../Components/Form/CustomInputNumber';
import Input from '../../../../Components/Form/Input';
import { TopTitle } from '../../../../Components/Form/TopTitle';
import { Row } from '../../../../Components/Row';
import request from '../../../../utils/request';
import Partnerform from './Partnerform';
import { Modal } from '../../../../Components/Modal';
import EditInnerPlan from './EditInnerPlan';
import { useNavigate, useParams, useRevalidator, useRouteLoaderData } from 'react-router-dom';
import { customFormat } from '../../../../utils/customFormat';

const AddForm = ({ GetPlanner }) => {

    const [form] = Form.useForm();
    const { id } = useParams()

    const GETPEOPL = `advisor/view_consumer_profile/${id}`
    const GETEDIT = `income_planner/profile_get_income_planner/${id}/`

    const [people, setPeople] = useState({})
    const [button, setButton] = useState(null);
    const [trigger,setTrigger] = useState(0)
    const [plannerpost, setPlannerpost] = useState({})
    const [adminView, setAdminView] = useState([])

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleEdit = () => {
        GetPlannerEdit();
        GetPlanner()
        handleOk()
    }

    const EditEplan = (values) => {
        setTrigger(trigger+1)
        setModalTitle("Edit Details");
        setModalContent(<EditInnerPlan planner={plannerpost} handleEdit={handleEdit} GetPlanner={GetPlanner} trigger={trigger} />);
        showModal();
    }

    const onFinish = (values) => {
        const record = { ...values }
        const newvalue = {
            id: record.id,
            people: record.people,
            person_name: record.person_name,
            person_age: record.person_age,
            person_desire_age: record.person_desire_age,
            person_income_goal: record.person_income_goal,
            person_total_household_income: record.person_total_household_income,
            person_social: record.person_social,
            person_cola_percent: record.person_cola_percent,
            person_penson: record.person_penson,
            person_inflation_percent: record.person_inflation_percent,
            person_401k_balance: record.person_401k_balance,
            person_contribution: record.person_contribution,
            person_ror_percent: record.person_ror_percent,
            person_draw_per: record.person_draw_per,
            person_emp_match: record.person_emp_match,
            partner_name: record.partner_name,
            partner_age: record.partner_age,
            partner_desire_age: record.partner_desire_age,
            partner_income_goal: record.partner_income_goal,
            partner_total_household_income: record.partner_total_household_income,
            partner_ror_percent: record.partner_ror_percent,
            partner_social: record.partner_social,
            partner_cola_percent: record.partner_cola_percent,
            partner_penson: record.partner_penson,
            partner_inflation_percent: record.partner_inflation_percent,
            partner_401k_balance: record.partner_401k_balance,
            partner_contribution: record.partner_contribution,
            partner_draw_per: record.partner_draw_per,
            partner_emp_match: record.partner_emp_match,
            e_penson: record.e_penson
        }
        PlannerPost(newvalue)
    };

    const onFinishFailed = (errorInfo) => {
        toast.error('Please Fill the Details')
        console.log('Failed:', errorInfo);
    };

    const PlannerPost = (values) => {
        request.post(`income_planner/profile_add_income_planner/${id}/`, values)
            .then(function (response) {
                console.log(response.data)
                toast.success('Successfully Added Details!')
                form.resetFields();
                GetPlanner();
                GetPlannerPeopl()
                GetPlannerEdit()
            })
            .catch(function (error) {
                toast.error('failed')
                console.log(error);
            });
    }

    const GetPlannerPeopl = () => {
        request.get(`${GETPEOPL}`)
            .then(function (response) {
                setPeople(response.data.people)
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const GetPlannerEdit = () => {
        request.get(`${GETEDIT}`)
            .then(function (response) {

                if (response.status === 200) {
                    setPlannerpost(response.data)
                    setButton(
                        <Button.Primary text={'UPDATE'} onClick={() => EditEplan()} />
                    );
                } else if (response.status === 204) {
                    setButton(
                        <>
                            <Button.Primary text={'SUBMIT'} htmlType={'submit'} />
                            <Button.Danger text={'CANCEL'} onClick={() => onReset()} />
                        </>
                    );
                } else {
                    setButton(null);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        GetPlannerPeopl();
        GetPlannerEdit();
    }, [])

    useEffect(() => {
        form.setFieldsValue({ people: people.id })
    }, [people])


    useEffect(() => {
        const Incomegoal = plannerpost.person_income_goal !== undefined ? customFormat(plannerpost.person_income_goal) : undefined;
        const AnnualIncomePersonHosehold = plannerpost.person_total_household_income !== undefined ? customFormat(plannerpost.person_total_household_income) : undefined;
        const Soc1 = plannerpost.person_social !== undefined ? customFormat(plannerpost.person_social) : undefined;
        const Pension1 = plannerpost.person_penson !== undefined ? customFormat(plannerpost.person_penson) : undefined;
        const Fourty1kPersonbalance = plannerpost.person_401k_balance !== undefined ? customFormat(plannerpost.person_401k_balance) : undefined;
        const PartnerIncomegoal =  plannerpost.partner_income_goal !== undefined ? customFormat(plannerpost.partner_income_goal) : undefined;
        const AnnualIncomePartnerHosehold = plannerpost.partner_total_household_income !== undefined ? customFormat(plannerpost.partner_total_household_income) : undefined;
        const Soc2 = plannerpost.partner_social !== undefined ? customFormat(plannerpost.partner_social) : undefined;
        const Pension2 = plannerpost.partner_penson !== undefined ? customFormat(plannerpost.partner_penson) : undefined;
        const Fourty1kPartnerbalance = plannerpost.partner_401k_balance !== undefined ? customFormat(plannerpost.partner_401k_balance) : undefined;
        const EPension = plannerpost.e_penson !== undefined ? customFormat(plannerpost.e_penson) : undefined;


        form.setFieldsValue({ person_name: plannerpost.person_name })
        form.setFieldsValue({ person_age: plannerpost.person_age })
        form.setFieldsValue({ person_desire_age: plannerpost.person_desire_age })
        form.setFieldsValue({ person_social: Soc1 })
        form.setFieldsValue({ person_income_goal: Incomegoal })
        form.setFieldsValue({ person_total_household_income: AnnualIncomePersonHosehold})
        form.setFieldsValue({ person_cola_percent: plannerpost.person_cola_percent })
        form.setFieldsValue({ person_penson: Pension1 })
        form.setFieldsValue({ person_inflation_percent: plannerpost.person_inflation_percent })
        form.setFieldsValue({ person_contribution: plannerpost.person_contribution })
        form.setFieldsValue({ person_401k_balance: Fourty1kPersonbalance })
        form.setFieldsValue({ person_ror_percent: plannerpost.person_ror_percent })
        form.setFieldsValue({ person_draw_per: plannerpost.person_draw_per })
        form.setFieldsValue({ person_emp_match: plannerpost.person_emp_match })


        form.setFieldsValue({ partner_name: plannerpost.partner_name })
        form.setFieldsValue({ partner_age: plannerpost.partner_age })
        form.setFieldsValue({ partner_desire_age: plannerpost.partner_desire_age })
        form.setFieldsValue({ partner_social: Soc2 })
        form.setFieldsValue({ partner_cola_percent: plannerpost.partner_cola_percent })
        form.setFieldsValue({ partner_penson: Pension2})
        form.setFieldsValue({ partner_inflation_percent: plannerpost.partner_inflation_percent })
        form.setFieldsValue({ partner_contribution: plannerpost.partner_contribution })
        form.setFieldsValue({ partner_401k_balance: Fourty1kPartnerbalance})
        form.setFieldsValue({ partner_ror_percent: plannerpost.partner_ror_percent })
        form.setFieldsValue({ partner_emp_match: plannerpost.partner_emp_match })
        form.setFieldsValue({ partner_income_goal: PartnerIncomegoal})
        form.setFieldsValue({ e_penson: EPension })
        form.setFieldsValue({ partner_draw_per: plannerpost.partner_draw_per })
        form.setFieldsValue({ partner_total_household_income: AnnualIncomePartnerHosehold})

    }, [plannerpost])

    const onReset = () => {
        form.resetFields();
    }

    useEffect(() => {
        form.setFieldsValue({ id: id })
    }, [id])
    console.log(adminView, 'adminViewlll');

    useEffect(() => {
        GetAdvisorView()
    }, [])

    const GetAdvisorView = () => {
        request.get('advisor/view_consumer/')
            .then(function (response) {
                setAdminView(response.data)
                console.log(response.data, 'adminview')
            })
            .catch(function (error) {
                console.error(error)
            })
    }

    return (
        <div>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}

                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">
               <TopTitle Heading={'Client Details'} />
                <Row gutter={[24, 24]}>
                    <Col span={24} md={8}>
                        {/* <Input name={'id'} display={'none'}/> */}
                        <Input name={'people'} display={'none'} />
                        <Input label={'Name'} placeholder={'Name'} name={'person_name'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Your Name!',
                                }
                            ]} />
                    </Col>
                    <Input name={'id'} display={'none'} />
                    <Col span={24} md={8}>
                        <CustomInputNumber label={'Age'} placeholder={'Age'} name={'person_age'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Your Age!',
                                },

                            ]} />
                    </Col>
                    <Col span={24} md={8}>
                        <CustomInputNumber label={'Desired Age'} placeholder={'Desired Age'}
                            name={'person_desire_age'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Your Desired Age!',
                                }
                            ]}
                        />
                    </Col>
                    <Col span={24} md={8}>
                        <CustomInputNumber
                            label={
                                <>
                                    Goal<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                </>
                            }
                            placeholder={'Goal'} name={'person_income_goal'}
                            rules={[{
                                required: 'true',
                                message: 'Please Enter your Goal !'
                            },]} />
                    </Col>
                    <Col span={24} md={8}>
                        <CustomInputNumber
                            label={
                                <>
                                    Annual Income<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }
                            placeholder={'Annual Income'} name={'person_total_household_income'}
                        />
                    </Col>
                    <Col span={24} md={8}>
                        <CustomInputNumber
                            label={
                                <>
                                    SOC 1<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }
                            placeholder={'SOC 1'} name={'person_social'}
                        />
                    </Col>
                    <Col span={24} md={8}>
                        <CustomInputNumber label={'COLA %'} placeholder={'COLA %'} name={'person_cola_percent'}
                        />
                    </Col>
                    <Col span={24} md={8}>
                        <CustomInputNumber
                            label={
                                <>
                                    Pension 1<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }
                            placeholder={'Pension 1'} name={'person_penson'}
                        />
                    </Col>
                    <Col span={24} md={8}>
                        <CustomInputNumber label={'Inflation %'} placeholder={'Inflation'} name={'person_inflation_percent'}

                        />
                    </Col>
                    <Col span={24} md={8}>
                        <CustomInputNumber
                            label={
                                <>
                                    401k Balance<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }
                            placeholder={'401k Balance'} name={'person_401k_balance'}
                        />
                    </Col>
                    <Col span={24} md={8}>
                        <CustomInputNumber label={'Contributions %'} placeholder={'Contributions'} name={'person_contribution'}
                        />
                    </Col>
                    <Col span={24} md={8}>
                        <CustomInputNumber label={'ROR %'} placeholder={'ROR %'} name={'person_ror_percent'}
                        // rules={[{
                        //     required: 'true',
                        //     message: 'This is required field!'
                        // },]}
                        />
                    </Col>
                    <Col span={24} md={8}>
                        <CustomInputNumber label={'DRAW %'} placeholder={'DRAW %'} name={'person_draw_per'}
                        // rules={[{
                        //     required: 'true',
                        //     message: 'This is required field!'
                        // },]}
                        />
                    </Col>
                    <Col span={24} md={8}>
                        <CustomInputNumber label={'EMP Match %'} placeholder={'EMP Match'} name={'person_emp_match'}
                        />
                    </Col>
                </Row><br />
                <TopTitle Heading={'Spouse Details'} />
                <Partnerform />
                <Flex center gap={'20px'} style={{ margin: '20px 0px' }}>
                    {/* <Button.Primary text={'SUBMIT'} htmlType={'submit'} /> */}
                    {/* <Button.Success text={'Back'} onClick={() => navigate(`/View/${id}`)} /> */}
                    {button}

                </Flex>
            </Form>
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={1000} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

export default AddForm