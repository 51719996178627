import { Col, Tooltip } from 'antd';
import React from 'react';
import { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { MdArrowRightAlt } from 'react-icons/md';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Flex from '../../../Components/Flex';
import { Row } from '../../../Components/Row';
import { authenticated3 } from '../../../routes/config/user';
import { BacCard, DashCard, Tablestyledd, TopBox } from './Style';
import { THEME } from '../../../theme';
import { TableIconHolder } from '../../../Components/Styled';
import { CustomPopconfirm } from '../../../Components/CustomPopConfirm';
import { useDispatch, useSelector } from 'react-redux';
import { parographData } from '../../../Assets/DashboardData';
import { Components } from 'antd/es/date-picker/generatePicker';
import request from '../../../utils/request';
import { useEffect } from 'react';
import { DashboardView } from '../../Auth/actions';
import { setPlanningRules } from '../../PlannningDetailsTotal/actions';

const Dashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id } = useParams();

    const [popconfirmVisible, setPopconfirmVisible] = useState(false);
    const [decriptionShow, setDecriptionShow] = useState(false);

    const SuperUser = useSelector((state) => state?.auth?.token?.role)
    const Viewid = useSelector((state) => state?.auth?.pageview?.id)


    const handleConfirm = (routePath, description) => {
        if (popconfirmVisible && popconfirmVisible === routePath) {
            navigate('/Home');
        } else {
            navigate(`/${routePath}`);
        }
        setPopconfirmVisible(false)
        if (decriptionShow && decriptionShow == description) {
            setPopconfirmVisible(false)

        }
    };

    const handlePopConfrmCancel = () => {
        setPopconfirmVisible(false)
    };
    useEffect(() => {
        Getadvisorallpage();
    }, [])

    const Getadvisorallpage = () => {
        request.get(`advisor/view_consumer_profile/${id}`)
            .then(function (response) {
            })
            .catch(function (error) {
                console.error(error)
            })
    }
    useEffect(() => {
        dispatch(DashboardView())
    }, [])




    return (
        <div>
            <div style={{ margin: '20px 0px' }} >
                <Row gutter={[24, 24]} >

                    {authenticated3.map(({ h1, routePath, routePath1, routePath2, description, Components, key }, i) => (

                        <Col key={i} span={24} md={8} >
                            <BacCard >
                              <h1 onClick={() => navigate(routePath2)} onChange={Components}>{h1}</h1>
                            </BacCard>

                        </Col>
                    ))}

                </Row>
            </div>
        </div>
    );
};

export default Dashboard;
