import React from 'react'
import MyincomePage from './Partials/MyincomePage'
import { TopTitle } from '../../Components/Form/TopTitle'
import { CustomCard } from '../../Components/CustomCard'
import { useEffect } from 'react'
import request from '../../utils/request'
import { useState } from 'react'
import BackButton from '../../Components/Form/BackButton'
import { FaArrowCircleLeft } from 'react-icons/fa'
import Flex from '../../Components/Flex'
import { useNavigate } from 'react-router-dom'

const MyIncome = () => {

    const URL = 'income/add_my_income/'

    const navigate = useNavigate()

    const [incomeData, setIncomeData] = useState([])

    const GetIncomes = () => {
        request.get(`${URL}`)
            .then(function (response) {
                setIncomeData(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    useEffect(() => {
        GetIncomes();
    }, [])

    return (
        <div>
            <>
            <Flex gap={'15px'}>
                    <BackButton text={<FaArrowCircleLeft style={{ fontSize: '26px' }} />} onClick={() => navigate(`/`)} />
                    <TopTitle Heading={'Income'} />
                </Flex>
                <CustomCard>
                    <MyincomePage GetIncomes={GetIncomes} incomeData={incomeData} />
                </CustomCard>
            </>
        </div>
    )
}

export default MyIncome