import React, { Fragment } from 'react'
import AuthPage from './AuthPage'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import { adminAuthenticated, anonymous } from '../config/user'
import { useEffect } from 'react'
import request from '../../utils/request'
import { useDispatch } from 'react-redux'
import { LogOutSuccess } from '../../Modules/Auth/actions'
import { toast } from 'react-toastify'
import initializeApp from '../../utils/initializeApp'

const AuthRouter = ({ isAuthenticated, isSuperAuthenticated }) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const location = useLocation();

  useEffect(() => {
    GetTokenExpire();
  }, [location?.pathname])

  const GetTokenExpire = () => {

    request.get('token_app/token_check')
      .then(function (response) {
        console.log(response.data,'tokenexpire');
        if (response.data?.avenger) {
          toast.warn("User Expire")
          dispatch(LogOutSuccess())
          navigate('/signin')
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/signin')
      toast('Welcome ePlan Advisors !')
    }
  }, [isAuthenticated])

  return (

    <Fragment>
      {
        isAuthenticated ? (
          <AuthPage isAuthenticated={isAuthenticated} />
        ) :
          (
            <Routes>
              {anonymous.map(({ routePath, Component }) => {
                return (
                  <Route
                    key={routePath}
                    path={routePath}
                    element={<Component />}
                  ></Route>
                )
              })}
            </Routes>
          )
      }
    </Fragment>
  )
}

export default AuthRouter
