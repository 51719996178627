import { toast } from "react-toastify"
import initializeApp from "../../utils/initializeApp"
import request from "../../utils/request"

export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const ADMIN_REGISTER_SUCCESS = 'ADMIN_REGISTER_SUCCESS'
export const DASHBOARD_VIEW ='DASHBOARD_VIEW'

export const SignInSuccess = token => {
  return {
    type: SIGN_IN_SUCCESS,
    token,
  }
}

export const LogOutSuccess = () => {
  console.log('called')
  return {
    type: LOGOUT_SUCCESS,
  }
}
export const RegisterSuccess = supertoken => {
  return {
    type: ADMIN_REGISTER_SUCCESS,
    supertoken,
  }
}
export const AllPageView = pageview => {
  return {
    type: DASHBOARD_VIEW,
    pageview,
  }
}

export const AdminRegister = data => async dispatch => {
  try {
    const adminAuthData = await request.post(`api/admin_register/`, {
      ...data,
     
    })

    console.log(adminAuthData,'superlogin errooror')
       
    if (adminAuthData.status === 201) {
      toast.success('Created Successfully!')   
      dispatch(RegisterSuccess(adminAuthData.data))
    } 
          
    else if (adminAuthData.status === 204) {
      toast.warn('User Profile already exits!')   
      dispatch(RegisterSuccess(adminAuthData.data))
    }else {
      toast.error('Please Check Your Email and Password!')
    }
  } catch (error) {
    console.log(error)
    toast.error('Connection Error.')
  }
}

export const DashboardView = data => async dispatch => {
  try {
    const responseData = await request.get(`advisor/view_consumer_profile/`, {
      ...data,
     
    })
    
    console.log(responseData,'responseDataget')
    dispatch(AllPageView(responseData.data))
       
  } catch (error) {
    console.log(error)
  }
}

