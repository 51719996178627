import React, { useEffect } from 'react'
import FormMain from './Form'
import { CustomCard } from '../../../../Components/CustomCard'
import request from '../../../../utils/request'

const PlanngForm = ({GetPlanDetails}) => {
    useEffect(() => {
        GetNEwPlanning()
    }, [])

    const GetNEwPlanning = () => {
        request.get(`e_app/planning_details`)
            .then((response) => {
            })
            .catch(error => {
                console.log(error);
            })
    }
    return (
        <div>
            <CustomCard>
                <FormMain GetPlanDetails={GetPlanDetails} />
            </CustomCard>
        </div>
    )
}

export default PlanngForm