import React, { useEffect, useState } from 'react'
// import styled from 'styled-components'
import styled, { keyframes } from 'styled-components';
import Flex from '../../../Components/Flex'
import SignInForm from './SignInForm'
import { useNavigate } from 'react-router-dom'
import SignupForm from './SignupForm'
import { Tabs } from '../../../Components/Tabs'
import LoginTopLogo from '../../../Assets/Images/Signlogo.png'
import { SignInSuccess } from '../actions';
import { useDispatch } from 'react-redux';
import initializeApp from '../../../utils/initializeApp';
import { toast } from 'react-toastify';
import request from '../../../utils/request';
import { THEME } from '../../../theme';

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

export const Wrapper = styled(Flex)`
  height: 100vh;
  width: 100%;
  background: ${THEME.primary_color};
  & img {
    width:100%;
    background:#fff;
    margin: 10px 10px;
     border-radius:0px  10px 0px 10px;
     box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  }
  /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  animation: ${gradientAnimation} 15s ease infinite; */
  /* background-repeat: no-repeat;
  background-size: cover;  */
`
const Headings = styled.div`
font-size: 20px;
font-weight: 600;
display: flex;
justify-content: center;
padding-bottom: 10px;
border-bottom: 1px solid;
`

const SignInCard = styled.div`
  background-color:#fff;
  /* backdrop-filter:blur(1px); */
  padding: 30px 52px;
  border-radius:0px 25px 0px 25px;
  max-width: 450px;
  width: 100%;
  /* height: 80%; */
  margin: auto;
  border: 1px solid #949292;
  margin-bottom: 100px;
`
const UserSignin = ({ token, SignIn }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // const handleSignIn = data => {
  //   SignIn(data)
  //   dispatch(SignInSuccess(data))
  // }
  const handleSignIn = async (data) => {
    try {
      const authData = await request.post(`api/login`, {
        ...data,
      })

      if (authData?.data !== '') {
        initializeApp(authData?.data?.token)
        toast.success(`Login Successful, Welcome ${authData?.data?.username}`)
        dispatch(SignInSuccess(authData?.data))
        navigate('/')
      }
      else {
        toast.warn('UserName or Password is incorrect!')
      }

    } catch (error) {
      toast.error('Getting error while login, Please Login Again')

      console.error('Getting error while login', error)
    }
  }


  useEffect(() => {
    if (token) {
      // if()
      navigate('/signin')
    }
  }, [token])

  const items = [
    {
      key: '1',
      label: `SignIn`,
      children: <SignInForm handleSignIn={handleSignIn} />,
    },
    {
      key: '2',
      label: `SignUp`,
      children: <SignupForm />,
    },
  ];

  return (
    <Wrapper column>
      <div style={{ width: '200px', height: 'auto' }}>
        <a href='https://eplanadvisors.com/' target="_blank" >
          <img
            src={LoginTopLogo}
            alt=""
            style={{ cursor: 'pointer' }}
          />
        </a>
      </div>
      <SignInCard>
        <Headings >
          My-ePlan
        </Headings>
        <br />
        <div className="tabs">
          <Tabs items={items} />
        </div>
      </SignInCard>
    </Wrapper>
  )
}
export default UserSignin
