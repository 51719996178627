import React, { useEffect, useState } from 'react'
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai'
import { FaArrowCircleLeft } from 'react-icons/fa'
import Flex from '../../../Components/Flex'
import Button from '../../../Components/Form/Button'
import { Modal } from '../../../Components/Modal'
import { Table } from '../../../Components/Table'
import DeleteAssets from '../../AddAssets/Partials/DeleteAssets'
import { EditAssets } from '../../AddAssets/Partials/EditAssets'
import { Row } from '../../../Components/Row'
import { Col } from 'antd'
import { TopTitle } from '../../../Components/Form/TopTitle'
import Label from '../../../Components/Form/Label'
import Input from '../../../Components/Form/Input'
import { useNavigate, useParams } from 'react-router-dom'
import request from '../../../utils/request'
import EditButton from '../../../Components/Form/EditButton'
import BackButton from '../../../Components/Form/BackButton'
import DeleteButton from '../../../Components/Form/DeleteButton'
import { CustomCard } from '../../../Components/CustomCard'
import { customFormat } from '../../../utils/customFormat'

const UserAssets = () => {

    const navigate = useNavigate();
    const [width, setWidth] = useState(0)

    const [dataSource, setDataSource] = useState([])

    const URLS = 'assets/add_assets'
    const { id } = useParams();

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [searchText, setSearchText] = useState([]);



    useEffect(() => {
        HandleGetAssets();
    }, [])

    const HandleGetAssets = () => {
        request.get(`${URLS}`)
            .then(function (response) {
                setDataSource(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const handleSearch = (value) => {
        setSearchText(value);
    };
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        HandleGetAssets()
        setIsModalOpen(false);
    };


    const columns = [
        {
            title: 'SI No',
            render: (text, value, index) => index + 1,
        },
        {
            title: 'Client Name',
            dataIndex: 'client_name',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.client_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.client_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: 'Account',
            dataIndex: 'account',
        },
        {
            title: 'Risk',
            dataIndex: 'risk',
        },
        {
            title: 'Contribution Amount',
            dataIndex: 'contribution_amt',
            render: (value) =>`$ ${customFormat(value)}` ,
        },
        {
            title: 'Current Balance',
            dataIndex: 'current_bal_amt',
            render: (value) =>`$ ${customFormat(value)}` ,
        },
        {
            title: 'Action',
            render: (record) => {
                return (

                    <Flex gap={'15px'} center >
                        <EditButton onClick={() => { EditAssetsTable(record); }} text={<AiOutlineEdit />} />
                        <DeleteButton onClick={() => { DeleteAssetsTable(record); }} text={<AiOutlineDelete />} />
                    </Flex>
                )
            }
        },

    ]
    const EditAssetsTable = (record) => {
        setWidth(600)
        setModalTitle('Edit details')
        setModalContent(<EditAssets datas={record} handleOk={handleOk} HandleGetAssets={HandleGetAssets} />);
        showModal();
    }
    const DeleteAssetsTable = (record) => {
        setWidth(500)
        setModalTitle('')
        setModalContent(<DeleteAssets datass={record} HandleGetAssets={HandleGetAssets} handleOk={handleOk} />);
        showModal();
    }

    return (
        <div style={{ marginBottom: '50px' }}>
            <Row>
                <Col span={24} md={6}>
                    <Flex gap={'15px'}>
                        <BackButton text={<FaArrowCircleLeft style={{ fontSize: '26px' }} />} onClick={() => navigate('/')} />
                        <TopTitle Heading={'Assets'} />
                    </Flex>
                </Col>
            </Row>
            <CustomCard>
                <Row gutter={[16, 16]} >
                    <Col xs={24} sm={24} md={12}>
                        <Flex centerVertically gap={'10px'}>
                            <Label>Search by Client Name :</Label>
                            <Input
                                placeholder="Search by Client Name"
                                value={searchText}
                                onSearch={handleSearch}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </Flex>
                    </Col>
                    <Col xs={24} sm={24} md={12} >
                        <Flex end>
                            <Button.Primary
                                text={'Add Assets'}
                                icon={<AiOutlinePlus style={{ fontSize: '20px' }} />}
                                onClick={() => navigate('/Assetsmain')}
                            />
                        </Flex>
                    </Col>
                </Row>
            </CustomCard>
            <Table columns={columns} data={dataSource} />
            <div style={{ margin: '20px 0px' }} >
            </div>
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={width} modalTitle={modalTitle} modalContent={modalContent} />

        </div>
    )
}

export default UserAssets