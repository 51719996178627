
import React from 'react'
import { useState } from 'react';
import { Modal } from '../../../Components/Modal';
import { Table } from '../../../Components/Table'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import Flex from '../../../Components/Flex';
import { useParams } from 'react-router-dom';
import TableDelet from './TableDelet';
import TypeEditForm from './TypeEditForm';
import { Row } from '../../../Components/Row';
import { Col } from 'antd';
import { TopTitle } from '../../../Components/Form/TopTitle';
import Label from '../../../Components/Form/Label';
import Input from '../../../Components/Form/Input';
import EditButton from '../../../Components/Form/EditButton';
import DeleteButton from '../../../Components/Form/DeleteButton';

const EditTypes = ({ HandleGetAssets, dataSource, GetTabelTypes, GetTypesData, setInitialChange }) => {

    const [searchText, setSearchText] = useState([]);

    const { id } = useParams();
    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        GetTabelTypes();
        setIsModalOpen(false);
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const EditTypeForm = (record) => {
        setModalTitle("EDIT Type");
        setModalContent(<TypeEditForm HandleGetAssets={HandleGetAssets} GetTabelTypes={GetTabelTypes} GetTypesData={GetTypesData}
            setInitialChange={setInitialChange}
            datas={record} handleOk={handleOk} handleCancel={handleCancel} />);
        showModal();
    }

    const DeleteLiabilities = (record) => {
        // setModalTitle("");
        setModalContent(<TableDelet HandleGetAssets={HandleGetAssets} setInitialChange={setInitialChange}
            GetTabelTypes={GetTabelTypes} handleOk={handleOk} datas={record} GetTypesData={GetTypesData} />);
        showModal();
    }

    const columns = [

        {
            title: 'SI No',
            render: (text, value, index) => index + 1,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.type).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.type).includes(value.toUpperCase());
            },
        },
        {
            title: 'Action',
            render: (record) => {
                return (

                    <Flex gap={'50px'} center >
                        <EditButton onClick={() => { EditTypeForm(record); }} text={<AiOutlineEdit style={{ fontSize: '20px' }} />} />
                        <DeleteButton onClick={() => { DeleteLiabilities(record) }} text={<AiOutlineDelete style={{ fontSize: '20px' }} />} />
                    </Flex>
                )
            }

        },

    ]

    return (
        <div>
            <Row>
                <Col span={24} md={12}>
                    <TopTitle Heading={'Liability Type'} />
                </Col>
                <Col span={24} md={12}>
                    <Flex end centerVertically style={{ paddingLeft: '10px', margin: '0px 20px' }}>
                        <Label>Search by Type : &nbsp;&nbsp;</Label>
                        <Input
                            placeholder="Search by Type"
                            value={searchText}
                            onSearch={handleSearch}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </Flex>
                </Col>
            </Row>
            <Table columns={columns} data={dataSource} />
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={500} modalTitle={modalTitle} modalContent={modalContent} />

        </div>
    )
}

export default EditTypes