import React, { useEffect } from 'react';
import { Col, Form } from 'antd';
import { Fragment } from 'react';
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';
import Input from '../../../Components/Form/Input';
import { Row } from '../../../Components/Row';
import request from '../../../utils/request';
import { toast } from 'react-toastify';


const EditAdvisor = ({handlerecord, handleCheckReq,trigger }) => {
   
    const [form] = Form.useForm();

    const onFinish = (values, id) => {
        EditAdvisor(values, values.id)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onReset = () => {
        handleCheckReq();
    }
    //====================Edit======================//

    const EditAdvisor = (values, id) => {
        request.patch(`api/advisor_user_edit/${id}`, values)
            .then(function (response) {
                if(handleCheckReq){
                    handleCheckReq();
                }
                toast.info('Successfully Updated')
            })
            .catch(function (error) {
                console.error(error)
            })
    }

    useEffect(() => {
        form.setFieldsValue(handlerecord);
    }, [handlerecord]);

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(handlerecord);
    }, [handlerecord,trigger]);
    
    //===============register get=====================//

    return (
        <Fragment>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}

                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <Row gutter={[12, 12]}>

                    <Col span={24} >
                        <Input label={'Name'} placeholder={'Name'} name={'name'}
                            precision={2}
                        />
                    </Col>
                    <Col span={24} >
                        <Input label={'Email'} type={'email'} placeholder={'Email'} name={'email'} rules={[
                            {
                                required: true,
                                message: 'Please Enter Your Email!',
                            }
                        ]} />
                    </Col>
                    <Col span={24} >
                        <Input label={'Password'} type={'password'}   placeholder={'Password'} name={'password'} 
                            />
                    </Col>
                    <Col span={24} >
                        <Input label={'User Role'} placeholder={'User Role'} name={'user_role'} disabled  rules={[
                            {
                                required: true,
                                message: '',
                            }
                        ]} />
                    </Col>
                    <Input name={'id'} display={'none'}  />
                </Row>
                <Flex center gap={'20px'} style={{ margin: '20px 0px' }}>
                    <Button.Primary text={'SAVE'} htmlType={'submit'} />
                    <Button.Danger text={'RESET'} onClick={() => onReset()} />
                </Flex>

            </Form>
        </Fragment>
    )
}

export default EditAdvisor