import React from 'react'
import { Table } from '../../../Components/Table'

const AccumTableDetails = () => {

    const columns = [
        {
            title: 'SI No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Years',
            dataIndex: 'year',
        },
        {
            title: 'Age',
            dataIndex: 'age',
            // filteredValue: searchText ? [searchText] : null,
            // onFilter: (value, record) => {
            //     return String(record.age).toLowerCase().includes(value.toLowerCase()) ||
            //         String(record.age).includes(value.toUpperCase());
            // },
        },
        {
            title: 'Work Year',
            dataIndex: 'retire_age',
        },
        {
            title: '1st ROR',
            dataIndex: 'ror',
        },
        {
            title: '2nd ROR',
            dataIndex: '2ror',
        },
        {
            title: '3rd ROR',
            dataIndex: '3ror',
        },
        {
            title: 'Inflat',
            dataIndex: 'Inflat',
        },
        {
            title: 'Income',
            dataIndex: 'Income',
        },
        {
            title: 'Target',
            dataIndex: 'Target',
        }
    ]
    return (
        <Table columns={columns} />
    )
}

export default AccumTableDetails