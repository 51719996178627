import React, { useEffect, useState } from 'react'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import Flex from '../../../Components/Flex'
import Button from '../../../Components/Form/Button'
import { Modal } from '../../../Components/Modal'
import { Table } from '../../../Components/Table'
import DeleteAssets from './DeleteAssets'
import { EditAssets } from './EditAssets'
import { Row } from '../../../Components/Row'
import { Col } from 'antd'
import { TopTitle } from '../../../Components/Form/TopTitle'
import Label from '../../../Components/Form/Label'
import Input from '../../../Components/Form/Input'
import { useNavigate, useParams } from 'react-router-dom'
import request from '../../../utils/request'
import BackButton from '../../../Components/Form/BackButton'
import { FaArrowCircleLeft } from 'react-icons/fa'
import { customFormat } from '../../../utils/customFormat'

const ConsumerViewAssets = ({ HandleGetAssets, GetClientName, setGetdata, getdata }) => {
    
    const navigate = useNavigate();
    const {id} = useParams()

    const [dataSource,setDataSource]=useState([])

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [searchText, setSearchText] = useState([]);

    const handleSearch = (value) => {
        setSearchText(value);
    };
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        HandleGetAssets()
        setIsModalOpen(false);
    };


    useEffect(() => {
        GetPeople();
    }, [])

    const GetPeople = () => {
        request.get(`advisor/view_consumer_profile/${id}`)
            .then(function (response) {
                setDataSource(response.data?.asset)

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const columns = [
        {
            title: 'SI No',
            render: (text, value, index) => index + 1,
        },
        {
            title: 'Client Name',
            dataIndex: 'client_name',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.client_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.client_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: 'Account',
            dataIndex: 'account',
        },
        {
            title: 'Risk',
            dataIndex: 'risk',
        },
        {
            title: 'Contribution Amount',
            dataIndex: 'contribution_amt',
            render: (value) =>`$ ${customFormat(value)}` ,
        },
        {
            title: 'Current Balance',
            dataIndex: 'current_bal_amt',
            render: (value) =>`$ ${customFormat(value)}` ,
        },
        
    ]
    
    return (
        <div style={{ marginBottom: '50px' }}>
             <Row>
                <Col span={24} md={6}>
                    <Flex gap={'15px'}>
                        <BackButton text={<FaArrowCircleLeft style={{fontSize:'26px'}}  />} onClick={()=>navigate(`/View/${id}`)} />
                        <TopTitle Heading={'Assets'} />
                    </Flex>
                </Col>
                <Col span={24} sm={12}  md={18}>
                    <Flex end centerVertically  >

                        <Label>Search by Client Name : &nbsp;&nbsp;</Label>
                        <Input
                            placeholder="Search by Client Name"
                            value={searchText}
                            onSearch={handleSearch}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </Flex>
                </Col>
            </Row>
            <Table columns={columns} data={dataSource} />
            {/* <Button.Success text={'Back'} onClick={()=>navigate(`/View/${id}`)}  /> */}
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={600} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

export default ConsumerViewAssets