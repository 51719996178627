import { Col } from 'antd';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { AiOutlineDelete, AiOutlineEye } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom'
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button'
import EditButton from '../../../Components/Form/EditButton';
import Input from '../../../Components/Form/Input';
import Label from '../../../Components/Form/Label';
import { TopTitle } from '../../../Components/Form/TopTitle';
import { Row } from '../../../Components/Row';
import { TableIconHolder } from '../../../Components/Styled';
import { Table } from '../../../Components/Table';
import { THEME } from '../../../theme';
import request from '../../../utils/request';

const Adminrole = () => {
    const navigate = useNavigate();
    const [adminView, setAdminView] = useState([])
    const [searchText, setSearchText] = useState([]);

    //====================Search ========================//

    const handleSearch = (value) => {
        setSearchText(value);
    };
//===========================
    const columns = [
        {
            title: 'S.No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.name).includes(value.toUpperCase());
            },

        },
        {
            title: 'Email',
            dataIndex: 'email',

        },
        {
            title: 'Action',
            render: (record) => {

                return (
                    <>
                        <Flex gap={'15px'} center>
                            <EditButton text={<AiOutlineEye />}  onClick={() => navigate(`/View/${record?.id}`)} />
                            {/* <Button.Danger text={<AiOutlineDelete />} /> */}
                        </Flex>
                    </>
                )
            }

        },
    ]

    //========================Advisor Get  ========================//

    useEffect(() => {
        GetAdvisorView()
    }, [])

    const GetAdvisorView = () => {
        request.get('advisor/view_consumer/')
            .then(function (response) {
                setAdminView(response.data)
            })
            .catch(function (error) {
                console.error(error)
            })
    }

    return (
        <div>
            <Row>
                <Col span={24} md={12}>
                    <TopTitle Heading={'Consumer List'} />
                </Col>
                <Col span={24} md={12}>
                    <Flex end centerVertically>
                        <Label>Search by Name : &nbsp;&nbsp;</Label>
                        <Input
                            placeholder="Search by Name"
                            value={searchText}
                            onSearch={handleSearch}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </Flex>
                </Col>
            </Row>
            <Table columns={columns} data={adminView} />
        </div>
    )
}

export default Adminrole