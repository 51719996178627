import React from 'react'
import { AddAssets } from './Partials/AddAssets'

import { CustomCard } from '../../Components/CustomCard'
import { TopTitle } from '../../Components/Form/TopTitle'
import AssetsViewtable from './Partials/AssetsViewtable'
import request from '../../utils/request'
import { useEffect } from 'react'
import { useState } from 'react'
import BackButton from '../../Components/Form/BackButton'
import { FaArrowCircleLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import Flex from '../../Components/Flex'

const AddAssetsMain = () => {

    const navigate = useNavigate();
    const [dataSource, setDataSource] = useState([])

    const URLS = 'assets/add_assets'

    const HandleGetAssets = () => {
        request.get(`${URLS}`)
            .then(function (response) {
                setDataSource(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        HandleGetAssets();
    }, [])

    return (
        <div>
            <Flex gap={'15px'}>
                <BackButton text={<FaArrowCircleLeft style={{ fontSize: '26px' }} />} onClick={() => navigate(`/`)} />
                <TopTitle Heading={'Add Assets'} />
            </Flex>
            <CustomCard>
                <AddAssets HandleGetAssets={HandleGetAssets} dataSource={dataSource} />
            </CustomCard>

        </div>
    )
}

export default AddAssetsMain