import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import Flex from '../../../Components/Flex'
import { Modal } from '../../../Components/Modal'
import { Table } from '../../../Components/Table'
import request from '../../../utils/request'
import TableDelet from './Deletable'
import EditIncomePage from './EditIncome'
import { Row } from '../../../Components/Row'
import { TopTitle } from '../../../Components/Form/TopTitle'
import Label from '../../../Components/Form/Label'
import Input from '../../../Components/Form/Input'
import { Col } from 'antd'
import { useNavigate } from 'react-router-dom'
import EditButton from '../../../Components/Form/EditButton'
import DeleteButton from '../../../Components/Form/DeleteButton'

const ViewIncome = ({ GetIncomes ,incomeData}) => {

    const URL = 'income/add_my_income/'

    // const [incomeData, setIncomeData] = useState([])
    const [getdata, setGetdata] = useState([])
    const [selectedClient, setSelectedClient] = useState({});
    const navigate = useNavigate();

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchText, setSearchText] = useState([]);

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        GetIncomes()
        setIsModalOpen(false);
    };

    const EditIncomeTable = (record) => {
        setModalTitle("Update Income");
        setModalContent(<EditIncomePage setGetdata={setGetdata} getdata={getdata} datas={record} handleOk={handleOk} GetIncomes={GetIncomes} GetClientName={GetClientName} />);
        showModal();
    }

    const DeleteLiabilities = (record) => {
        setModalTitle("");
        setModalContent(<TableDelet GetIncomes={GetIncomes} handleOk={handleOk} datas={record} />);
        showModal();
    }

    //=======================Client Name=================================//

    useEffect(() => {
        GetClientName();
    }, [])

    const GetClientName = () => {
        request.get(`${URL}`)
            .then(function (response) {
                setGetdata(response.data)
                setSelectedClient(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    //======================View income get================================//

    function customFormat(amount) {
        const parts = amount.toString().split('.');
        parts[0] = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace(/(\d{2})(?=\d{2,})/, '$1,').replace(/(\d{2})(?=\d{2,})/, '$1,');
        return parts.join('.');
    }

    const columns = [
        {
            title: 'SI No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Client Name',
            dataIndex: 'client_name',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.client_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.client_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: ' Description',
            dataIndex: 'description',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (value) =>`$ ${customFormat(value)}` ,
        },
        {
            title: 'Action',
            render: (record) => {
                return (

                    <Flex gap={'15px'} center  >
                        <EditButton onClick={() => { EditIncomeTable(record); }} text={<AiOutlineEdit style={{ fontSize: '20px' }} />} />
                        <DeleteButton onClick={() => { DeleteLiabilities(record) }} text={<AiOutlineDelete style={{ fontSize: '20px' }} />} />
                    </Flex>
                )
            }

        },
    ]
    useEffect(() => {
        GetIncomes();
    }, [])

    return (
        <div>
            <Row>
                <Col span={24} md={12}>
                    <TopTitle Heading={'Household Income'} />
                </Col>
                <Col span={24} md={12}>
                    <Flex end centerVertically>
                        <Label>Search by Client Name : &nbsp;&nbsp;</Label>
                        <Input
                            placeholder="Search by Client Name"
                            value={searchText}
                            onSearch={handleSearch}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </Flex>
                </Col>
            </Row>
            <Table columns={columns} data={incomeData} />
            {/* <Button.Success text={'Back'} onClick={() => navigate('/')} /> */}
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={500} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

export default ViewIncome