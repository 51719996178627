import { UPDATE_TABLE } from "./action"


const InitialState = { 
    TableData : [],
}

export default (state= InitialState,action) => {
    switch (action.type) {
        case UPDATE_TABLE :
            return {...state,TableData:[...state.TableData,action.data]}         
        default:
            return state
    }
}