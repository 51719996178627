import request from "../../utils/request"

export const SET_PLANNING = 'SET_PLANNING'
export const DELETE_COMPANY_PROFILE = 'DELETE_COMPANY_PROFILE'

export const setPlanningRules = value => {
    return {
        type: SET_PLANNING,
        payload: value,
    }
}
export const deleteCompanyProfile = () => {
  return {
      type: DELETE_COMPANY_PROFILE,
  }
}

export const RejinPalnningDeatails = data => async dispatch => {
    try {
      const responseData = await request.get(`e_app/planning_details`, {
        ...data,
       
      })
      dispatch(setPlanningRules(responseData.data))
         
    } catch (error) {
      console.log(error)
    }
  }

  
  
