import React, { useEffect, useState } from 'react'
import { Table } from '../../../Components/Table'

const ToalAssets = ({currentData}) => {

    const [tableData,setTableData] = useState([])

    useEffect(() => {
        setTableData(currentData)
    }, [currentData])

    const columns=[
        {
            title:'SI No',
            render:(item,value,index)=>index+1,
        },
        {
            title:'Year',
            dataIndex:'year',
        },
        {
            title:'Desired Age',
            dataIndex:'retire_age',
        },
        {
            title:'Income goal',
            dataIndex:'income_goal',
        },
        {
            title:'Retirement Income',
            dataIndex:'retire_ment_income',
        },
        {
            title:'Income Gap',
            dataIndex:'income_gap',
        },
        // {
        //     title:'ePaln Pension',
        //     dataIndex:'ePaln_pension',
        // },
        {
            title:'Soc 2',
            dataIndex:'social2',
        },
        {
            title:'Soc 1',
            dataIndex:'social1',
        },
        {
            title:'Pension 1',
            dataIndex:'penson1',
        },
        {
            title:'Pension 2',
            dataIndex:'penson2',
        },
        {
            title:'Investable Income',
            dataIndex:'ins_income',
        },
        {
            title:'Balance Down',
            dataIndex:'b_d',
        },
        {
            title:'Balance Up',
            dataIndex:'b_up',
        },
        {
            title:'Growth',
            dataIndex:'growth',
        },
        {
            title:'Annual Contribution',
            dataIndex:'annual_contiribution',
        },
        {
            title:'Stage',
            dataIndex:'stage',
        },
    ]
  return (
    <div>
        <Table columns={columns} data={tableData} />
    </div>
  )
}

export default ToalAssets