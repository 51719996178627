import React from 'react'
import { FaArrowCircleLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { CustomCard } from '../../../Components/CustomCard'
import Flex from '../../../Components/Flex'
import BackButton from '../../../Components/Form/BackButton'
import { TopTitle } from '../../../Components/Form/TopTitle'

import { ClientDetails } from './Partials/Addperson'


const EPlanForm = ({setCompanyProfile}) => {
    const navigate = useNavigate();
    return (
        <div>
       <Flex gap={'15px'}>
          <BackButton text={<FaArrowCircleLeft style={{ fontSize: '26px' }} />} onClick={()=>navigate(`/`)} />
          <TopTitle Heading={'My Profile'} />
        </Flex>
            <CustomCard>
                <ClientDetails setCompanyProfile={setCompanyProfile}/>
            </CustomCard>
        </div>
    )
}

export default EPlanForm