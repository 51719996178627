import React, { useEffect, useState } from 'react'
import { Col } from 'antd'
import { Row } from '../../../Components/Row'
import { Card, Cards } from './style'

const NetworthTotalCard = ({datas}) => {


    const NetworthData = [
        {
            key: '1',
            card: 'Assets',
            networth: '',
        },
        {
            key: '2',
            card: 'Liabilities',
            networth: '₹',
        },
        {
            key: '3',
            card: 'Networth',
            networth: '₹',
        },

    ]
    const [dashData, setDashData] = useState(NetworthData)

    useEffect(() => {

        setDashData((prevData) =>
            prevData.map((item) => {
                switch (item.card) {
                    case 'Assets':
                        return {
                            ...item,
                            networth: datas?.total_asset || 0,
                        };
                    case 'Liabilities':
                        return {
                            ...item,
                            networth: datas?.total_liabilites || 0,
                        };
                    case 'Networth':
                        return {
                            ...item,
                            networth: datas?.mynetworth || 0,
                        };

                    default:
                        return item;
                }
            })
        );
       
    }, [datas])

    return (
        <div>
             <Row gutter={[24, 24]}>
                {
                    dashData.map((item, i, card,networth) => (
                        <Col span={24} md={8} key={i}>
                            <Cards>
                                <h5>{item.card}</h5>
                                <h4>{item.networth}</h4>
                            </Cards>
                        </Col>
                    ))
                }
            </Row>
        </div>
    )
}

export default NetworthTotalCard