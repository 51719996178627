import React from 'react'
import PlangPieChart from './PieChart'
import PlanningBarChart from './BarChart'
import PlanngForm from './FormDetails.js'
import { useEffect } from 'react'
import { useState } from 'react'
import request from '../../../utils/request'
import TableDetail from './BarChart/TableDetails'

const PlanningViewDetails = () => {

  const [currentData, setCurrentData] = useState([])
  const [graphtData, setGraphtData] = useState([])

  const GETURL = 'my_plan/e_planning/'

  const GetPlanDetails = () => {
    request.get(`${GETURL}`)
      .then((response) => {
        setCurrentData(response.data?.Table)
        setGraphtData(response.data?.Graph)
      })
      .catch(error => {
        console.log(error);
      })
  }

  useEffect(() => {
    GetPlanDetails()
  }, [])

  return (
    <div>
      <PlangPieChart currentData={currentData} graphtData={graphtData}/><br /><br />

      <PlanngForm GetPlanDetails={GetPlanDetails} /><br />
      {/* <PlanningBarChart currentData={currentData} graphtData={graphtData} /> */}
      <TableDetail currentData={currentData} />
    </div>
  )
}

export default PlanningViewDetails