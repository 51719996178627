import React from 'react';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const Currentchart = ({ graphData }) => {

  const data = {
    labels: ['Wall street', 'Bank', 'Protected'],
    datasets: [
      {
        label: 'Percentage',
        data: [graphData?.wall_percent, graphData?.bank_percent, graphData?.procted_percent],
        backgroundColor: [
          'red',
          'yellow',
          'green'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right'
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            const label = context.label;
            const value = context.parsed;
            const percentage =  " % ";
            const segmentData = context.dataset.data[context.dataIndex]; // Get the data value for the segment

            return `${label}:${segmentData}${percentage}`;
          },
          title: function (context) {
            const label = context[0].label; // Assumes all segments have the same title
            return label;
          },
          afterLabel: function (context) {
            let data = ''
            if (context.label === 'Wall street') {
              return "If you can lose money....then it goes in the red column.Include Stocks,IRAs/401(k)s.403(b)s";
            } else if (context.label === 'Bank') {
              return "Safe and liquid: typically used for bills and short term needs.";
            } else if (context.label === 'Protected') {
              return "This is money that must be protected so you can guarantee your income in retirement..";
            }
            return ""; // Default tooltip content
          },
        },
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        titleColor: '#000',
        bodyColor: '#000',
        titleFont: {
          weight: '600',
        },
        labels: {
          title: {
            font: {
              weight: "600",
              size: '16px'
            },
          },
        },
      },
      datalabels: {
        color: 'white',
        font: {
          weight: "600",
          size: '16px'
        },
        
        formatter: function (value, context) {
          console.log('Datalabels - Label:', context.label);
          const segmentData = context.dataset.data[context.dataIndex]; // Get the data value for the segment

          const percentage = ".00% ";
          console.log('Datalabels - Percentage:', percentage);
          return `${segmentData}${percentage}`;
        },
      },
      
      

      percentage:{
        color: 'white', 
      }
    },
    onHover: function (e) {
      e.native.target.style.cursor = "pointer";
    },
    rotation: 270,
    events: ["click"],
  };
  

  return (
    <div>
      <Pie data={data} options={options} style={{ width: '900px', height: '500px' }} />
    </div>
  );
}

export default Currentchart;
