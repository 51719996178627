import React, { useState } from 'react'
import { Col, Form } from 'antd';
import { Row } from '../../../Components/Row';
import Input from '../../../Components/Form/Input';
import { CustomInputNumber } from '../../../Components/Form/CustomInputNumber';
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';
import { TopTitle } from '../../../Components/Form/TopTitle';
import { useEffect } from 'react';
import request from '../../../utils/request';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';


const EditForm = ({ handlerecords, handleEdit,trigger }) => {

    const [form] = Form.useForm();
    const { id } = useParams();
    
    const [eplanData, setEplanData] = useState({})

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFinish = (values) => {

        const record = { ...values };
        let result = {
            people: record.people,
            person_name: record.person_name,
            person_age: record.person_age,
            person_desire_age: record.person_desire_age,
            person_social: record.person_social,
            person_penson: record.person_penson,
            person_cola_percent: record.person_cola_percent,
            person_inflation_percent: record.person_inflation_percent,
            person_contribution: record.person_contribution,
            person_ror_percent: record.person_ror_percent,
            person_draw_per: record.person_draw_per,
            person_emp_match: record.person_emp_match,
            person_investable_assets: record.person_investable_assets,

            e_plan_pension: record.e_plan_pension,
            partner_name: record.partner_name,
            partner_age: record.partner_age,
            partner_desire_age: record.partner_desire_age,
            partner_social: record.partner_social,
            partner_penson: record.partner_penson,
            partner_cola_percent: record.partner_cola_percent,
            partner_inflation_percent: record.partner_inflation_percent,
            partner_contribution: record.partner_contribution,
            partner_ror_percent: record.partner_ror_percent,
            partner_draw_per: record.partner_draw_per,
            partner_emp_match: record.partner_emp_match,
            partner_investable_assets: record.partner_investable_assets,

            income_goal: record.income_goal,
            total_household_income: record.total_household_income,

        }

        EditDetails(result, values.id)
    };

    // =============== Edit URLS =============== 

    const EditDetails = (values, id) => {
        request.put(`e_plan/profile_edit_planning_details_eplan/${id}/`, values)
            .then((response) => {
                form.resetFields();
                handleEdit();
                toast.info('Updated Details Successfully')
            }).catch(error => {
                toast.error("Failed")
                console.log(error);
            });
    }


    useEffect(() => {    
            form.setFieldsValue({
                partner_name: eplanData.partner_name,
                partner_draw_per: eplanData.partner_draw_per,
                partner_age: eplanData.partner_age,
                partner_desire_age: eplanData.partner_desire_age,
                partner_cola_percent: eplanData.partner_cola_percent,
                partner_emp_match: eplanData.partner_emp_match,
                partner_ror_percent: eplanData.partner_ror_percent,
                partner_contribution: eplanData.partner_contribution,
                partner_inflation_percent: eplanData.partner_inflation_percent,
                partner_penson: eplanData.partner_penson, 
                partner_investable_assets: eplanData.partner_investable_assets,
                partner_social: eplanData.partner_social,
                person_name: eplanData.person_name,
                person_desire_age: eplanData.person_desire_age,
                income_goal: eplanData.income_goal,
                total_investable_assets: eplanData.total_investable_assets,
                person_social: eplanData.person_social,
                person_penson: eplanData.person_penson, 
                person_cola_percent: eplanData.person_cola_percent,
                person_inflation_percent: eplanData.person_inflation_percent,
                person_contribution: eplanData.person_contribution,
                person_ror_percent: eplanData.person_ror_percent,
                person_draw_per: eplanData.person_draw_per,
                person_emp_match: eplanData.person_emp_match,
                person_age: eplanData.person_age,
                total_household_income: eplanData.total_household_income,
                person_investable_assets: eplanData.person_investable_assets,
                id: eplanData.id,
                people: eplanData.people,
                e_plan_pension: eplanData.e_plan_pension
            });

    }, [eplanData]);

    const onReset = () => {
        form.resetFields();
    }

   //=========================================Get request Form ========================================== 
   
    useEffect(() => {
        GetEpalnDetails()
    }, [trigger])


    const GetEpalnDetails = () => {
        request.get(`e_plan/profile_planning_details_eplan/${id}/`)
            .then((response) => {
                setEplanData(response.data?.people)
                console.log(response.data,'fgfdg');
            }).catch(error => {
                console.log(error);
            });
    }

    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">

            <Row gutter={[24, 24]}>
                <Col span={24} md={12}>
                    <TopTitle Heading={'Client Details'} />
                    <Row gutter={[12, 12]}>
                        <Input name={'id'} display={'none'} />
                        <Col span={24} md={12}>
                            <Input label={'Name'} placeholder={'Name'} name={'person_name'} rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Details!',
                                }
                            ]} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Age'} placeholder={'Age'} name={'person_age'} rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Details!',
                                }
                            ]} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Desired Age'} placeholder={'Desired Age'} name={'person_desire_age'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Details!',
                                    }
                                ]} />
                        </Col>

                        <Col span={24} md={12}>
                            <Input
                                label={
                                    <>
                                        Income Goal<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }
                                placeholder={'Income Goal'} name={'income_goal'}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please Enter Details!',
                            //     }
                            // ]} 
                            />
                        </Col>

                        <Col span={24} md={12}>
                            <Input
                                label={
                                    <>
                                        Annual Income<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }
                                placeholder={'Annual Income'} name={'total_household_income'}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please Enter Details!',
                            //     }
                            // ]} 
                            />
                        </Col>

                        <Input name={'people'} display={'none'} />

                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={
                                    <>
                                        SOC 1<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }
                                placeholder={'SOC 1'} name={'person_social'}
                            //  rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please Enter Details!',
                            //     }
                            // ]} 
                            />
                        </Col>

                        <Col span={24} md={12}>
                            <Input
                                label={
                                    <>
                                        Pension 1<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }
                                placeholder={'Pension 1'} name={'person_penson'}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please Enter Details!',
                            //     }
                            // ]} 
                            />
                        </Col>

                        <Col span={24} md={12}>
                            <Input
                                label={
                                    <>
                                        Investable Amount<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }
                                placeholder={'Investable Amount'}
                                name={'person_investable_assets'}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please Enter Details!',
                            //     }
                            // ]} 
                            />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'COLA %'} placeholder={'COLA %'}
                                name={'person_cola_percent'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Details!',
                                    }
                                ]}
                            />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Inflation %'} placeholder={'Inflation'}
                                name={'person_inflation_percent'} rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Details!',
                                    }
                                ]} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Contributions %'} placeholder={'Contributions'}
                                name={'person_contribution'}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please Enter Details!',
                            //     }
                            // ]} 
                            />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'ROR %'} placeholder={'ROR %'} name={'person_ror_percent'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Details!',
                                    }
                                ]} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Draw %'} placeholder={'Draw %'} name={'person_draw_per'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Details!',
                                    }
                                ]} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Emp Match %'} placeholder={'Emp Match'} name={'person_emp_match'}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please Enter Details!',
                            //     }
                            // ]} 
                            />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={
                                    <>
                                        E Pension<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }
                                placeholder={'E Pension'} name={'e_plan_pension'}
                            />
                        </Col>

                    </Row>
                </Col>

                {/* ----------- Partner form ----------- */}

                <Col span={24} md={12}>
                    <TopTitle Heading={'Spouse Details'} />
                    <Row gutter={[12, 12]}>

                        <Col span={24} md={12}>
                            <Input label={'Partner Name'} placeholder={'Partner Name'} name={'partner_name'} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Age'} placeholder={'Age'} name={'partner_age'} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Desired Age'} placeholder={'Desired Age'} name={'partner_desire_age'} />
                        </Col>
                        <Col span={24} md={12}>
                            <Input
                                label={
                                    <>
                                        SOC 2<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }
                                placeholder={'SOC 2'} name={'partner_social'} />
                        </Col>

                        <Col span={24} md={12}>
                            <Input
                                label={
                                    <>
                                        Pension 2<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }
                                placeholder={'Pension 2'} name={'partner_penson'} />
                        </Col>

                        <Col span={24} md={12}>
                            <Input
                                label={
                                    <>
                                        Investable Amount<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }
                                placeholder={'Investable Amount'}
                                name={'partner_investable_assets'} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'COLA %'} placeholder={'COLA %'} name={'partner_cola_percent'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <Input label={'Inflation %'} placeholder={'Inflation'} name={'partner_inflation_percent'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <Input label={'Contributions %'} placeholder={'Contributions'} name={'partner_contribution'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'ROR %'} placeholder={'ROR %'} name={'partner_ror_percent'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Draw %'} placeholder={'Draw %'} name={'partner_draw_per'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Emp Match %'} placeholder={'Emp Match'} name={'partner_emp_match'}
                            />
                        </Col>

                    </Row>
                </Col>

            </Row>
            <br />

            <Flex center gap={'20px'} >

                <Button.Primary text={'SAVE'} htmlType={'submit'} />
                <Button.Danger text={'CANCEL'} onClick={() => onReset()} />
            </Flex>

        </Form>
    )
}

export default EditForm