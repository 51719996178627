import React, { useEffect, useState } from 'react'
import { Col, Form } from 'antd';
import { toast } from 'react-toastify';
import { Row } from '../../../../../Components/Row';
import { TopTitle } from '../../../../../Components/Form/TopTitle';
import Input from '../../../../../Components/Form/Input';
import { CustomInputNumber } from '../../../../../Components/Form/CustomInputNumber';
import Button from '../../../../../Components/Form/Button';
import Flex from '../../../../../Components/Flex';
import request from '../../../../../utils/request';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const EditForm = ({ handleEdit, trigger }) => {

    const [form] = Form.useForm();
    const { id } = useParams()
    
    const [eplanData, setEplanData] = useState({})
    const [eplanData1, setEplanData1] = useState({})

    const URLSPUT = 'e_app/edit_planning_details'
    const URLS = `advisor/view_consumer_profile/${id}`
    const URLS2 = `advisor/view_consumer_profile/${id}`

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFinish = (values) => {

        const record = { ...values };
        let result = {
            people: record.people,
            id:record.id,
            person_name: record.person_name,
            person_age: record.person_age,
            person_desire_age: record.person_desire_age,
            person_social: record.person_social,
            person_penson: record.person_penson,
            person_cola_percent: record.person_cola_percent,
            person_inflation_percent: record.person_inflation_percent,
            person_contribution: record.person_contribution,
            person_ror_percent: record.person_ror_percent,
            person_draw_per: record.person_draw_per,
            person_emp_match: record.person_emp_match,
            person_investable_assets: record.person_investable_assets,
            // person_total_household_income: record.total_household_income,
            e_plan_pension: record.e_plan_pension,
            partner_name: record.partner_name,
            partner_age: record.partner_age,
            partner_desire_age: record.partner_desire_age,
            partner_social: record.partner_social,
            partner_penson: record.partner_penson,
            partner_cola_percent: record.partner_cola_percent,
            partner_inflation_percent: record.partner_inflation_percent,
            partner_contribution: record.partner_contribution,
            partner_ror_percent: record.partner_ror_percent,
            partner_draw_per: record.partner_draw_per,
            partner_emp_match: record.partner_emp_match,
            partner_investable_assets: record.partner_investable_assets,
            e_penson: record.e_penson,
            income_goal: record.income_goal,
            total_household_income: record.total_household_income,
        }

        EditDetails(result, values.id)
    };

    // =============== Edit URLS =============== 

    const EditDetails = (values, id) => {
        request.patch(`${URLSPUT}/${id}`, values)
            .then((response) => {
                form.resetFields();
                handleEdit();
                // GetPlanGraphTable();
                toast.info('Updated Details Successfully')
            }).catch(error => {
                toast.error('Failed')
                console.log(error);
            });
    }
//==============================Get request record form ===============================//

useEffect(() => {
    GetEpalnDetails()
    GetEpalnDetailss1()
}, [trigger])

    const GetEpalnDetails = () => {
        request.get(`${URLS}`)
            .then((response) => {
                setEplanData(response.data?.planing_detail)
            }).catch(error => {
                console.log(error);
            });
    }

    const GetEpalnDetailss1 = () => {
        request.get(`${URLS2}`)
            .then((response) => {
                setEplanData1(response.data?.people_rule)
            }).catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        form.setFieldsValue({ partner_name: eplanData1.partner_name })
        form.setFieldsValue({ partner_age: eplanData1.partner_age })
        form.setFieldsValue({ partner_desire_age: eplanData1.partner_retire_age })
        form.setFieldsValue({ partner_penson: eplanData1.pension_partner_total_income })
        form.setFieldsValue({ partner_investable_assets: eplanData1.total_401k_balance })
        form.setFieldsValue({ partner_social: eplanData1.social_partner_total_income })
        form.setFieldsValue({ person_name: eplanData1.person_name })
        form.setFieldsValue({ person_desire_age: eplanData1.person_retire_age })
        form.setFieldsValue({ income_goal: eplanData1.income_goal })
        form.setFieldsValue({ person_social: eplanData1.social_person_total_income })
        form.setFieldsValue({ person_penson: eplanData1.pension_person_total_income })
        form.setFieldsValue({ person_age: eplanData1.person_age })
        form.setFieldsValue({ person_total_household_income: eplanData1.total_household_income })
        form.setFieldsValue({ person_investable_assets: eplanData1.new_total_invest })

    }, [eplanData1])

    useEffect(() => {
        form.setFieldsValue({ people: eplanData.people })
        form.setFieldsValue({ id: eplanData.id })
        form.setFieldsValue({ partner_draw_per: eplanData.partner_draw_per })
        form.setFieldsValue({ partner_cola_percent: eplanData.partner_cola_percent })
        form.setFieldsValue({ partner_emp_match: eplanData.partner_emp_match })
        form.setFieldsValue({ partner_ror_percent: eplanData.partner_ror_percent })
        form.setFieldsValue({ partner_contribution: eplanData.partner_contribution })
        form.setFieldsValue({ partner_inflation_percent: eplanData.partner_inflation_percent })
        form.setFieldsValue({ person_cola_percent: eplanData.person_cola_percent })
        form.setFieldsValue({ person_inflation_percent: eplanData.person_inflation_percent })
        form.setFieldsValue({ person_contribution: eplanData.person_contribution })
        form.setFieldsValue({ person_ror_percent: eplanData.person_ror_percent })
        form.setFieldsValue({ person_draw_per: eplanData.person_draw_per })
        form.setFieldsValue({ person_emp_match: eplanData.person_emp_match })
        form.setFieldsValue({ e_penson: eplanData.e_penson })

    }, [eplanData])

    const onReset = () => {
        handleEdit();
    }

    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">

            <Row gutter={[24, 24]}>
                <Col span={24} md={12}>
                    <TopTitle Heading={'Client Details'} />
                    <Row gutter={[12, 12]}>
                        <Col span={24} md={12}>
                            <Input label={'Name'} disabled placeholder={'Name'} name={'person_name'}
                             rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Your Name!',
                                }
                            ]} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Age'} disabled placeholder={'Age'} name={'person_age'} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={' Desired Age'} disabled placeholder={'Desired Age'} name={'person_desire_age'} />
                        </Col>

                        <Col span={24} md={12}>
                            <Input 
                             label={
                                <>
                                    Income Goal<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }
                             disabled placeholder={'Income Goal'} name={'income_goal'} />
                        </Col>

                        <Col span={24} md={12}>
                            <Input 
                             label={
                                <>
                                    Annual Income<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }
                            disabled placeholder={'Annual Income'} name={'person_total_household_income'} />
                        </Col>

                        <Col span={24} md={12}>
                            <Input 
                             label={
                                <>
                                   SOC 1<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }
                            
                            disabled placeholder={'SOC 1'} name={'person_social'} />
                        </Col>

                        <Col span={24} md={12}>
                            <Input 
                             label={
                                <>
                                   Pension 1<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }                          
                             disabled placeholder={'Pension 1'}
                                name={'person_penson'} />
                        </Col>

                        <Col span={24} md={12}>
                            <Input 
                             label={
                                <>
                                  Investable Amount<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }                          
                             disabled placeholder={'Investable Amount'}
                                name={'person_investable_assets'} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'COLA %'} placeholder={'COLA %'}
                                name={'person_cola_percent'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Details!',
                                    }
                                ]} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Inflation %'} placeholder={'Inflation'}
                                name={'person_inflation_percent'} 
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Details!',
                                    }
                                ]}/>
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Contributions %'} placeholder={'Contributions'}
                                name={'person_contribution'} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'ROR %'} placeholder={'ROR %'} name={'person_ror_percent'}
                             rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Details!',
                                }
                            ]}/>
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Draw %'} placeholder={'Draw %'} name={'person_draw_per'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Details!',
                                }
                            ]} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Emp Match %'} placeholder={'Emp Match'} name={'person_emp_match'}
                            //  rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please Enter Details!',
                            //     }
                            // ]}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber   
                            label={
                                <>
                                  E-Pension<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }   placeholder={'E-Pension'} name={'e_penson'}
                            />
                        </Col>

                    </Row>
                </Col>

                {/* ----------- Partner form ----------- */}

                <Col span={24} md={12}>
                    <TopTitle Heading={'Spouse Details'} />
                    <Row gutter={[12, 12]}>

                        <Input name={'people'} display={'none'} />
                        <Input name={'id'} display={'none'} />
                        <Col span={24} md={12}>
                            <Input label={'Partner Name'} disabled placeholder={'Partner Name'} name={'partner_name'} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Age'} disabled placeholder={'Age'} name={'partner_age'} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Desired Age'} disabled placeholder={'Desired Age'} name={'partner_desire_age'} />
                        </Col>

                        <Col span={24} md={12}>
                            <Input
                             label={
                                <>
                                 SOC 2<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            } 
                             disabled placeholder={'SOC 2'} name={'partner_social'} />
                        </Col>

                        <Col span={24} md={12}>
                            <Input 
                            label={
                                <>
                                 Pension 2<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }
                             disabled placeholder={'Pension 2'} name={'partner_penson'} />
                        </Col>

                        <Col span={24} md={12}>
                            <Input 
                            label={
                                <>
                                 401K Balance<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }
                             disabled placeholder={'401K Balance'}
                                name={'partner_investable_assets'} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'COLA %'} placeholder={'COLA %'} name={'partner_cola_percent'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Inflation %'} placeholder={'Inflation'} name={'partner_inflation_percent'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Contributions %'} placeholder={'Contributions'} name={'partner_contribution'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'ROR %'} placeholder={'ROR %'} name={'partner_ror_percent'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Draw %'} placeholder={'Draw %'} name={'partner_draw_per'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Emp Match %'} placeholder={'Emp Match'} name={'partner_emp_match'}
                            />
                        </Col>

                    </Row>
                </Col>

            </Row>
            <br />

            <Flex center gap={'20px'} >
                <Button.Primary text={'SAVE'} htmlType={'submit'} />
                <Button.Danger text={'CANCEL'} onClick={() => onReset()} />
            </Flex>

        </Form>
    )
}

export default EditForm