import axios from 'axios'

// axios.defaults.withCredentials = true;

const request = axios.create({
//    baseURL: 'http://127.0.0.1:8000/', // API server url
baseURL: 'https://api.eplanadvisors.com/', // API server url



  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
});

export default request


