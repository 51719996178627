import React, { useEffect } from 'react'
import { FaArrowCircleLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { CustomCard } from '../../../Components/CustomCard'
import Flex from '../../../Components/Flex'
import BackButton from '../../../Components/Form/BackButton'
import { TopTitle } from '../../../Components/Form/TopTitle'
import Planningdetails from './Partials/Planningdetails'

const PlanningDetailsMain = ({ RejinPalnningDeatails,getPlanningRules }) => {
    const navigate = useNavigate()


    return (
        <div>
            <Flex gap={'15px'}>
                <BackButton text={<FaArrowCircleLeft style={{ fontSize: '26px' }} />} onClick={() => navigate('/')} />
                <TopTitle Heading={'Planning Details'} />
            </Flex>
            <CustomCard>
                <Planningdetails RejinPalnningDeatails={RejinPalnningDeatails}  />
            </CustomCard>
        </div>
    )
}

export default PlanningDetailsMain