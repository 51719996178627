import React, { useEffect, useState } from 'react'
import TableDetails from './TableDetails'
import ChartMain from './Chart'
import { TopTitle } from '../../../Components/Form/TopTitle'

const MyEplanBarChart = ({graphtData, currentData}) => {

    return (
        <div>
            <ChartMain graphtData={graphtData} />
        </div>
    )
}

export default MyEplanBarChart