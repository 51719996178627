import { Form } from 'antd';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';
import Input from '../../../Components/Form/Input';
import request from '../../../utils/request';

const DeleteAssets = ({ datass, HandleGetAssets, handleOk }) => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        DeleteTableLiabilities(values, values.id);
        handleOk();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const DeleteTableLiabilities = (record, id) => {
        request
            .delete(`assets/assets_edit/${id}`, record)
            .then(function (response) {
                toast.success('Deleted Successfully');
                HandleGetAssets();
            })
            .catch(function (error) {
                console.log(error);
                toast.error('Failed');
            });
    };

    useEffect(() => {
        form.setFieldsValue({ id: datass?.id });
    }, [datass]);

    const onRest = () =>{
        handleOk();
    }

    return (
        <div>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Input name="id" initialValue={datass?.id} display={'none'}/>
                <Flex center>
                    <h1 style={{ fontSize: '20px', color: '#685e5e' }}>
                        Do you want to delete these Assets?
                    </h1>
                </Flex>
                <Flex center gap="50px" style={{ margin: '50px 0px' }}>
                    <Button.Success text="Yes" htmlType="submit" />
                    <Button.Danger text="No" onClick={onRest}/>
                </Flex>
            </Form>
        </div>
    );
};

export default DeleteAssets;
