import React from 'react'
import { CustomCard } from '../../../Components/CustomCard'
import { TopTitle } from '../../../Components/Form/TopTitle'
import LiabilitiesTables from './ViewLiabilities'

const ViewLiabilities = () => {
    return (
        <div>           
            {/* <CustomCard> */}
                <LiabilitiesTables />
            {/* </CustomCard> */}
        </div>
    )
}

export default ViewLiabilities