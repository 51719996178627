import { Col } from 'antd';
import React, { useEffect, useState } from 'react'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button'
import DeleteButton from '../../../Components/Form/DeleteButton';
import EditButton from '../../../Components/Form/EditButton';
import Input from '../../../Components/Form/Input';
import Label from '../../../Components/Form/Label';
import { TopTitle } from '../../../Components/Form/TopTitle';
import { Modal } from '../../../Components/Modal';
import { Row } from '../../../Components/Row';
import { Table } from '../../../Components/Table';
import request from '../../../utils/request';
import DeleteAdvisor from './DeleteAdvisor';
import EditAdvisor from './EditAdvisor';

const Advisor = () => {

    const [advisorView, setAdvisorView] = useState([])
    const [width, setWidth] = useState(0)
    const [trigger, setTrigger] = useState(0)
    const [searchText, setSearchText] = useState([]);

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    //====================Search ========================//

    const handleSearch = (value) => {
        setSearchText(value);
    };


    useEffect(() => {
        GetAdvisorView()
    }, [])

    const GetAdvisorView = () => {
        request.get('advisor/view_advisor/')
            .then(function (response) {
                setAdvisorView(response.data)
            })
            .catch(function (error) {
                console.error(error)
            })
    }
    const handleCheckReq = () => {
        GetAdvisorView();
        handleOk();
    }

    const EditTableAdvisor = (record) => {
        setTrigger(trigger + 1)
        setWidth(500)
        setModalTitle("Edit Advisor");
        setModalContent(<EditAdvisor handlerecord={record} handleCheckReq={handleCheckReq} trigger={trigger} />)
        showModal();
    }

    const DeleteTableAdvisor = (record) => {
        setWidth(400)
        setModalTitle("");
        setModalContent(<DeleteAdvisor handlerecords={record} handleCheckReq={handleCheckReq} />);
        showModal();
    }

    const columns = [
        {
            title: 'S.No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.name).includes(value.toUpperCase());
            },

        },
        {
            title: 'Email',
            dataIndex: 'email',

        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <>
                        <Flex gap={'15px'} center >
                            <EditButton onClick={() => { EditTableAdvisor(record) }} text={<AiOutlineEdit/>} />
                            <DeleteButton onClick={() => { DeleteTableAdvisor(record) }} text={<AiOutlineDelete />} />
                        </Flex>
                    </>
                )
            }

        },
    ]
    return (
        <div>

            <Row>
                <Col span={24} md={12}>
                    <TopTitle Heading={'Advisor List'} />
                </Col>
                <Col span={24} md={12}>
                    <Flex end centerVertically>
                        <Label>Search by Name : &nbsp;&nbsp;</Label>
                        <Input
                            placeholder="Search by Name"
                            value={searchText}
                            onSearch={handleSearch}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </Flex>
                </Col>
            </Row>
            <Table columns={columns} data={advisorView} />
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={width} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

export default Advisor