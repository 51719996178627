import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row } from '../../../../../Components/Row';
import { Col, Form } from 'antd';
import Input from '../../../../../Components/Form/Input';
import { TopTitle } from '../../../../../Components/Form/TopTitle';
import Flex from '../../../../../Components/Flex';
import Button from '../../../../../Components/Form/Button';
import { Modal } from '../../../../../Components/Modal';
import { CustomInputNumber } from '../../../../../Components/Form/CustomInputNumber';
import request from '../../../../../utils/request';
import { useNavigate, useParams } from 'react-router-dom';
import EditForm from './EditForm';
import { toast } from 'react-toastify';
import { customFormat } from '../../../../../utils/customFormat';

const FormMain = ({ GetPlanDetails }) => {

    const [form] = Form.useForm();
    const { id } = useParams()


    const [eplanData, setEplanData] = useState({})
    const [eplanData1, setEplanData1] = useState({})
    const [trigger,setTrigger] = useState(0)

    const dispatch = useDispatch();

    const URLS = `advisor/view_consumer_profile/${id}`
    const URLS2 = `advisor/view_consumer_profile/${id}`

    const onFinishFailed = (errorInfo) => {
        toast.error('Form is incomplete ,Please fill in the required fields correctly')
        console.log('Failed:', errorInfo);
    };

    const onFinish = (values) => {      
        EditEplan(values)
    };

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleEdit = (record) => {
        GetEpalnDetailss1(record);
        GetEpalnDetails(record)
        GetPlanDetails()
        handleOk()
    }

    const EditEplan = (values) => {
        setTrigger(trigger+1)
        setModalTitle("Edit Details");
        setModalContent(<EditForm handlerecords={values} handleEdit={handleEdit} trigger={trigger} />);
        showModal();
    }

    useEffect(() => {
        GetEpalnDetails()
        GetEpalnDetailss1()
    }, [])

    const GetEpalnDetails = () => {
        request.get(`${URLS}`)
            .then((response) => {
                setEplanData(response.data?.planing_detail)
            }).catch(error => {
                console.log(error);
            });
    }

    const GetEpalnDetailss1 = () => {
        request.get(`${URLS2}`)
            .then((response) => {
                setEplanData1(response.data?.people_rule)
            }).catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        if (eplanData1) {
            const formattedIncomeGoal = eplanData1.income_goal !== undefined ? customFormat(eplanData1.income_goal) : undefined;
            const formattedTotalHouseholdIncome = eplanData1.total_household_income !== undefined ? customFormat(eplanData1.total_household_income) : undefined;
            const formattedNewTotalInvest = eplanData1.new_total_invest !== undefined ? customFormat(eplanData1.new_total_invest) : undefined;
            const formattedPartnerPension = eplanData1.pension_partner_total_income !== undefined ? customFormat(eplanData1.pension_partner_total_income) : undefined;
            const formattedartnerSocial = eplanData1.social_person_total_income !== undefined ? customFormat(eplanData1.social_person_total_income) : undefined;
            const pension1 = eplanData1.pension_person_total_income !== undefined ? customFormat(eplanData1.pension_person_total_income):undefined;
            const sco2 =eplanData1.social_partner_total_income !== undefined ? customFormat(eplanData1.social_partner_total_income) : undefined;
            const total401kbalance =eplanData1.total_401k_balance !== undefined ? customFormat(eplanData1.total_401k_balance) :undefined;
        
            form.setFieldsValue({
                people: eplanData1.people,
                id: eplanData1.id,
                partner_name: eplanData1.partner_name,
                partner_age: eplanData1.partner_age,
                partner_desire_age: eplanData1.partner_retire_age,
                partner_penson: formattedPartnerPension,
                partner_investable_assets: total401kbalance,
                partner_social: sco2,
                person_name: eplanData1.person_name,
                person_desire_age: eplanData1.person_retire_age,
                income_goal: formattedIncomeGoal,
                person_social: formattedartnerSocial,
                person_penson: pension1,
                person_age: eplanData1.person_age,
                person_total_household_income: formattedTotalHouseholdIncome,
                person_investable_assets: formattedNewTotalInvest
            });
        }
    }, [eplanData1, form]);
    

    useEffect(() => {
        const epension =eplanData.e_penson !== undefined ?  customFormat(eplanData.e_penson) :undefined;
        form.setFieldsValue({ partner_draw_per: eplanData.partner_draw_per })
        form.setFieldsValue({ partner_cola_percent: eplanData.partner_cola_percent })
        form.setFieldsValue({ partner_emp_match: eplanData.partner_emp_match })
        form.setFieldsValue({ partner_ror_percent: eplanData.partner_ror_percent })
        form.setFieldsValue({ partner_contribution: eplanData.partner_contribution })
        form.setFieldsValue({ partner_inflation_percent: eplanData.partner_inflation_percent })
        form.setFieldsValue({ person_cola_percent: eplanData.person_cola_percent })
        form.setFieldsValue({ person_inflation_percent: eplanData.person_inflation_percent })
        form.setFieldsValue({ person_contribution: eplanData.person_contribution })
        form.setFieldsValue({ person_ror_percent: eplanData.person_ror_percent })
        form.setFieldsValue({ person_draw_per: eplanData.person_draw_per })
        form.setFieldsValue({ person_emp_match: eplanData.person_emp_match })
        form.setFieldsValue({ e_penson: epension })

    }, [eplanData])


    const onReset = () => {
        form.resetFields();
    }

    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">

            {/* <Row gutter={[24, 24]}> */}
            {/* <Col span={24} md={12}> */}
            <TopTitle Heading={'Client Details'} />
            <Row gutter={[12, 12]}>
                <Col span={24} md={8}>
                    <Input label={'Name'} placeholder={'Name'} name={'person_name'} disabled />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Age'} placeholder={'Age'} name={'person_age'} disabled />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={' Desired Age'} placeholder={'Desired Age'} disabled name={'person_desire_age'} />
                </Col>

                <Col span={24} md={8}>
                    <Input
                        label={
                            <>
                                Income Goal<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                            </>
                        }
                        placeholder={'Income Goal'} disabled name={'income_goal'} />
                </Col>

                <Col span={24} md={8}>
                    <Input
                        label={
                            <>
                                Annual Income<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                            </>
                        }
                        placeholder={'Annual Income'} disabled name={'person_total_household_income'} />
                </Col>

                <Col span={24} md={8}>
                    <Input
                        label={
                            <>
                                SOC 1 <span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                            </>
                        }
                        placeholder={'SOC 1'} disabled name={'person_social'} />
                </Col>

                <Col span={24} md={8}>
                    <Input
                        label={
                            <>
                                Pension 1 <span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                            </>
                        }
                        placeholder={'Pension 1'} disabled
                        name={'person_penson'} />
                </Col>

                <Col span={24} md={8}>
                    <Input
                        label={
                            <>
                                Investable Amount<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                            </>
                        }
                        disabled placeholder={'Investable Amount'}
                        name={'person_investable_assets'} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'COLA %'} disabled placeholder={'COLA %'}
                        name={'person_cola_percent'} 
                        rules={[
                            {
                                required: true,
                                message: 'This is required fields!',
                            }
                        ]}
                        />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Inflation %'} disabled placeholder={'Inflation'}
                        name={'person_inflation_percent'} 
                        rules={[
                            {
                                required: true,
                                message: 'This is required fields!',
                            }
                        ]}
                        />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Contributions %'} disabled placeholder={'Contributions'}
                        name={'person_contribution'} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'ROR %'} disabled placeholder={'ROR %'} name={'person_ror_percent'}
                     rules={[
                        {
                            required: true,
                            message: 'This is required fields!',
                        }
                    ]}
                    />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Draw %'} disabled placeholder={'Draw %'} name={'person_draw_per'}
                     rules={[
                        {
                            required: true,
                            message: 'This is required fields!',
                        }
                    ]}
                    />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Emp Match %'} disabled placeholder={'Emp Match'} name={'person_emp_match'}
                    />
                </Col>
                <Col span={24} md={8}>
                    <CustomInputNumber label={'E-Pension ($)'} disabled placeholder={'E-Pension'} name={'e_penson'}
                    />
                </Col>

            </Row>
            {/* </Col> */}



            {/* ----------- Partner form ----------- */}

            {/* <Col span={24} md={12}> */}<br />
            <TopTitle Heading={'Spouse Details'} />
            <Row gutter={[12, 12]}>
                <Input name={'people'} display={'none'} />
                <Input name={'id'} display={'none'} />
                <Col span={24} md={8}>
                    <Input label={'Partner Name'} disabled placeholder={'Partner Name'} name={'partner_name'} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Age'} disabled placeholder={'Age'} name={'partner_age'} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Desired Age'} disabled placeholder={'Desired Age'} name={'partner_desire_age'} />
                </Col>

                <Col span={24} md={8}>
                    <Input
                        label={
                            <>
                                SOC 2<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                            </>
                        }
                        disabled placeholder={'SOC 2'} name={'partner_social'} />
                </Col>

                <Col span={24} md={8}>
                    <Input
                        label={
                            <>
                                Pension 2<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                            </>
                        }
                        disabled placeholder={'Pension 2'} name={'partner_penson'} />
                </Col>

                <Col span={24} md={8}>
                    <Input
                        label={
                            <>
                                401K Balance<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                            </>
                        }
                        disabled placeholder={'401K Balance'}
                        name={'partner_investable_assets'} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'COLA %'} disabled placeholder={'COLA %'} name={'partner_cola_percent'}
                    />
                </Col>
                <Col span={24} md={8}>
                    <CustomInputNumber label={'Inflation %'} disabled placeholder={'Inflation'} name={'partner_inflation_percent'}
                    />
                </Col>
                <Col span={24} md={8}>
                    <CustomInputNumber label={'Contributions %'} disabled placeholder={'Contributions'} name={'partner_contribution'}
                    />
                </Col>
                <Col span={24} md={8}>
                    <CustomInputNumber label={'ROR %'} disabled placeholder={'ROR %'} name={'partner_ror_percent'}
                    />
                </Col>
                <Col span={24} md={8}>
                    <CustomInputNumber label={'Draw %'} disabled placeholder={'Draw %'} name={'partner_draw_per'}
                    />
                </Col>
                <Col span={24} md={8}>
                    <CustomInputNumber label={'Emp Match %'} disabled placeholder={'Emp Match'} name={'partner_emp_match'}
                    />
                </Col>

            </Row><br />
            <Flex center gap={'20px'} >
                {/* <Button.Success text={'Back'} onClick={() => navigate(`/View/${id}`)} /> */}
                <Button.Primary text={'EDIT DETAILS'} htmlType={'submit'} />
            </Flex>

            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={1000} modalTitle={modalTitle} modalContent={modalContent} />
        </Form>
    )
}

export default FormMain