import React from 'react'
import { CustomCard } from '../../Components/CustomCard'
import { TopTitle } from '../../Components/Form/TopTitle'
import SurviveForm from './Partials/Survive'
import ViewSurvive from './Partials/ViewSurvive'

const suriveDetails = () => {
    return (
        <div>
            <TopTitle Heading={'Survive Details'} />
            <CustomCard>
                <SurviveForm />
            </CustomCard><br/>
            <TopTitle Heading={'View Details'} />
            <ViewSurvive/>
        </div>
    )
}

export default suriveDetails