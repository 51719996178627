import React from 'react'
import PlangPieChart from './PieChart'
import PlanningBarChart from './BarChart'
import PlanngForm from './FormDetails.js'
import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import request from '../../../../utils/request'
import TableDetail from './BarChart/TableDetails'


const ConsumerPlanningViewDetails = () => {
  
  const { id } = useParams()
  const [currentData, setCurrentData] = useState([])
  const [graphtData, setGraphtData] = useState([])

  useEffect(() => {
    GetPlanDetails()
  }, [])

  const GetPlanDetails = () => {
    request.get(`advisor/view_consumer_profile/${id}`)
      .then((response) => {
        setCurrentData(response.data?.eplan_graph?.Table)
        setGraphtData(response.data?.eplan_graph?.Graph)
      })
      .catch(error => {
        console.log(error);
      })
  }

  return (
    <div>
      <PlangPieChart currentData={currentData} graphtData={graphtData}/><br /><br />
      <PlanngForm GetPlanDetails={GetPlanDetails} /><br />
      <TableDetail currentData={currentData} />

      {/* <PlanningBarChart currentData={currentData} graphtData={graphtData} /> */}
    </div>
  )
}

export default ConsumerPlanningViewDetails