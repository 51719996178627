import { Col } from 'antd'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { FaArrowCircleLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CustomCard } from '../../Components/CustomCard'
import Flex from '../../Components/Flex'
import BackButton from '../../Components/Form/BackButton'
import { TopTitle } from '../../Components/Form/TopTitle'
import { Row } from '../../Components/Row'
import request from '../../utils/request'
import EditTypes from './Partials/EditTypes'
import LiabilitiesFom from './Partials/LiabilitiesForm'


const MyLiabilities = () => {

    const [tableData, setTableData] = useState([])
    const [dataSource, setDataSource] = useState([]);

    const navigate = useNavigate()

    const URLS = 'liabilites/add_liabilities/'


    const HandleGetAssets = () => {
        request.get(`${URLS}`)
            .then(function (response) {
                setTableData(response.data)

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        HandleGetAssets();
    }, [])


    //========================Table View type ================================//
    
    const GetTabelTypes = () => {
        request.get('liabilites/add_type/')
            .then(function (response) {
                setDataSource(response.data)
            })
            .catch(function (error) {
                console.error(error)
            })
    }
    useEffect(() => {
        GetTabelTypes();
    }, [])
    
    return (
        <div>
             <Flex gap={'15px'}>
                <BackButton text={<FaArrowCircleLeft style={{ fontSize: '26px' }} />} onClick={() => navigate(`/`)} />
                <TopTitle Heading={'Liabilities'} />
            </Flex>
            <CustomCard>
                <LiabilitiesFom GetTabelTypes={GetTabelTypes} HandleGetAssets={HandleGetAssets} dataSource={dataSource} />       
            </CustomCard>
        </div>
    )
}

export default MyLiabilities