import React from 'react'
import { Table } from '../../../Components/Table'

const RetireTable = () => {

    const columns = [
        {
            title: 'SI No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Years',
            dataIndex: 'year',
        },
        {
            title: 'Age',
            dataIndex: 'age',
            // filteredValue: searchText ? [searchText] : null,
            // onFilter: (value, record) => {
            //     return String(record.age).toLowerCase().includes(value.toLowerCase()) ||
            //         String(record.age).includes(value.toUpperCase());
            // },
        },
        {
            title: 'Required Income',
            dataIndex: 'retired_income',
        },
        {
            title: 'Social Security',
            dataIndex: 'ror',
        },
        {
            title: "Fred's",
            dataIndex: 'ror',
            children: [
                {
                    title: 'Social Security',
                    dataIndex: 'Net',
                }
            ]
        },
        {
            title: 'Pension Income',
            dataIndex: '2ror',
        },
        {
            title: "Fred's",
            dataIndex: '2ror',
            children: [
                {
                    title: 'Pension Income',
                    dataIndex: '2ror',
                }
            ]
        },
        {
            title: 'Still Working',
            dataIndex: '3ror',
        },
        {
            title: 'Income Surplus',
            dataIndex: 'Surplus',
        },
        {
            title: 'GIFT',
            dataIndex: 'GIFT',
        },

        {
            title: 'Return',
            dataIndex: 'Return',
            children: [
                {
                    title: 'Gross',
                    dataIndex: 'Gross',
                    children: [
                        {
                            title: 'Net',
                            dataIndex: 'Net',
                        }
                    ]
                }

            ],

        },
        {
            title: 'Return',
            dataIndex: 'Return',
            children: [
                {
                    title: 'Gross',
                    dataIndex: 'Gross',
                    children: [
                        {
                            title: 'Net',
                            dataIndex: 'Net',
                        }
                    ]
                },
                {
                    title: 'Gross',
                    dataIndex: 'Gross',
                    children: [
                        {
                            title: 'Net',
                            dataIndex: 'Net',
                        }
                    ]
                },
                {
                    title: 'Gross',
                    dataIndex: 'Gross',
                    children: [
                        {
                            title: 'Net',
                            dataIndex: 'Net',
                        }
                    ]
                }, {
                    title: 'Gross',
                    dataIndex: 'Gross',
                    children: [
                        {
                            title: 'Net',
                            dataIndex: 'Net',
                        }
                    ]
                },
                {
                    title: 'Gross',
                    dataIndex: 'Gross',
                    children: [
                        {
                            title: 'Others',
                            dataIndex: 'Net',
                        }
                    ]
                }

            ],

        }, {
            title: 'Used Unified Credit',
            dataIndex: 'Credit',
        }
    ]

    return (
        <Table columns={columns} />
    )
}

export default RetireTable