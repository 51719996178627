import { Col, Form } from 'antd';
import React from 'react'
import { CustomInputNumber } from '../../../../Components/Form/CustomInputNumber';
import Input from '../../../../Components/Form/Input';
import { Row } from '../../../../Components/Row';

const PartnerDetails = () => {
    return (
        <div>
            <Row gutter={[24, 24]}>
                <Col span={24} md={8}>
                    <Input label={'Partner Name'} placeholder={'Partner Name'} name={'partner_name'}
                        disabled
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please Enter Your Family Name!',
                    //     }
                    // ]} 
                    />
                </Col>
                <Col span={24} md={8}>
                    <CustomInputNumber label={'Age'} placeholder={'Age'} name={'partner_age'} disabled />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Desired Age'} placeholder={'Desired Age'} name={'partner_desire_age'} disabled />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber
                     label={
                        <>
                            SOC 2<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                        </>
                    } 
                     placeholder={'SOC 2'} name={'social_partner_total_income'} disabled />
                </Col>
                <Col span={24} md={8}>
                    <CustomInputNumber
                        label={
                            <>
                                Pension 2<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                            </>
                        }
                        placeholder={'Pension 2'} name={'pension_partner_total_income'} disabled />
                </Col>
                <Col span={24} md={8}>
                    <CustomInputNumber
                        label={
                            <>
                                401K Balance<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                            </>
                        }
                        placeholder={'401K Balance'}
                        name={'total_401k_balance'} disabled />
                </Col>
                <Col span={24} md={8}>
                    <CustomInputNumber
                        label={'COLA %'}

                        placeholder={'COLA %'} name={'partner_cola_percent'}
                    />
                </Col>
                <Col span={24} md={8}>
                    <CustomInputNumber label={'Inflation %'} placeholder={'Inflation'} name={'partner_inflation_percent'}
                    />
                </Col>
                <Col span={24} md={8}>
                    <CustomInputNumber label={'Contributions %'} placeholder={'Contributions'} name={'partner_contribution'}
                    />
                </Col>
                <Col span={24} md={8}>
                    <CustomInputNumber label={'ROR %'} placeholder={'ROR %'} name={'partner_ror_percent'}
                    />
                </Col>
                <Col span={24} md={8}>
                    <CustomInputNumber label={'Draw %'} placeholder={'Draw %'} name={'partner_draw_per'}
                    />
                </Col>
                <Col span={24} md={8}>
                    <CustomInputNumber label={'Emp Match %'} placeholder={'Emp Match'} name={'partner_emp_match'}
                    />
                </Col>

            </Row><br />


        </div>
    )
}

export default PartnerDetails