import { Form } from 'antd';
import React, { useEffect } from 'react'
import Button from '../../../Components/Form/Button';
import Flex from '../../../Components/Flex';
import Input from '../../../Components/Form/Input';
import { toast } from 'react-toastify';
import request from '../../../utils/request';
import { useState } from 'react';

const TableDelet = ({ GetTabelTypes, datas, handleOk, GetTypesData, HandleGetAssets,setInitialChange }) => {

    const [form] = Form.useForm();

    const DEL = 'liabilites/edit_type'

    const onFinish = (values) => {
        DeleteTableLiabilities(values, values.id)
        handleOk()

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onRest = () => {
        handleOk();
    }

    useEffect(() => {
        form.setFieldsValue({ datas })
    }, [datas])


    useEffect(() => {
        form.setFieldsValue({ id: datas.id })
    }, [datas])

    const DeleteTableLiabilities = (values, id) => {

        request.delete(`${DEL}/${id}/`, values)
            .then(function (response) {
                setInitialChange(response.data)
                GetTabelTypes();
                GetTypesData();
                HandleGetAssets();
                if (response.status === 226) {
                    toast.warn('Type Already exists');
                }
                else {
                    toast.success('Deleted Successfully')
                }
            })
            .catch(function (error) {
                toast.error('Failed')
            });
    }
    return (
        <div>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <Input name={'id'} display={'none'} />

                <Flex center>
                    <h1 style={{ fontSize: '20px', color: '#685e5e' }}>Do you Want to delete these Type?</h1>
                </Flex>

                <Flex center gap={'50px'} style={{ margin: '50px 0px' }}>
                    <Button.Success text={'Yes'} htmlType={'submit'} />
                    <Button.Danger text={'No'} onClick={onRest} />
                </Flex>
            </Form>
        </div>
    )
}

export default TableDelet