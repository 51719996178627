import React, { useEffect, useState } from 'react'
import request from '../../../utils/request'
import Currentchart from './Currentchart'
import CurrentAssets from './CurrentAssets'
import OptimalChart from './Optimalchart'
import OptimalAssets from './OptimalAssets'
import { Row } from '../../../Components/Row'
import { Button, Col } from 'antd'
import Flex from '../../../Components/Flex'
import { TopTitle } from '../../../Components/Form/TopTitle'
import { useNavigate, useParams } from 'react-router-dom'
import MyEplanBarChart from '../BarChart'
import BackButton from '../../../Components/Form/BackButton'
import { FaArrowCircleLeft } from 'react-icons/fa'

const MyEplanPieChart = ({ currentData, graphtData }) => {

  // const URL = 'e_app/current_asset_allocation/'

  // const URLS = 'e_app/optimal_asset_allocation/'
  const { id } = useParams();
  const navigate = useNavigate();

  const [currentdata, setCurrentdata] = useState([])
  const [optimalData, setoptimalData] = useState([])
  const [graphData, setGraphData] = useState([])
  const [optimalgraphdata, setOptimalgraphdata] = useState([])

  //========================Current Assets=====================================

  const Getitems = () => {
    request.get(`e_app/profile_current_asset_allocation/${id}`)
      .then(function (response) {
        setCurrentdata(response.data?.Table)
        setGraphData(response.data?.Graph)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    Getitems();
  }, [])
  //========================Optimal Assets=====================================

  const GetOptimal = () => {
    request.get(`e_app/profile_optimal_asset_allocation/${id}`)
      .then(function (response) {
        setoptimalData(response.data?.Table)
        setOptimalgraphdata(response.data?.Graph)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetOptimal();
  }, [])


  return (
    <div>
      <Row gutter={[24, 24]}>

        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
          <Flex gap={'15px'}>
            <BackButton text={<FaArrowCircleLeft style={{ fontSize: '26px' }} />} onClick={() => navigate(`/View/${id}`)} />
            <TopTitle Heading={'Current Asset'} />
          </Flex>

          <Currentchart graphData={graphData} />
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
          <TopTitle Heading={'Optimal Asset'} />

          <OptimalChart optimalgraphdata={optimalgraphdata} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <TopTitle Heading={'Income Gap'} />
          <MyEplanBarChart currentData={currentData} graphtData={graphtData} />
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col sm={24} md={12}>
          <CurrentAssets currentdata={currentdata} />
        </Col>
        <Col sm={24} md={12}>
          <OptimalAssets optimalData={optimalData} />
        </Col>
      </Row>

    </div>
  )
}

export default MyEplanPieChart