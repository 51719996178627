import React, { useEffect, useState } from 'react'
import { Modal } from '../../../../Components/Modal'
import { Table } from '../../../../Components/Table'
import { customFormat } from '../../../../utils/customFormat'
import { Overhid } from './style'

const ViewTotalAssets = ({ dataSource ,HandleGetAssets}) => {

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const columns = [
        {
            title: 'SI No',
            render: (text, value, index) => index + 1,

        },
        {
            title: 'Client Name',
            dataIndex: 'client_name',

        },
        {
            title: 'Type',
            dataIndex: 'type',

        },
        {
            title: 'Account',
            dataIndex: 'account',
        },
        {
            title: 'Contribution Amount',
            dataIndex: 'contribution_amt',
            render: (value) =>`$ ${customFormat(value)}` ,
        },
        {
            title: 'Current Balance',
            dataIndex: 'current_bal_amt',
            render: (value) =>`$ ${customFormat(value)}` ,
        },
        // {
        //     title: 'Action',
        //     render: (record) => {
        //         return (

        //             <Flex spaceEvenly>
        //                 <Button.Success onClick={() => { EditAssetsTable(record); }} text={<AiOutlineEdit style={{ fontSize: '20px' }} />} />
        //                 <Button.Danger  onClick={() => {DeleteAssetsTable(record); }} text={<AiOutlineDelete style={{ fontSize: '20px' }} />} />
        //             </Flex>
        //         )
        //     }

        // },

    ]


    return (
        <div style={{ marginBottom: '50px' }}>
            <Overhid>
                <Table columns={columns} data={dataSource} />
            </Overhid>
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={600} modalTitle={modalTitle} modalContent={modalContent} />


        </div>
    )
}

export default ViewTotalAssets