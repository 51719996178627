import { connect } from 'react-redux'
import Register from '../Components/Register'
import { AdminRegister } from '../actions'
import selector from '../selectors'

const mapStateToProps = state => ({
  supertoken: selector.getSuperToken(state),
})

const mapDispatchToProps = {
    AdminRegister,
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
