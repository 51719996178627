import { Col, Form, Upload as Uploded } from 'antd'
import { Select } from '../../../Components/Form/Select';
import { CustomInputNumber } from '../../../Components/Form/CustomInputNumber';
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';
import { Fragment } from 'react';
import { Row } from '../../../Components/Row';
import { useState } from 'react';
import { useEffect } from 'react';
import request from '../../../utils/request';
import Input from '../../../Components/Form/Input';
import { toast } from 'react-toastify';
import { customFormat } from '../../../utils/customFormat';




export const EditAssets = ({ datas, HandleGetAssets, handleOk }) => {

    const [form] = Form.useForm();
    // const [getdata, setGetdata] = useState([])
    const [selectedClient, setSelectedClient] = useState({});
    const [getdata, setGetdata] = useState([])


    const URL = 'assets/assets_people_values/'
    const URLS = 'assets/add_assets'

    const onFinish = (values) => {
        const record = { ...values };
        let result = {
            current_bal_amt: record.current_bal_amt || 0,
            client_name: record.client_name,
            type: record.type,
            risk: record.risk,
            people: record.people,
            account: record.account,
            contribution_amt: record.contribution_amt || 0,

        }
        AssetsTableEdit(result, datas.id)
        handleOk()

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onReset = (values) => {
        GetClientName();
        HandleGetAssets();
        // form.resetFields();
        handleOk();
    }

    useEffect(() => {
        GetClientName();
    }, [])


    useEffect(() => {
        form.setFieldsValue({ client_name: selectedClient.person_name })
        form.setFieldsValue({ id: selectedClient.people })
    }, [selectedClient])


    const Riskoption = [
        { label: 'Bank', value: 'Bank' },
        { label: 'Protected', value: 'Protected' },
        { label: 'Wall Street', value: 'Wall Street' },
    ];

    const TypeOption = [
        { label: '401K', value: '401K' },
        { label: 'Bonds', value: 'Bonds' },
        { label: 'Checking Account', value: 'Checking Account' },
        { label: 'Real Estate', value: 'Real Estate' },
        { label: 'ROTH', value: 'ROTH' },
        { label: 'Savings Account', value: 'Savings Account' },
        { label: 'Stocks', value: 'Stocks' },

    ]

    const Clientoption = [{ label: getdata?.person_name, value: getdata?.person_name }, { label: getdata?.partner_name, value: getdata?.partner_name }]

    useEffect(() => {
        form.setFieldsValue(datas)
    }, [datas])

    // //========================Dollar ',' Format ==================================//

    //     useEffect(() => {
    //         if (datas?.current_bal_amt !== undefined) {
    //             const formattedIncomeGoal = customFormat(datas.current_bal_amt);
    //             form.setFieldsValue({ current_bal_amt: formattedIncomeGoal.toString() });
    //         }
    //         if (datas?.contribution_amt !== undefined) {
    //             const formattedIncomeGoal = customFormat(datas.contribution_amt);
    //             form.setFieldsValue({ contribution_amt: formattedIncomeGoal.toString() });
    //         }
    //     }, [datas]);


    //=================Client name ======================//

    useEffect(() => {
        GetClientName();
    }, [])

    const GetClientName = () => {

        request.get(`${URL}`)
            .then(function (response) {
                setGetdata(response.data)
                setSelectedClient(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    //=====================Edit Put==============================

    const AssetsTableEdit = (record, id) => {

        request.put(`assets/assets_edit/${id}`, record)
            .then(function (response) {
                toast.info('Successfully Updated Assets!')
                // form.resetFields();
                HandleGetAssets();
                GetClientName();
            })
            .catch(function (error) {
                toast.error('failed')
                console.log(error);
            });
    }

    const handleTypeChange = (value) => {
        if (value === "401K") {
            form.setFieldsValue({ risk: 'Wall Street' })
        } else {
            form.setFieldsValue({ risk: null })
        }
    }


    return (
        <Fragment>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}

                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <Row gutter={[12, 12]}>

                    <Col span={24} md={12}>

                        <Select
                            options={Clientoption}
                            label={'Client Name'}
                            placeholder={'Client Name'}
                            name={'client_name'}
                            disabled
                            rules={[
                                {
                                    required: true,
                                    message: 'This is required field!'
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Select
                            options={TypeOption}
                            label={'Type'}
                            onChange={handleTypeChange}
                            placeholder={'Type'} name={'type'}
                            rules={[
                                {
                                    required: true,
                                    message: 'This is required field!',
                                }
                            ]}

                        />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Account '} placeholder={'Account'} name={'account'}
                            rules={[
                                {
                                    required: true,
                                    message: 'This is required field!',
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Select
                            showSearch
                            options={Riskoption}
                            label={'Risk'}
                            placeholder={'Risk'} name={'risk'}
                            rules={[
                                {
                                    required: true,
                                    message: 'This is required field!',
                                }
                            ]}
                        />
                    </Col>

                    <Col span={24} md={12}>
                        <CustomInputNumber
                            label={
                                <>
                                    Current Balance <span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }
                            placeholder={'Current Balance'} name={'current_bal_amt'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter current balance!',
                                }
                            ]} />
                    </Col>
                    <Col span={24} md={12}>
                        <Input label={'Contribution'} placeholder={'Contribution'} name={'contribution_amt'}
                        />
                    </Col>

                    <Input name={'people'} display={'none'} />
                </Row>

                <Flex center gap={'20px'} style={{ margin: '20px 0px' }}>
                    <Button.Primary text={'SAVE'} htmlType={'submit'} />
                    <Button.Danger text={'CANCEL'} onClick={() => onReset()} />
                </Flex>

            </Form>


        </Fragment>
    )
}