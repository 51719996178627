import styled from "styled-components";


export const BacCard = styled.div`
background-color: var(--purple); 
box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
border-radius:30px;
height: 180px;
color:var(--light-color);
padding: 30px 15px;
transition: all 0.5s;
/* margin:10px 0px; */

& h1{
  text-align:center;
    font-size: 20px;
    padding:20px 0px;
    cursor:pointer;
   


}
&:hover {
    h1 {
      transform: scale(1.5); /* Zoom in effect */
    }
  }
&:active {
    background-color:#174e17;
    & svg{
    
    }
}
& svg{
    font-size: 25px;
    font-weight: 900;
    margin: 20px 0px;
}

`;
export const DashCard = styled.div`
border:solid 2px black;
`;

export const Tablestyledd = styled.div`
 .table .thead {
background: red !important;
color: #fff;
}
`;
export const TopBox = styled.div`
 background: black;  
& h1{
 color:white;
 text-align:center;
}
`;

