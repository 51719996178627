import React from 'react'
import Input from '../../../Components/Form/Input';
import { CustomInputNumber } from '../../../Components/Form/CustomInputNumber';
import { Col, Form } from 'antd';
import { Row } from '../../../Components/Row';
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';

const AccumForm = () => {

    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log(values, 'Success')
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const onReset = () => {
        form.resetFields();
    }

    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">

            <Row gutter={[12, 12]}>
                <Col span={24} md={8}>
                    <CustomInputNumber label={'Current Age'} placeholder={'Current Age'} name={'Current_age'} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Age at Retirement'} placeholder={'Age at Retirement'} name={'Age_retirement'} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Number of Working Years'} placeholder={'Number of Working Years'} name={'Working_years'} />
                </Col>

                <Col span={24} md={8}>
                    <Input label={'Current Assets (Investable Only)'} placeholder={'Current Assets (Investable Only)'} name={'Current_assets'} />
                </Col>

                <Col span={24} md={8}>
                    <Input label={'Annual Contribution'} placeholder={'Annual Contribution'} name={'Annual_contribution'} />
                </Col>

                <Col span={24} md={8}>
                    <Input label={'ROR'} placeholder={'Range of Rates of Return'} name={'Range_Return'} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Annual Income Needs at Ret.'} placeholder={'Annual Income Needs at Ret.'}
                        name={'Annual_Income_Needs'} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Projected Rate of Inflation'} placeholder={'Inflation'}
                        name={'Projected_Inflation'} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Earnings after Retirement'} placeholder={'Earnings after Retirement'}
                        name={'Earnings'} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Age Resources are Exhausted'} placeholder={'Age Resources are Exhausted'}
                        name={'Exhausted'} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Target Funding Goal'} placeholder={'Target Funding Goal'}
                        name={'Target'} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Total Dist. during Retirement'} placeholder={'Total Dist. during Retirement'} name={'during_Retirement'}
                    />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Future Dollars to be Paid In'} placeholder={'Future Dollars'} name={'Future_Dollars'}
                    />
                </Col>

            </Row>
            <br />

            <Flex center gap={'20px'} >
                <Button.Primary text={'SUBMIT'} htmlType={'submit'} />
                <Button.Danger text={'RESET'} onClick={() => onReset()} /> 
            </Flex>
        </Form>
    )
}

export default AccumForm