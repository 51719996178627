import React, { useEffect, useState } from 'react'
import Currentchart from './Currentchart'
import CurrentAssets from './CurrentAssets'
import OptimalChart from './Optimalchart'
import OptimalAssets from './OptimalAssets'
import { Col } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import request from '../../../../../utils/request'
import { Row } from '../../../../../Components/Row'
import { TopTitle } from '../../../../../Components/Form/TopTitle'
import Flex from '../../../../../Components/Flex'
import Button from '../../../../../Components/Form/Button'
import PlanningBarChart from '../BarChart'
import { FaArrowCircleLeft } from 'react-icons/fa'
import BackButton from '../../../../../Components/Form/BackButton'

const PlangPieChart = ({ currentData, graphtData }) => {
  const { id } = useParams()
  const navigate = useNavigate();

  const URL = `advisor/view_consumer_profile/${id}`

  const URLS = `advisor/view_consumer_profile/${id}`

  const [currentdata, setCurrentdata] = useState([])
  const [optimalData, setoptimalData] = useState([])
  const [graphData, setGraphData] = useState([])
  const [optimalgraphdata, setOptimalgraphdata] = useState([])

  //========================Current Assets=====================================
  const Getitems = () => {
    request.get(`${URL}`)
      .then(function (response) {
        setCurrentdata(response.data?.current_asset?.Table)
        setGraphData(response.data?.current_asset?.Graph)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    Getitems();
  }, [])

  //========================Optimal Assets=====================================

  const GetOptimal = () => {
    request.get(`${URLS}`)
      .then(function (response) {
        setoptimalData(response.data?.optimal_asset?.Table)
        setOptimalgraphdata(response.data?.optimal_asset?.Graph)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetOptimal();
  }, [])


  return (
    <div>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
          <Flex gap={'15px'}>
            <BackButton text={<FaArrowCircleLeft style={{ fontSize: '26px' }} />}onClick={()=>navigate(`/View/${id}`)}  />
            <TopTitle Heading={'Current Asset'} />
          </Flex>
          <Currentchart graphData={graphData} />
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
          <TopTitle Heading={'Optimal Asset'} />
          <OptimalChart optimalgraphdata={optimalgraphdata} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <TopTitle Heading={'Income Gap'} />
          <div style={{ margin: '16px 10px' }}>
            <PlanningBarChart currentData={currentData} graphtData={graphtData} />
          </div>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col sm={24} md={12}>
          <CurrentAssets currentdata={currentdata} />
        </Col>
        <Col sm={24} md={12}>
          <OptimalAssets optimalData={optimalData} />
        </Col>
      </Row>
    </div>
  )
}

export default PlangPieChart