import React from 'react'
import { Col, Form } from 'antd';
import { Row } from '../../../Components/Row';
import Input from '../../../Components/Form/Input';
import { CustomInputNumber } from '../../../Components/Form/CustomInputNumber';
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';
import { TopTitle } from '../../../Components/Form/TopTitle';
import { useEffect } from 'react';
import { useState } from 'react';
import request from '../../../utils/request';
import { Modal } from '../../../Components/Modal';
import EditForm from './EditForm';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { customFormat } from '../../../utils/customFormat';


const FormMain = ({ GetPlanDetails }) => {

    const [form] = Form.useForm();

    const [eplanData, setEplanData] = useState({})
    const [trigger,setTrigger] = useState(0)
    const { id } = useParams();

    const URLS = `e_plan/profile_planning_details_eplan/${id}/`

    const onFinishFailed = (errorInfo) => {
        toast.error('Form is incomplete ,Please fill in the required fields correctly')
        console.log('Failed:', errorInfo);
    };

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleEdit = (record) => {
        GetEpalnDetails(record);
        GetPlanDetails()
        handleOk()
    }

    const EditEplan = (values) => {
        setTrigger(trigger+1)
        setModalTitle("Edit Details");
        setModalContent(<EditForm handlerecords={values} handleEdit={handleEdit} trigger={trigger} />);
        showModal();
    }

    const onFinish = (values) => {
        EditEplan(values)
    };

    useEffect(() => {
        GetEpalnDetails()
    }, [])


    const GetEpalnDetails = () => {
        request.get(`${URLS}`)
            .then((response) => {
                setEplanData(response.data?.people)
            }).catch(error => {
                console.log(error);
            });
    }
    useEffect(() => {
        if (eplanData) {
            const pension2 = eplanData.partner_penson !== undefined ? customFormat(eplanData.partner_penson) : undefined;
            const persion1 = eplanData.person_penson !== undefined ? customFormat(eplanData.person_penson) : undefined;
            const formattedIncomeGoal = eplanData.income_goal !== undefined ? customFormat(eplanData.income_goal) : undefined;
            const AnnualIncome = eplanData.total_household_income !== undefined ? customFormat(eplanData.total_household_income):undefined;
            const soc1 =eplanData.person_social !== undefined ? customFormat(eplanData.person_social):undefined;
            const InvestableAmount = eplanData.person_investable_assets !== undefined ? customFormat(eplanData.person_investable_assets):undefined;
            const Epension = eplanData.e_plan_pension !== undefined ? customFormat(eplanData.e_plan_pension) :undefined;
            const Soc2 = eplanData.partner_social !== undefined ? customFormat(eplanData.partner_social) : undefined;
            const fourtoneK = eplanData.partner_investable_assets !== undefined ? customFormat(eplanData.partner_investable_assets):undefined;

            form.setFieldsValue({
                partner_name: eplanData.partner_name,
                partner_draw_per: eplanData.partner_draw_per,
                partner_age: eplanData.partner_age,
                partner_desire_age: eplanData.partner_desire_age,
                partner_cola_percent: eplanData.partner_cola_percent,
                partner_emp_match: eplanData.partner_emp_match,
                partner_ror_percent: eplanData.partner_ror_percent,
                partner_contribution: eplanData.partner_contribution,
                partner_inflation_percent: eplanData.partner_inflation_percent,
                partner_penson: pension2, 
                partner_investable_assets: fourtoneK,
                partner_social: Soc2,
                person_name: eplanData.person_name,
                person_desire_age: eplanData.person_desire_age,
                income_goal: formattedIncomeGoal,
                total_investable_assets: eplanData.total_investable_assets,
                person_social: soc1,
                person_penson: persion1, 
                person_cola_percent: eplanData.person_cola_percent,
                person_inflation_percent: eplanData.person_inflation_percent,
                person_contribution: eplanData.person_contribution,
                person_ror_percent: eplanData.person_ror_percent,
                person_draw_per: eplanData.person_draw_per,
                person_emp_match: eplanData.person_emp_match,
                person_age: eplanData.person_age,
                total_household_income: AnnualIncome,
                person_investable_assets: InvestableAmount,
                id: eplanData.id,
                people: eplanData.people,
                e_plan_pension: Epension
            });
        }
    }, [eplanData, form]);

    // ============== Graph and table data ============

    return (
        <div>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">


                <TopTitle Heading={'Client Details'} />
                <Row gutter={[12, 12]}>
                    <Col span={24} md={8}>
                        <Input label={'Name'} placeholder={'Name'} name={'person_name'} disabled />
                    </Col>

                    <Col span={24} md={8}>
                        <CustomInputNumber label={'Age'} placeholder={'Age'} name={'person_age'} disabled />
                    </Col>

                    <Col span={24} md={8}>
                        <CustomInputNumber label={'Desired Age'} placeholder={'Desired Age'} disabled name={'person_desire_age'} />
                    </Col>

                    <Col span={24} md={8}>
                        <Input
                            label={
                                <>
                                    Income Goal<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }
                            placeholder={'Income Goal'} disabled name={'income_goal'} />
                    </Col>

                    <Col span={24} md={8}>
                        <CustomInputNumber
                            label={
                                <>
                                    Annual Income<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }
                            placeholder={'Annual Income'} disabled name={'total_household_income'} />
                    </Col>

                    <Col span={24} md={8}>
                        <Input
                            label={
                                <>
                                    SOC 1<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }
                            placeholder={'SOC 1'} disabled name={'person_social'} />
                    </Col>

                    <Col span={24} md={8}>
                        <CustomInputNumber
                            label={
                                <>
                                    Pension 1<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }
                            placeholder={'Pension 1'} disabled
                            name={'person_penson'} />
                    </Col>


                    <Col span={24} md={8}>
                        <CustomInputNumber
                            label={
                                <>
                                    Investable Amount<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }
                            disabled placeholder={'Investable Amount'}
                            name={'person_investable_assets'} />
                    </Col>

                    <Col span={24} md={8}>
                        <CustomInputNumber label={'COLA %'} disabled placeholder={'COLA %'}
                            name={'person_cola_percent'}
                            rules={[
                                {
                                    required: true,
                                    message: 'This is required fields!',
                                }
                            ]}
                             />
                    </Col>

                    <Col span={24} md={8}>
                        <CustomInputNumber label={'Inflation %'} disabled placeholder={'Inflation'}
                        rules={[
                            {
                                required: true,
                                message: 'This is required fields!',
                            }
                        ]} 
                            name={'person_inflation_percent'}
                             />
                    </Col>

                    <Col span={24} md={8}>
                        <CustomInputNumber label={'Contributions %'} disabled placeholder={'Contributions'}
                            name={'person_contribution'} />
                    </Col>

                    <Col span={24} md={8}>
                        <CustomInputNumber label={'ROR %'} disabled placeholder={'ROR %'} name={'person_ror_percent'}
                         rules={[
                            {
                                required: true,
                                message: 'This is required fields!',
                            }
                        ]}
                        />
                    </Col>

                    <Col span={24} md={8}>
                        <CustomInputNumber label={'Draw %'} disabled placeholder={'Draw %'} name={'person_draw_per'}
                         rules={[
                            {
                                required: true,
                                message: 'This is required fields!',
                            }
                        ]}
                        />
                    </Col>

                    <Col span={24} md={8}>
                        <CustomInputNumber label={'Emp Match %'} disabled placeholder={'Emp Match'} name={'person_emp_match'}
                        />
                    </Col>

                    <Col span={24} md={8}>
                        <CustomInputNumber
                            label={
                                <>
                                    E Pension<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }
                            disabled placeholder={'E Pension'} name={'e_plan_pension'}
                        />
                    </Col>
                </Row>

                {/* ----------- Partner form ----------- */}

                <br />
                <TopTitle Heading={'Spouse Details'} />
                <Row gutter={[12, 12]}>
                    <Input name={'people'} display={'none'} />
                    <Input name={'id'} display={'none'} />
                    <Col span={24} md={8}>
                        <Input label={'Partner Name'} disabled placeholder={'Partner Name'} name={'partner_name'} />
                    </Col>

                    <Col span={24} md={8}>
                        <CustomInputNumber label={'Age'} disabled placeholder={'Age'} name={'partner_age'} />
                    </Col>

                    <Col span={24} md={8}>
                        <CustomInputNumber label={' Desired Age'} disabled placeholder={'Desired Age'} name={'partner_desire_age'} />
                    </Col>

                    <Col span={24} md={8}>
                        <Input
                            label={
                                <>
                                    SOC 2<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                </>
                            }
                            disabled placeholder={'SOC 2'} name={'partner_social'} />
                    </Col>

                    <Col span={24} md={8}>
                        <Input
                            label={
                                <>
                                    Pension 2<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                </>
                            }
                            disabled placeholder={'Pension 2'} name={'partner_penson'} />
                    </Col>

                    <Col span={24} md={8}>
                        <Input
                            label={
                                <>
                                    401K Balance<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                </>
                            }
                            disabled placeholder={'401K Balance'}
                            name={'partner_investable_assets'} />
                    </Col>

                    <Col span={24} md={8}>
                        <CustomInputNumber label={'COLA %'} disabled placeholder={'COLA %'} name={'partner_cola_percent'}
                        />
                    </Col>
                    <Col span={24} md={8}>
                        <Input label={'Inflation %'} disabled placeholder={'Inflation'} name={'partner_inflation_percent'}
                        />
                    </Col>
                    <Col span={24} md={8}>
                        <Input label={'Contributions %'} disabled placeholder={'Contributions'} name={'partner_contribution'}
                        />
                    </Col>
                    <Col span={24} md={8}>
                        <CustomInputNumber label={'ROR %'} disabled placeholder={'ROR %'} name={'partner_ror_percent'}
                        />
                    </Col>
                    <Col span={24} md={8}>
                        <CustomInputNumber label={'Draw %'} disabled placeholder={'Draw %'} name={'partner_draw_per'}
                        />
                    </Col>
                    <Col span={24} md={8}>
                        <CustomInputNumber label={'Emp Match %'} disabled placeholder={'Emp Match'} name={'partner_emp_match'}
                        />
                    </Col>

                </Row>

                <br />

                <Flex center gap={'20px'} >
                    {/* <Button.Success text={'Back'} onClick={() => navigate(`/View/${id}`)} /> */}

                    <Button.Primary text={'EDIT DETAILS'} htmlType={'submit'} />
                    {/* <Button.Primary text={'Submit'} htmlType={'submit'} />
                <Button.Danger text={'RESET'} onClick={() => onReset()} /> */}
                </Flex>
                <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={1000} modalTitle={modalTitle} modalContent={modalContent} />
                {/* <br />
            <MyEplanPieChart /><br />*/}

            </Form>
            {/* <MyEplanBarChart graphtData={graphtData} currentData={currentData} /> */}
        </div>
    )
}

export default FormMain

