import { Col, Form } from 'antd';
import React from 'react'
import { toast } from 'react-toastify';
import Flex from '../../../../Components/Flex';
import Button from '../../../../Components/Form/Button';
import { CustomInputNumber } from '../../../../Components/Form/CustomInputNumber';
import Input from '../../../../Components/Form/Input';
import { Row } from '../../../../Components/Row';

const Partnerform = () => {

    return (

        <Row gutter={[24, 24]}>
            <Col span={24} md={8}>

                <Input label={'Name'} placeholder={'Name'} name={'partner_name'}
                />
            </Col>

            <Col span={24} md={8}>
                <CustomInputNumber label={'Age'} placeholder={'Age'} name={'partner_age'}
                />
            </Col>
            <Col span={24} md={8}>
                <CustomInputNumber label={' Desired Age'} placeholder={'Desired Age'} name={'partner_desire_age'}

                />
            </Col>
            <Col span={24} md={8}>
                <CustomInputNumber
                    label={
                        <>
                            Goal<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                        </>
                    }

                    placeholder={'Goal'} name={'partner_income_goal'}
                />
            </Col>
            <Col span={24} md={8}>
                <CustomInputNumber
                    label={
                        <>
                            Annual Income<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                        </>
                    }
                    placeholder={'Annual Income'} name={'partner_total_household_income'}
                />
            </Col>
            <Col span={24} md={8}>
                <CustomInputNumber
                    label={
                        <>
                            SOC 2<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                        </>
                    }
                    placeholder={'SOC 2'} name={'partner_social'}
                />
            </Col>
            <Col span={24} md={8}>
                <CustomInputNumber label={'COLA %'} placeholder={'COLA %'} name={'partner_cola_percent'}
                />
            </Col>
            <Col span={24} md={8}>
                <CustomInputNumber
                    label={
                        <>
                            Pension 2<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                        </>
                    }
                    placeholder={'Pension 2'} name={'partner_penson'}
                />
            </Col>
            <Col span={24} md={8}>
                <CustomInputNumber label={'Inflation %'} placeholder={'Inflation'} name={'partner_inflation_percent'}
                />
            </Col>
            <Col span={24} md={8}>
                <CustomInputNumber
                    label={
                        <>
                            401k Balance<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                        </>
                    }
                    placeholder={'401k Balance'} name={'partner_401k_balance'}
                />
            </Col>
            <Col span={24} md={8}>
                <CustomInputNumber label={'Distribution %'} placeholder={'Distribution'} name={'partner_contribution'}
                />
            </Col>
            <Col span={24} md={8}>
                <CustomInputNumber label={'ROR %'} placeholder={'ROR %'} name={'partner_ror_percent'}
                />
            </Col>
            <Col span={24} md={8}>
                <CustomInputNumber label={'DRAW %'} placeholder={'DRAW %'} name={'partner_draw_per'}
                />
            </Col>
            <Col span={24} md={8}>
                <CustomInputNumber label={'EMP Match %'} placeholder={'EMP Match'} name={'partner_emp_match'}
                />
            </Col>
            <Col span={24} md={8}>
                <CustomInputNumber
                    label={
                        <>
                            EPlan Pension<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                        </>
                    }
                    placeholder={' EPlan Pension'} name={'e_penson'}
                />
            </Col>
        </Row>


    )
}

export default Partnerform