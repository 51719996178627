import React from 'react'
import { TopTitle } from '../../../../../Components/Form/TopTitle'
import { Table } from '../../../../../Components/Table'
import { customFormat } from '../../../../../utils/customFormat'


const OptimalAssets = ({optimalData}) => {

    const columns = [
        {
            title: 'Optimal Asset Allocation',
            dataIndex: 'asset_allocation'
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (value) =>` ${customFormat(value)}` ,
        },
        {
            title: 'Percent',
            dataIndex: 'per'
        },
    ]

    return (
        <div>
            {/* <TopTitle Heading={'Optimal Asset'} /> */}
            <Table columns={columns} data={optimalData} />
        </div>
    )
}

export default OptimalAssets