import React, { useEffect, useState } from 'react'
import { Table } from '../../../Components/Table'
import { TopTitle } from '../../../Components/Form/TopTitle'
import request from '../../../utils/request'

const CurrentAssets = ({currentdata}) => {

    const columns = [
        {
            title: 'Current Asset Allocation',
            dataIndex: 'asset_allocation'
        },
        {
            title: 'Amount',
            dataIndex: 'amount'
        },
        {
            title: 'Percent',
            dataIndex: 'per'
        },
    ]



    return (
        <div>
            <TopTitle Heading={'Current Asset'} />
            <Table columns={columns} data={currentdata} />
        </div>
    )
}

export default CurrentAssets