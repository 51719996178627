import { Col, Form } from 'antd';
import React, { useEffect, useState } from 'react'
import request from '../../../../utils/request';
import { Row } from '../../../../Components/Row';
import Input from '../../../../Components/Form/Input';
import { CustomInputNumber } from '../../../../Components/Form/CustomInputNumber';
import Flex from '../../../../Components/Flex';
import { TopTitle } from '../../../../Components/Form/TopTitle';
import PartnerDetails from './PartnerDetails';
import Button from '../../../../Components/Form/Button';
import Switch from '../../../../Components/Form/Switch';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EditForm from '../../ViewDetails/FormDetails.js/EditForm';
import { setPlanningRules } from '../../actions';




const Planningdetails = ({ RejinPalnningDeatails }) => {


    const [form] = Form.useForm();

    const URLS = 'e_app/planning_details'
    const [button, setButton] = useState(null);

    const GETURL = 'assets/assets_people_values/'

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedClient, setSelectedClient] = useState({});


    const [isChecked, setIsChecked] = useState(false);

    // const Rule100 = useSelector((state) => state.planningrules.planningrules)
    // console.log(Rule100, 'Rule100lll');

    const onFinish = (values) => {

        const record = { ...values };
        let result = {
            people: record.people,

            person_name: record.person_name,
            person_age: record.person_age,
            person_desire_age: record.person_desire_age,
            person_social: record.social_person_total_income,
            person_penson: record.pension_person_total_income,
            person_cola_percent: record.person_cola_percent,
            person_inflation_percent: record.person_inflation_percent,
            person_contribution: record.person_contribution,
            person_ror_percent: record.person_ror_percent,
            person_draw_per: record.person_draw_per,
            person_emp_match: record.person_emp_match,
            person_investable_assets: record.new_total_invest,
            e_penson: record.e_penson,

            partner_name: record.partner_name,
            partner_age: record.partner_age,
            partner_desire_age: record.partner_desire_age,
            partner_social: record.social_partner_total_income,
            partner_penson: record.pension_partner_total_income,
            partner_investable_assets: record.total_401k_balance,
            partner_cola_percent: record.partner_cola_percent,
            partner_inflation_percent: record.partner_inflation_percent,
            partner_contribution: record.partner_contribution,
            partner_ror_percent: record.partner_ror_percent,
            partner_draw_per: record.partner_draw_per,
            partner_emp_match: record.partner_emp_match,

            income_goal: record.income_goal,
            total_household_income: record.total_household_income,
        }

        AddPlanDetails(result);
    };

    const onFinishFailed = (errorInfo) => {
        toast.error('Please Fill the Details')
        console.log('Failed:', errorInfo);
    };

    const handlechecked = (e) => {
        setIsChecked(!isChecked)
    }


    const AddPlanDetails = (values) => {
        request.post(`${URLS}`, values)
            .then((response) => {

                if (response.status == 201) {
                    navigate('/ViewPlanning')
                    toast.success('Add Details Successfully')
                    form.resetFields()
                    dispatch(RejinPalnningDeatails(response.data))
                }
                else if (response.status === 208) {
                    toast.warn('User Profile Already Exists!');
                }
            })
            .catch(function (error) {
                console.log(error)
                // toast.error('Failed!')

            });
    }

    useEffect(() => {
        form.setFieldsValue({ person_name: selectedClient.person_name })
        form.setFieldsValue({ person_age: selectedClient.person_age })
        form.setFieldsValue({ person_desire_age: selectedClient.person_retire_age })
        form.setFieldsValue({ income_goal: selectedClient.income_goal })
        form.setFieldsValue({ total_household_income: selectedClient.total_household_income })
        form.setFieldsValue({ new_total_invest: selectedClient.new_total_invest })
        form.setFieldsValue({ partner_name: selectedClient.partner_name })
        form.setFieldsValue({ partner_age: selectedClient.partner_age })
        form.setFieldsValue({ partner_desire_age: selectedClient.partner_retire_age })
        form.setFieldsValue({ people: selectedClient.id })
        form.setFieldsValue({ social_person_total_income: selectedClient.social_person_total_income })
        form.setFieldsValue({ pension_person_total_income: selectedClient.pension_person_total_income })
        form.setFieldsValue({ social_partner_total_income: selectedClient.social_partner_total_income })
        form.setFieldsValue({ pension_partner_total_income: selectedClient.pension_partner_total_income })
        form.setFieldsValue({ total_401k_balance: selectedClient.total_401k_balance })
    }, [selectedClient])


    const GetPlanDetails = () => {
        request.get(`${GETURL}`)
            .then((response) => {
                setSelectedClient(response.data)

            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        GetPlanDetails()
    }, [])

    useEffect(() => {
        form.setFieldsValue({ client_name: selectedClient })
    }, [selectedClient])


    const onReset = () => {
        if (GetPlanDetails) {
            GetPlanDetails();
        } form.resetFields();
    }

    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <TopTitle Heading={'Client Details :'} />
            <Row gutter={[24, 24]}>
                <Col span={24} md={8}>
                    <Input label={'Name'} placeholder={'Name'} name={'person_name'}
                        disabled
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Your Family Name!',
                            }
                        ]} />
                </Col>
                <Col span={24} md={8}>
                    <CustomInputNumber label={'Age'} placeholder={'Age'} name={'person_age'} disabled />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={' Desired Age'} placeholder={'Desired Age'} name={'person_desire_age'} disabled />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber
                        label={
                            <>
                                Income Goal<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                            </>
                        }
                        placeholder={'Income Goal'} name={'income_goal'} disabled />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber
                        label={
                            <>
                                Annual Income<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                            </>
                        }

                        placeholder={'Annual Income'} name={'total_household_income'} disabled />
                </Col>

                <Input name={'people'} display={'none'} />

                <Col span={24} md={8}>
                    <CustomInputNumber
                        label={
                            <>
                                SOC 1<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                            </>
                        }

                        placeholder={'SOC 1'} name={'social_person_total_income'} disabled />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber
                        label={
                            <>
                                Pension 1<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                            </>
                        }

                        placeholder={'Pension 1'} name={'pension_person_total_income'} disabled />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber
                        label={
                            <>
                                Investable Amount<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                            </>
                        }

                        placeholder={'Investable Amount'} name={'new_total_invest'} disabled />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'COLA %'} placeholder={'COLA %'} name={'person_cola_percent'}
                        rules={[
                            {
                                required: true,
                                message: 'This Is requird field!',
                            }
                        ]} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Inflation %'} placeholder={'Inflation'} name={'person_inflation_percent'}
                        rules={[
                            {
                                required: true,
                                message: 'This Is requird field!',
                            }
                        ]} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Contributions %'} placeholder={'Contributions'} name={'person_contribution'}
                    />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'ROR %'} placeholder={'ROR %'} name={'person_ror_percent'}
                        rules={[
                            {
                                required: true,
                                message: 'This Is requird field!',
                            }
                        ]} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Draw %'} placeholder={'Draw %'} name={'person_draw_per'}
                        rules={[
                            {
                                required: true,
                                message: 'This Is requird field!',
                            }
                        ]} />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber label={'Emp Match %'} placeholder={'Emp Match'} name={'person_emp_match'}
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'This Is requird field!',
                    //     }
OLA

                    />
                </Col>

                <Col span={24} md={8}>
                    <CustomInputNumber
                        label={
                            <>
                                E-Pension<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                            </>
                        }

                        placeholder={'E - Pension'} name={'e_penson'}
                    />
                </Col>

            </Row><br />
            {/* <Flex flexStart>
                <Switch onChange={(e) => handlechecked()} />
            </Flex> */}

            <TopTitle Heading={'Spouse Details :'} />
            <PartnerDetails />
            {/* {isChecked ?  : null} */}

            <Flex center gap={'20px'} >
                {/* <Button.Success text={'Back'} onClick={() => navigate('/')} /> */}

                <Button.Primary text={'Submit'} htmlType={'submit'} />
                <Button.Danger text={'CANCEL'} onClick={() => onReset()} />
                {button}
            </Flex>
        </Form>
    )
}

export default Planningdetails
