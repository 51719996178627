import { Col, Form, Upload as Uploded } from 'antd'
import { TopTitle } from '../../../Components/Form/TopTitle';
import { Select } from '../../../Components/Form/Select';
import { CustomInputNumber } from '../../../Components/Form/CustomInputNumber';
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';
import { Fragment } from 'react';
import { Row } from '../../../Components/Row';
import { useState } from 'react';
import { useEffect } from 'react';
import request from '../../../utils/request';
import Input from '../../../Components/Form/Input';
import { CustomSelect } from '../../../Components/Form/CustomSelect';
import { toast } from 'react-toastify';
import AssetsViewtable from './AssetsViewtable';
import { useNavigate } from 'react-router-dom';


export const AddAssets = ({ datas, HandleGetAssets, dataSource }) => {

    const [form] = Form.useForm();
    const [getdata, setGetdata] = useState([])
    const [selectedClient, setSelectedClient] = useState({});

    const navigate = useNavigate();

    const URL = 'assets/assets_people_values/'
    const URLS = 'assets/add_assets'

    const onFinish = (values) => {

        const record = { ...values };
        let result = {
            current_bal_amt: record.current_bal_amt || 0,
            client_name: record.client_name,
            type: record.type,
            risk: record.risk,
            people: record.people,
            account: record.account,
            contribution_amt: record.contribution_amt || 0,

        }
        if (GetClientName) {
            GetClientName();
        }
        // if(AssetsTableEdit){
        //     AssetsTableEdit(values,datas.id)
        //     handleOk()
        // }
        // else{
        HandlePostAssets(result)
        // }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onReset = (values) => {
        HandleGetAssets();
        GetClientName();
        form.resetFields();

    }
    const HandlePostAssets = (values) => {

        request.post(`${URLS}`, values)
            .then(function (response) {
                toast.success('Successfully Added Assets!')
                GetClientName()
                HandleGetAssets();
                form.resetFields();
            })
            .catch(function (error) {
                toast.error('failed')
                console.log(error);
            });
    }

    useEffect(() => {
        GetClientName();
    }, [])


    const GetClientName = () => {

        request.get(`${URL}`)
            .then(function (response) {
                setGetdata(response.data)
                setSelectedClient(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    useEffect(() => {
        setGetdata(getdata)
    }, [getdata])

    useEffect(() => {
        form.setFieldsValue({ client_name: selectedClient.person_name })
        form.setFieldsValue({ people: selectedClient.id })
    }, [selectedClient])


    const Riskoption = [
        { label: 'Bank', value: 'Bank', description: 'Safe and liquid typically this is money used to pay for bills and short term needs.' },
        { label: 'Protected', value: 'Protected', description: 'This is money that must be protected so you can guarantee your income in retirement..' },
        { label: 'Real Estate', value: 'Real Estate', description: 'ePlan consultants was established in Cyprus in 2008 with the vision to be the leading cloud services provider in Cyprus and in the region. Since 2010, we are the largest Google G Suite partner in Cyprus. We are a team of professionals with several years of work experience in the Information Technology and Telecommunications sector, doing development, training, consulting, and design for various companies in Cyprus and abroad.' },
        { label: 'Wall Street', value: 'Wall Street', description: 'If you can lose money....then it goes in the red column.Include Stocks,IRAs/401(k)s.403(b)s.' },
    ];

    const TypeOption = [
        { label: '401K', value: '401K' },
        { label: 'Bonds', value: 'Bonds' },
        { label: 'Checking Account', value: 'Checking Account' },
        { label: 'Real Estate', value: 'Real Estate' },
        { label: 'ROTH', value: 'ROTH' },
        { label: 'Savings Account', value: 'Savings Account' },
        { label: 'Stocks', value: 'Stocks' },
    ]

    const Clientoption = [{ label: getdata?.person_name, value: getdata?.person_name }, { label: getdata?.partner_name, value: getdata?.partner_name }]

    useEffect(() => {
        form.setFieldsValue(datas)
    }, [datas])

    const handleTypeChange = (value) => {
        if (value === "401K") {
            form.setFieldsValue({ risk: 'Wall Street' })
        } else {
            form.setFieldsValue({ risk: null })
        }
    }

    return (
        <Fragment>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}

                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <Row gutter={[12, 12]}>

                    <Col span={24} md={12}>
                        <Select
                            options={Clientoption}
                            label={'Client Name'}
                            placeholder={'Client Name'}
                            name={'client_name'}
                            disabled
                            rules={[
                                {
                                    required: true,
                                    message: 'This is required field!'
                                }
                            ]} />
                    </Col>
                    <Col span={24} md={12}>
                        <Select
                            options={TypeOption}
                            label={'Type'}
                            onChange={handleTypeChange}
                            placeholder={'Type'} name={'type'}
                            rules={[
                                {
                                    required: true,
                                    message: 'This is required field!',
                                }
                            ]}

                        />
                    </Col>
                    <Col span={24} md={12}>
                        <Input label={'Account'} placeholder={'Account'} name={'account'}
                            rules={[
                                {
                                    required: true,
                                    message: 'This is required field!',
                                }
                            ]} />
                    </Col>
                    <Col span={24} md={12}>
                        <Select
                            showSearch
                            options={Riskoption}
                            label={'Risk'}
                            placeholder={'Risk'} name={'risk'}
                            rules={[
                                {
                                    required: true,
                                    message: 'This is required field!',
                                }
                            ]}
                        />
                    </Col>
                    <Col span={24} md={12}>
                        <CustomInputNumber
                            label={
                                <>
                                    Current Balance <span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                </>
                            }
                            placeholder={'Current Balance'} name={'current_bal_amt'}
                            precision={2} defaultValue={0}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter current balance!',
                                }
                            ]} />
                    </Col>
                    <Col span={24} md={12}>
                        <CustomInputNumber
                            label={
                                <>
                                    Contribution<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                </>
                            }
                            placeholder={'Contribution'} name={'contribution_amt'}
                        />
                    </Col>


                    <Input name={'people'} display={'none'} />
                </Row>

                <Flex center gap={'20px'} style={{ margin: '20px 0px' }}>
                    {/* <Button.Success text={'Back'} onClick={() => navigate('/')} /> */}
                    <Button.Primary text={'SUBMIT'} htmlType={'submit'} />
                    <Button.Danger text={'CANCEL'} onClick={() => onReset()} />
                </Flex>

            </Form>

            <AssetsViewtable HandleGetAssets={HandleGetAssets} setGetdata={setGetdata} dataSource={dataSource} GetClientName={GetClientName} getdata={getdata} />

        </Fragment>
    )
}