import { Col, Form } from 'antd';
import React from 'react'
import { toast } from 'react-toastify';
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';
import Input from '../../../Components/Form/Input';
import { Row } from '../../../Components/Row';
import request from '../../../utils/request';
import { useState } from 'react';
import { useEffect } from 'react';

const TypeForms = ({ GetTypesData, handleOk, GetTabelTypes ,HandleGetAssets,setInitialChange}) => {

  const [form] = Form.useForm();

  const [getdata, setGetdata] = useState({})

  const URL = 'liabilites/add_type/'

  const onFinish = (values) => {
    const record = { ...values }
    let result = {
      type: record.type,
      people: record.people,
      id: record.id,
    }
    AddTypePost(result)
    handleOk()
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    form.setFieldsValue({ people: getdata.id })
  }, [getdata])


  const Getliabilities = () => {
    request.get('people/add_people/')
      .then(function (response) {
        setGetdata(response.data)
      })
      .catch(function (error) {
        console.error(error)
      })
  }

  useEffect(() => {
    Getliabilities()
  }, [])

  const AddTypePost = (values) => {
    request.post(`${URL}`, values)
      .then(function (response) {
        form.resetFields();
        setInitialChange(response.data?.type)
        GetTypesData();
        GetTabelTypes();
        Getliabilities()
        HandleGetAssets();
        if (response.status === 226) {
          toast.warn('Type Already exists');
        }
        else {
          toast.success('Add Successfully')
        }
      })
      .catch(function (error) {
        toast.error('Failed to add type');
      });
  }

  const onRest = () => {
    form.resetFields();
  }

  return (
    <div>
      <Form
        form={form}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}

        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">

        <Row gutter={[12, 12]}>

          <Col span={24} md={24}>

            <Input name={'people'} display={'none'}/>
            <Input label={'Type'}
              placeholder={'Type'} name={'type'}
              rules={[
                {
                  required: true,
                  message: 'This is required field!'
                }
              ]} />
          </Col>

        </Row>
        <Flex center gap={'20px'} style={{ margin: '30px' }}>
          <Button.Success text={'ADD'} htmlType={'submit'} />
          <Button.Danger text={'RESET'} onClick={onRest} />
        </Flex>

      </Form>
    </div>
  )
}

export default TypeForms