import React from 'react'
import { TopTitle } from '../../Components/Form/TopTitle'
import { CustomCard } from '../../Components/CustomCard'
import ViewTotalAssets from './Partials/ViewTotalAssets'
import ViewTotalLiabilities from './Partials/ViewTotalLiabilities'
import NetworthTotalCard from './Partials/NetworthTotalCard'
import { useEffect } from 'react'
import request from '../../utils/request'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../Components/Form/Button'
import { Row } from '../../Components/Row'
import Flex from '../../Components/Flex'
import { Col } from 'antd'
import BackButton from '../../Components/Form/BackButton'
import { FaArrowCircleLeft } from 'react-icons/fa'
import Label from '../../Components/Form/Label'


const Mynetworth = ({ liability }) => {
    const navigate = useNavigate();

    const [dataSource, setDataSource] = useState([])
    const [tableData, setTableData] = useState([])

    const [assetsData, setAssetsData] = useState([])


    const URLS = 'networth/networth/'

    const HandleGetAssets = () => {
        request.get(`${URLS}`)
            .then(function (response) {
                setDataSource(response.data?.asset)
                setTableData(response.data?.liability)
                setAssetsData(response.data?.networth)


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        HandleGetAssets();
    }, [])
    return (
        <div>

            <Flex gap={'15px'}>
                <BackButton text={<FaArrowCircleLeft style={{ fontSize: '26px' }} />} onClick={() => navigate('/')} />
                <TopTitle Heading={'My Networth'} />
            </Flex>
            
            <CustomCard>
                <NetworthTotalCard datas={assetsData} /><br />

                <TopTitle Heading={'Assets'} />
                <ViewTotalAssets dataSource={dataSource} HandleGetAssets={HandleGetAssets} />

                <TopTitle Heading={'Liability'} />
                <ViewTotalLiabilities tableData={tableData} HandleGetAssets={HandleGetAssets} liability={liability} />
                {/* <Button.Success text={'Back'} onClick={() => navigate('/')} /> */}
            </CustomCard>
        </div>
    )
}

export default Mynetworth


