
export const SET_COMPANY_PROFILE = 'SET_COMPANY_PROFILE'
export const DELETE_COMPANY_PROFILE = 'DELETE_COMPANY_PROFILE'



export const setCompanyProfile = value => {
    return {
        type: SET_COMPANY_PROFILE,
        payload: value,
    }
}

export const deleteCompanyProfile = () => {
    return {
        type: DELETE_COMPANY_PROFILE,
    }
}

