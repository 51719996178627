import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'right'
        },
        // title: {
        //     display: true,
        //     text: 'Chart Bar Chart',
        // }
    }
}


const BarChart = ({ graphtData }) => {

    const ColumnGap = [
        graphtData.map(column => column?.income_gap)
    ]

    const IncomeGoal = [
        graphtData.map(column => column?.income_goal)
    ]

    const Retirement = [
        graphtData.map(column => column?.retire_ment_income)
    ]


    const data = {
        labels: ['1', '2', '3', '4', '5', '6'],
        datasets: [
            {
                label: 'Income Gap',
                data: ColumnGap[0],
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Retirement Income',
                data: Retirement[0],
                backgroundColor: 'red',
            },
            {
                label: 'Income Goal',
                data: IncomeGoal[0],
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };
    return (
        <div style={{ width: '800', height: '400px' }}>
            <Bar options={options} data={data} />
        </div>
    )
}

export default BarChart