import React, { useEffect, useState } from 'react'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import Flex from '../../../Components/Flex'
import Button from '../../../Components/Form/Button'
import { Modal } from '../../../Components/Modal'
import { Table } from '../../../Components/Table'
import { customFormat } from '../../../utils/customFormat'
import {AddAssets} from '../../AddAssets/Partials/AddAssets'
import DeleteAssets from '../../AddAssets/Partials/DeleteAssets'
import { EditAssets } from '../../AddAssets/Partials/EditAssets'
import { Overhid } from './style'

const ViewTotalAssets = ({ dataSource ,HandleGetAssets}) => {

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const columns = [
        {
            title: 'SI No',
            render: (text, value, index) => index + 1,

        },
        {
            title: 'Client Name',
            dataIndex: 'client_name',

        },
        {
            title: 'Type',
            dataIndex: 'type',

        },
        {
            title: 'Account',
            dataIndex: 'account',
        },
        {
            title: 'Contribution Amount',
            dataIndex: 'contribution_amt',
            render: (value) =>  `$ ${customFormat(value)}`, 
        },
        {
            title: 'Current Balance',
            dataIndex: 'current_bal_amt',
            render: (value) =>  `$ ${customFormat(value)}`, 
        },
   
    ]

    return (
        <div style={{ marginBottom: '50px' }}>
            <Overhid>
                <Table columns={columns} data={dataSource} />
            </Overhid>
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={600} modalTitle={modalTitle} modalContent={modalContent} />


        </div>
    )
}

export default ViewTotalAssets