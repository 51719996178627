import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import CurrentAssets from './Partials/CurrentAssets'
import OptimalAssets from './Partials/OptimalAssets'
import Currentchart from './Partials/Currentchart'
import Optimalchart from './Partials/Optimalchart'
import Flex from '../../Components/Flex'
import request from '../../utils/request'

const MyEplanOptimal = () => {

  const URL = 'e_app/current_asset_allocation/'

  const URLS = 'e_app/optimal_asset_allocation/'

  const [currentdata, setCurrentdata] = useState([])
  const [optimalData, setoptimalData] = useState([])
  const [graphData, setGraphData] = useState([])
  const [optimalgraphdata, setOptimalgraphdata] = useState([])

  //========================Current Assets=====================================
  const Getitems = () => {
    request.get(`${URL}`)
      .then(function (response) {
        setCurrentdata(response.data?.Table)
        setGraphData(response.data?.Graph)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    Getitems();
  }, [])
  //========================Optimal Assets=====================================
  const GetOptimal = () => {
    request.get(`${URLS}`)
      .then(function (response) {
        setoptimalData(response.data?.Table)
        setOptimalgraphdata(response.data?.Graph)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetOptimal();
  }, [])

  return (
    <div>
      <Row gutter={[12, 12]}>
        <Col sm={24} md={8}>
          <Currentchart graphData={graphData} />
        </Col>
        <Col sm={24} md={5}></Col>
        <Col sm={24} md={8}>
          <Optimalchart optimalgraphdata={optimalgraphdata} />
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col sm={24} md={12}>
          <CurrentAssets currentdata={currentdata} />
        </Col>
        <Col sm={24} md={12}>
          <OptimalAssets optimalData={optimalData} />
        </Col>
      </Row>
    </div>
  )
}

export default MyEplanOptimal