import { Col, Form, Row, Upload as Uploded } from 'antd'
import { useState } from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { CustomDatePicker } from '../../../../Components/Form/CustomDatePicker';
import Button from '../../../../Components/Form/Button';
import Flex from '../../../../Components/Flex';
import { CustomInputNumber } from '../../../../Components/Form/CustomInputNumber';
import Input from '../../../../Components/Form/Input';
import request from '../../../../utils/request';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Childrens } from '../../../../Assets/DashboardData';
import { CustomPageFormSubTitle } from '../../../../Components/Form/FormSub';


export const Editperson = ({ trigger, handlePeople, handleOk }) => {

    const [childData, setChildData] = useState([])
    const [viewPeople, setViewPeople] = useState({})

    const URL = 'people/edit_people_new'

    const [form] = Form.useForm();

    const onFinish = (values) => {
        EditPeoples(values, values.id)

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    //==================Edit date records Format========================================//

    const onReset = () => {
        form.resetFields();
        handleOk();
    }


    useEffect(() => {
        form.setFieldsValue({ id: viewPeople.id })
        form.setFieldsValue({ person_name: viewPeople.person_name })
        form.setFieldsValue({ person_age: viewPeople.person_age })
        form.setFieldsValue({ person_retire_age: viewPeople.person_retire_age })
        form.setFieldsValue({ person_dob: viewPeople.person_dob })
        form.setFieldsValue({ partner_name: viewPeople.partner_name })
        form.setFieldsValue({ partner_age: viewPeople.partner_age })
        form.setFieldsValue({ partner_retire_age: viewPeople.partner_retire_age })
        form.setFieldsValue({ partner_dob: viewPeople.partner_dob })
        form.setFieldsValue({ income_goal: viewPeople.income_goal })


        const fd = childData.map((item) => ({
            child_name: item.child_name,
            child_age: item.child_age,
            id: item.id,
        }));

        form.setFieldsValue({ children: fd })

    }, [viewPeople, childData])

    useEffect(() => {
        GetPeople();
    }, [trigger])


    const GetPeople = (id) => {
        request.get(`people/add_people_new/`)
            .then(function (response) {
                setViewPeople(response.data)
                setChildData(response.data?.children)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    //=================== EditPeople =======================================//
    const EditPeoples = (values, id) => {
        request.patch(`${URL}/${id}`, values)
            .then((response) => {
                form.resetFields();
                handlePeople();
                GetPeople();
                toast.info('Updated Details Successfully')

            }).catch(error => {
                console.log(error);
                toast.error('Failed')
            });
    }

    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}

            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">

            <Row gutter={[24, 24]}>
                <Col span={24} md={12}>
                    <Input label={'Name Hame'} placeholder={' Client Name'} name={'person_name'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Your Name!',
                            }
                        ]}
                    />
                </Col>
                <Input name={'id'} display={'none'} />
                <Col span={24} md={12}>
                    <Input label={'Spouse Name'} placeholder={'Spouse'} name={'partner_name'}
                    />
                </Col>

                <Col span={24} md={12}>
                    <CustomInputNumber label={'Age '} placeholder={'Age'} name={'person_age'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Your Age!',
                            },

                        ]} />
                </Col>
                <Col span={24} md={12}>
                    <CustomInputNumber label={'Spouse Age'} placeholder={'Spouse Age'} name={'partner_age'}
                    />
                </Col>

                <Col span={24} md={12}>
                    <CustomInputNumber label={' Retirement Age '} placeholder={'Retirement Age'} name={'person_retire_age'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Your Retirement Age!',
                            }
                        ]}
                    />
                </Col>

                <Col span={24} md={12}>
                    <CustomInputNumber label={'Spouse Retirement Age '} placeholder={'Spouse Retirement Age'} name={'partner_retire_age'}
                    />
                </Col>
                <Col span={24} md={12}>
                    <CustomPageFormSubTitle Heading={'Children Details'} />
                    <Form.List name="children">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Row gutter={[24, 24]}>
                                        <Col span={24} md={24}>
                                            <div >
                                                <Input label={'Children Name'} name={[name, "child_name"]}
                                                    placeholder="Children Name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This is required field!',
                                                        }
                                                    ]}
                                                />
                                                <Input label={'Children Age'} name={[name, "child_age"]}
                                                    placeholder="Children Age"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This is required field!',
                                                        }
                                                    ]}
                                                />
                                                <Input name={'id'} display={'none'} />

                                            </div>
                                        </Col>

                                        <Col span={24} md={24}>
                                            <Button.Danger
                                                style={{ marginLeft: '8px' }}
                                                text={<MinusCircleOutlined />}
                                                onClick={() => remove(name)}
                                            />
                                        </Col>
                                    </Row>
                                ))}
                                <Col span={24} md={24} >
                                    <Form.Item style={{ margin: '20px 10px' }} >
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add Children Details
                                        </Button>
                                    </Form.Item>
                                </Col>

                            </>
                        )}
                    </Form.List>


                </Col>
                <Col span={24} md={12}>
                    <CustomInputNumber
                        label={
                            <>
                                Income Goal<span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                            </>
                        }
                        placeholder={'Income Goal'} name={'income_goal'}
                    />
                </Col>

            </Row>
            <Flex center gap={'20px'} style={{ margin: '20px 0px' }}>
                <Button.Primary text={'SAVE'} htmlType={'SAVE'} />
                <Button.Danger text={'CANCEL'} onClick={() => onReset()} />
            </Flex>
        </Form>

    )
}