import React from 'react'
import { TopTitle } from '../../Components/Form/TopTitle'
import RetireTable from './Partials/RetireTable'
import RetireAccumForm from './Partials/RetireForm'
import { CustomCard } from '../../Components/CustomCard'

const RetireAccum = () => {
    return (
        <div>
            <TopTitle Heading={'Retirement Accum'} />
            <CustomCard>
                <RetireAccumForm />
            </CustomCard>
            <br />
            <TopTitle Heading={'View Details'} />
            <RetireTable />
        </div>
    )
}

export default RetireAccum