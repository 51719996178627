import { Col, Form } from 'antd';
import React, { Fragment, useEffect, useState } from 'react'
import { Select } from '../../../Components/Form/Select';
import { CustomInputNumber } from '../../../Components/Form/CustomInputNumber';
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';
import { Row } from '../../../Components/Row';
import request from '../../../utils/request';
import Input from '../../../Components/Form/Input';
import { toast } from 'react-toastify';
import { CustomSelect } from '../../../Components/Form/CustomSelect';
import { Modal } from '../../../Components/Modal';
import TypeForms from './TypeForm';
import MyLiabilities from '..';
import EditTypes from './EditTypes';
import { TopTitle } from '../../../Components/Form/TopTitle';
import { CustomCard } from '../../../Components/CustomCard';
import { Overhid } from '../../MyNetworth/Partials/style';
import { useNavigate } from 'react-router-dom';


const LiabilitiesFom = ({ datas, HandleGetAssets, GetTabelTypes, dataSource, handleOks }) => {

    const [form] = Form.useForm();

    const [typeData, setTypeData] = useState([])

    const [getdata, setGetdata] = useState([])
    const [selectedClient, setSelectedClient] = useState({});
    const [selectedType, setSelectedType] = useState({});
    const [initialChange, setInitialChange] = useState()

    const URL = 'liabilites/add_liabilities/'

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const onFinish = (values) => {

        const record = { ...values };
        let result = {
            current_balance: record.current_balance || 0,
            client_name: record.client_name,
            type: record.type,
            people: record.people,
            account: record.account,
            monthly_payments: record.monthly_payments
        }

        AddClientName(result)
        handleOk(false)

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onReset = () => {
        form.resetFields();
        Getliabilities();
        GetTypesData();
        HandleGetAssets();
    }

    const AddClientName = (values) => {
        request.post(`${URL}`, values)
            .then(function (response) {
                form.resetFields();
                Getliabilities();
                GetTypesData();
                HandleGetAssets();
                toast.success('Add Successfully')

            })
            .catch(function (error) {
                console.log(error);
                toast.error('Faild')
            });
    }
    //======================Client Name==========================//

    useEffect(() => {
        form.setFieldsValue({ client_name: selectedClient.person_name })
        form.setFieldsValue({ people: selectedClient.id })
    }, [selectedClient])


    const Getliabilities = () => {
        request.get('assets/assets_people_values/')
            .then(function (response) {
                setGetdata(response.data)
                setSelectedClient(response.data)
            })
            .catch(function (error) {
                console.error(error)
            })
    }

    useEffect(() => {
        Getliabilities()
    }, [])

    useEffect(() => {
        setGetdata(getdata)
    }, [getdata])

    const Clientoption = [{ label: getdata?.person_name, value: getdata?.person_name }, { label: getdata?.partner_name, value: getdata?.partner_name }]

    const EditPeople = () => {
        setModalTitle("Add Type");
        setModalContent(<TypeForms setInitialChange={setInitialChange}  HandleGetAssets={HandleGetAssets} selectedClient={selectedClient} setSelectedClient={setSelectedClient} GetTypesData={GetTypesData} GetTabelTypes={GetTabelTypes} handleOk={handleOk} selectedType={selectedType} Getliabilities={Getliabilities} />);
        showModal();
    }

    const TypeOptionss = typeData?.map((com) => ({ label: com.type, value: com.type }));

    useEffect(() => {
        form.setFieldsValue(datas)
    }, [datas])

    useEffect(() => {
        form.setFieldsValue({ type:initialChange })
    }, [initialChange])

    //====================select option type get request===================//

    const GetTypesData = () => {
        request.get('liabilites/add_type/')
            .then(function (response) {
                setTypeData(response.data)
            })
            .catch(function (error) {
                console.error(error)
            })
    }
    useEffect(() => {
        GetTypesData();
    }, [])

    return (
        <div>
            <Fragment>
                <Form
                    form={form}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}

                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">
                    {/* <CustomCard> */}
                    <Row gutter={[12, 12]}>

                        <Col span={24} md={12}>

                            <Select label={'Client Name'}
                                options={Clientoption}
                                placeholder={'Client Name'} name={'client_name'}
                                disabled
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select the Client Name !'
                                    }
                                ]} />
                        </Col>
                        <Col span={24} md={12}>
                            {datas ?
                                <Select label={'Type'} options={TypeOptionss} name={'type'} placeholder={'Type'}
                                    showSearch

                                    rules={[
                                        {
                                            required: true,
                                            message: 'This is required field!'
                                        }
                                    ]} /> :
                                <CustomSelect label={'Type'} options={TypeOptionss} name={'type'} placeholder={'Type'}
                                    onButtonClick={EditPeople} showSearch buttonLabel="Add Type"

                                    rules={[
                                        {
                                            required: true,
                                            message: 'This is required field!'
                                        }
                                    ]} />
                            }

                        </Col>

                        <Col span={24} md={12}>
                            <Input label={'Account'} placeholder={'Account'} name={'account'}
                            />
                        </Col>

                        <Input name={'people'} display={'none'} />

                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={
                                    <>
                                        Monthly Payment <span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }
                                placeholder={'Monthly Payment'} name={'monthly_payments'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={
                                    <>
                                        Current Balance <span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    </>
                                }
                                defaultValue={0} precision={2}
                                placeholder={'Current Balance'} name={'current_balance'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter Current Balance!',
                                    }
                                ]} />
                        </Col>
                    </Row>
                    <Flex center gap={'20px'} style={{ margin: '20px 0px' }}>
                        {/* <Button.Success text={'Back'} onClick={() => navigate('/')} /> */}
                        <Button.Primary text={'SUBMIT'} htmlType={'submit'} />
                        <Button.Danger text={'CANCEL'} onClick={() => onReset()} />
                    </Flex>
                    {/* </CustomCard> */}

                    {
                        datas ?
                            null : <> <Overhid><EditTypes setInitialChange={setInitialChange}   dataSource={dataSource} GetTabelTypes={GetTabelTypes} HandleGetAssets={HandleGetAssets} GetTypesData={GetTypesData} /></Overhid> </>

                    }
                </Form>
                <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={500} modalTitle={modalTitle} modalContent={modalContent} />
            </Fragment>

        </div>
    )
}

export default LiabilitiesFom