import { Col, Form } from 'antd';
import React from 'react'
import { Row } from '../../../Components/Row';
import Button from '../../../Components/Form/Button';
import Flex from '../../../Components/Flex';
import { CustomInputNumber } from '../../../Components/Form/CustomInputNumber';

const RetireAccumForm = () => {

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log(values, 'Success')
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const onReset = () => {
    form.resetFields();
  }

  return (
    <Form
      form={form}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off">

      <Row gutter={[12, 12]}>
        <Col span={24} md={8}>
          <CustomInputNumber label={'Retirement Investments'} placeholder={'Retirement Investments'} name={'Investments'} />
        </Col>

        <Col span={24} md={8}>
          <CustomInputNumber label={'Current Age'} placeholder={'Current Age'} name={'Current_age'} />
        </Col>

        <Col span={24} md={8}>
          <CustomInputNumber label={'Income Requirement ($)'} placeholder={'Income Requirement'} name={'Age_retirement'} />
        </Col>

        <Col span={24} md={8}>
          <CustomInputNumber label={'Expected Annual Expense Inflation %'} placeholder={'Expected Annual Expense Inflation'} name={'Working_years'} />
        </Col>

        <Col span={24} md={8}>
          <CustomInputNumber label={'Social Security Growth Rate %'} placeholder={'Social Security Growth Rate'} name={'Current_assets'} />
        </Col>

        <Col span={24} md={8}>
          <CustomInputNumber label={'Effective Inc.Tax Rate %'} placeholder={'Effective Inc.Tax Rate'} name={'Annual_contribution'} />
        </Col>

        <Col span={24} md={8}>
          <CustomInputNumber label={'Return Gross Percentage %'} placeholder={'Return Gross Percentage'} name={'Range_Return'} />
        </Col>

        <Col span={24} md={8}>
          <CustomInputNumber label={'Investable Assets ($)'} placeholder={'Investable Assets'}
            name={'Annual_Income_Needs'} />
        </Col>

        <Col span={24} md={8}>
          <CustomInputNumber label={'Real Estate ($)'} placeholder={'Real Estate'}
            name={'Projected_Inflation'} />
        </Col>

        <Col span={24} md={8}>
          <CustomInputNumber label={'Total Estate ($)'} placeholder={'Total Estate'}
            name={'totalestate'} />
        </Col>

        <Col span={24} md={8}>
          <CustomInputNumber label={'Other Assets Percentage %'} placeholder={'Other Assets Percentage'}
            name={'Exhausted'} />
        </Col>

        <Col span={24} md={8}>
          <CustomInputNumber label={'Social Security 1 ($)'} placeholder={'Social Security 1'}
            name={'Target'} />
        </Col>

        <Col span={24} md={8}>
          <CustomInputNumber label={'Social Security 2 ($)'} placeholder={'Social Security 2'}
            name={'Target'} />
        </Col>

        <Col span={24} md={8}>
          <CustomInputNumber label={'Pension Income ($)'} placeholder={'Pension Income'} name={'during_Retirement'}
          />
        </Col>

        <Col span={24} md={8}>
          <CustomInputNumber label={'Yes City ($)'} placeholder={'Yes City'} name={'yescity'}
          />
        </Col>

      </Row>
      <br />

      <Flex center gap={'20px'} >
        <Button.Primary text={'SUBMIT'} htmlType={'submit'} />
        <Button.Danger text={'RESET'} onClick={() => onReset()} />
      </Flex>
    </Form>
  )
}

export default RetireAccumForm