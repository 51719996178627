import React from 'react'
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);



const Currentchart = ({ optimalgraphdata }) => {

  const data = {
    labels: ['Wall street', 'Bank', 'Protected'],
    datasets: [
      {
        label: 'Percentage',
        data: [optimalgraphdata?.wall_percent, optimalgraphdata?.bank_percent, optimalgraphdata?.procted_percent],
        backgroundColor: [
          'red',
          'yellow',
          'green'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],

        borderWidth: 1,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right'
      },
      // tooltip: false,
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(2) + "%";
          return percentage;
        },
        color: "#000",
        color: function (context) {
          let dataIndex = context.dataIndex;
          let datasetIndex = context.datasetIndex;
          let dataset = context.chart.data.datasets[datasetIndex];
          let backgroundColor = dataset.backgroundColor[dataIndex];

          return backgroundColor === 'yellow' ? 'black' : 'white';
        },

        labels: {
          title: {
            font: {
              weight: "600",
              size: '16px'
            },
          },
        },
      },
    },
    onHover: function (e) {
      e.native.target.style.cursor = "pointer";
    },
    rotation: 270,
    events: ["click"],
  }

  return (
    <div >
      <Pie style={{ width: '800', height: '400px' }}
        data={data}
        options={options} />
    </div>
  )
}

export default Currentchart
