import { Col, Form } from 'antd'
import React from 'react'
import { Fragment } from 'react'
import { Row } from '../../../Components/Row'
import Input from '../../../Components/Form/Input'
import { CustomInputNumber } from '../../../Components/Form/CustomInputNumber'
import Flex from '../../../Components/Flex'
import Button from '../../../Components/Form/Button'
import { useEffect } from 'react'
import request from '../../../utils/request'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { Select } from '../../../Components/Form/Select'
import { TextAreas } from '../../../Components/Form/TextArea'

const EditIncomePage = ({ datas, GetIncomes, handleOk }) => {

    const [form] = Form.useForm();
    // const [getdata, setGetdata] = useState([])
    const [selectedClient, setSelectedClient] = useState({});
    const [getdata, setGetdata] = useState([])


    const URL = 'assets/assets_people_values/'
    const URLS = 'income/add_my_income/'

    const onFinish = (values) => {
        HandleEditIncome(values, datas.id);
        handleOk();
        console.log(values, 'SuccessFully')
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onReset = () => {
        // form.resetFields();
        handleOk();
        GetIncomes();
        GetClientName();
    }
    //=================Client name================================//
    useEffect(() => {
        GetClientName();
    }, [])

    const GetClientName = () => {
        request.get(`${URL}`)
            .then(function (response) {
                setGetdata(response.data)
                setSelectedClient(response.data)

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        form.setFieldsValue({ client_name: selectedClient.person_name })
        form.setFieldsValue({ people: selectedClient.id })
    }, [selectedClient])

    const TypeOptions = [
        { label: 'Social Security', value: 'Social Security' },
        { label: 'Salary', value: 'Salary' },
        { label: 'Pension', value: 'Pension' },
        { label: 'Other', value: 'Other' },
    ]
    const Clientoption = [{ label: getdata?.person_name, value: getdata?.person_name }, { label: getdata?.partner_name, value: getdata?.partner_name }]

    //======================Income Edit========================//

    const HandleEditIncome = (record, id) => {

        request.put(`income/income_edit/${id}/`, record)
            .then(function (response) {
                toast.info('Successfully Updated Details!')
                GetIncomes();
                GetClientName();
            })
            .catch(function (error) {
                // toast.error('failed')
                console.log(error);
            });
    }
    useEffect(() => {
        form.setFieldsValue(datas)
    }, [datas])

    return (
        <Fragment>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}

                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <Row gutter={[12, 12]}>

                    <Col span={24} md={12}>
                        <Select
                            options={Clientoption}
                            label={'Client Name'}
                            placeholder={'Client Name'}
                            name={'client_name'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Select Client Name !'
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Select
                            options={TypeOptions}
                            showSearch
                            label={'Type'}
                            placeholder={'Type'} name={'type'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Type!',
                                }
                            ]}
                        />
                    </Col>

                    <Col span={24} md={12}>
                        <TextAreas label={'Description'} placeholder={'Description'} name={'description'} />
                    </Col>

                    <Col span={24} md={12}>
                        <CustomInputNumber
                            label={
                                <>
                                    Amount <span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                </>
                            }
                            placeholder={'Amount'} name={'amount'}
                            precision={2}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Your Amount!',
                                }
                            ]} />
                    </Col>

                    <Input name={'people'} display={'none'} />
                </Row>
                <Flex center gap={'20px'} style={{ margin: '20px 0px' }}>
                    <Button.Primary text={'SAVE'} htmlType={'submit'} />
                    <Button.Danger text={'CANCEL'} onClick={() => onReset()} />
                </Flex>

            </Form>
        </Fragment>
    )
}

export default EditIncomePage