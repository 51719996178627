import React, { useEffect } from 'react'
import { useState } from 'react'
import { Table } from '../../../../Components/Table'
import request from '../../../../utils/request'
import Flex from '../../../../Components/Flex'
import Input from '../../../../Components/Form/Input'
import { TopTitle } from '../../../../Components/Form/TopTitle'
import { Row } from '../../../../Components/Row'
import { Col } from 'antd'
import Label from '../../../../Components/Form/Label'
import { customFormat } from '../../../../utils/customFormat'

const Viewplanner = ({ learnerData }) => {

    const [searchText, setSearchText] = useState([]);

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const columns = [
        {
            title: 'SI No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Year',
            dataIndex: 'year',
        },
        {
            title: 'Age',
            dataIndex: 'age',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.age).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.age).includes(value.toUpperCase());
            },
        },
        {
            title: 'Desired Age',
            dataIndex: 'retire_age',
        },
        {
            title: 'Stage',
            dataIndex: 'stage',
        },
        {
            title: 'Soc 1',
            dataIndex: 'social1',
            render: (value) =>  ` ${customFormat(value)}`, 
            
        },
        {
            title: 'Soc 2',
            dataIndex: 'social2',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: 'Pension 1',
            dataIndex: 'penson1',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: 'Pension 2',
            dataIndex: 'penson2',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: 'ePlan Pension',
            dataIndex: 'e_penson',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: '401k Income',
            dataIndex: '401k_income',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: 'Goal',
            dataIndex: 'income_goal',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: 'RET Income Total',
            dataIndex: 'retire_ment_income',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: 'Gap',
            dataIndex: 'income_gap',
            render: (income_gap, record) => {
                return (
                    <span style={{ color: `${record.color}` }}>
                        {
                          customFormat(income_gap)
                        }
                    </span>

                );
            }
        },
        {
            title: '401k Balance Down',
            dataIndex: 'b_d',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: '401k Balance Up',
            dataIndex: 'b_up',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: '401k Balance Growth',
            dataIndex: 'growth',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
        {
            title: '401k Annual Contribution',
            dataIndex: 'annual_contiribution',
            render: (value) =>  ` ${customFormat(value)}`, 
        },
    ]



    return (
        <div>
            <Row>
                <Col span={24} md={12}>
                    <TopTitle Heading={'View Planner'} />
                </Col>
                <Col span={24} md={12}>
                    <Flex end centerVertically>
                        <Label>Search by Age : &nbsp;&nbsp;</Label>
                        <Input
                            placeholder="Search by Age"
                            value={searchText}
                            onSearch={handleSearch}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </Flex>
                </Col>
            </Row>


            <Table columns={columns} data={learnerData} />
        </div>
    )
}

export default Viewplanner