import request from './request'

const initializeApp = token => {
  request.defaults.headers.common['Authorization'] = token?.jwt
  request.defaults.headers.common['Rejin'] = token?.exp;
}


export default initializeApp
// import { toast } from 'react-toastify';
// import request from './request';


// const initializeApp = token => {
//   const jwt = token?.jwt;

//   if (jwt) {
//     const decodedToken = decodeJwtToken(jwt);
//     const expirationDate = new Date(decodedToken.exp * 1000); // Convert expiration timestamp to milliseconds

//     if (expirationDate < new Date()) {
//       toast.error('Your session has expired. Please log in again.'); // Display a toast message
//       return;
//     }

//     request.defaults.headers.common['Authorization'] = jwt;
//   }
// }

// function decodeJwtToken(token) {
//   // You need to implement a function to decode the JWT token here
//   // This function should decode the token and return the decoded payload
//   // There are various JWT libraries available that can help with this
//   // Example: jwt-decode, jsonwebtoken, etc.
// }

// export default initializeApp;

