import React from 'react'
import { Bar } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const ChartMain = ({ graphtData }) => {

    const ColumnGap = [
        graphtData.map(column => column?.income_gap)
    ]

    const IncomeGoal = [
        graphtData.map(column => column?.income_goal)
    ]

    const Retirement = [
        graphtData.map(column => column?.retire_ment_income)
    ]


    const data = {
        labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
        datasets: [
            {
                label: 'Income Gap',
                data: ColumnGap[0],
                backgroundColor: '#3c96f1',
            },
            {
                label: 'Retirement Income',
                data: Retirement[0],
                backgroundColor: '#75bc14',
            },
            {
                label: 'Income Goal',
                data: IncomeGoal[0],
                backgroundColor: '#fe6363',
            },
        ],
    };

    const options = {

        responsive: true,
        plugins: {
            title: {
                display: true,
            },
            legend: {
                position: 'right'
            },
        },
        colors: {
            color: "#FFF",
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    return (
        <div style={{ width: '1000', height: '500px' }} >
            <Bar options={options} data={data} />
        </div>
    )
}

export default ChartMain