import { Form } from 'antd';
import React, { useEffect } from 'react'
import Input from '../../../Components/Form/Input';
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';
import request from '../../../utils/request';
import { toast } from 'react-toastify';

const TableDelet = ({ GetPeople, datas, handleOk ,GetIncomes}) => {

    const [form] = Form.useForm();

    const DEL = 'income/income_edit'

    const onFinish = (values) => {
        DeleteTableLiabilities(values, values.id)
        handleOk()

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const onRest = () =>{
        handleOk();
    }

    const DeleteTableLiabilities = (values, id) => {

        request.delete(`${DEL}/${id}/`, values)
            .then(function (response) {
                toast.success('Deleted Successfully')
                GetIncomes();
                
            })
            .catch(function (error) {
                console.log(error);
                // toast.error('Faild')
            });
    }

  
    useEffect(() => {
        form.setFieldsValue({ datas})
    }, [datas])
    

    useEffect(() => {
        form.setFieldsValue({ id: datas.id })
    }, [datas])

    return (
        <div>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <Input name={'id'} display={'none'}/>

                <Flex center>
                    <h1 style={{ fontSize: '20px', color: '#685e5e' }}>Do you Want to delete these Household income?</h1>
                </Flex>

                <Flex center gap={'50px'} style={{ margin: '50px 0px' }}>
                    <Button.Success text={'Yes'} htmlType={'submit'} />
                    <Button.Danger text={'No'} onClick={onRest} />
                </Flex>
            </Form>
        </div>
    )
}

export default TableDelet