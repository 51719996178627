import { BiPlanet } from 'react-icons/bi'
import { ImProfile } from 'react-icons/im'
import { AiOutlineFundView, AiFillFileAdd } from 'react-icons/ai'
import { RiMoneyDollarCircleLine, RiHandCoinLine } from 'react-icons/ri'
import { SiHomeadvisor, SiMoneygram } from 'react-icons/si'
import { LiaMoneyCheckAltSolid } from "react-icons/lia";
import { GiExpense } from "react-icons/gi";
import { GiCash } from "react-icons/gi";
import { PiBankBold } from "react-icons/pi";
import { TbBusinessplan } from 'react-icons/tb'
import { GiReceiveMoney } from 'react-icons/gi'
import { MdAutoGraph, MdPreview } from 'react-icons/md'
import { FaCoins } from 'react-icons/fa'
import { authenticated3 } from '../../../routes/config/user'
import { Menu } from "antd";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import request from '../../../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import { setCompanyProfile } from '../../../Modules/People/actions'
import { setPlanningRules } from '../../../Modules/PlannningDetailsTotal/actions'

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}


const SideMenu = () => {
  const [CompanyPro, setCompanyPro] = useState([])
  const [planningRule, setplanningRule] = useState([])
  const dispatch = useDispatch();

  const company = useSelector((state) => state?.companyprofile?.companyprofile || CompanyPro)

  // useEffect(() => {
  //   GetCompany();
  // }, [])


  // const GetCompany = () => {

  //   request.get('people/add_people/')
  //     .then(function (response) {
  //       setCompanyPro(response.data)
  //       dispatch(setPlanningRules())
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }
  //===================Planning 100Rules=============================//


  const planning = useSelector((state) => state?.planningrules?.planningrules || planningRule)
  const UserRole = useSelector((state) => state?.auth?.token?.role)
  const SuperUser = useSelector((state) => state?.auth?.token?.role)

  // const SuperUser = useSelector((state) => state?.auth?.token?.super)

  // const GetPlanningRules = () => {

  //   request.get('e_app/planning_details')
  //     .then(function (response) {
  //       setplanningRule(response.data)
  //       // dispatch(setCompanyProfile(response.data))
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }
  // useEffect(() => {
  //   GetPlanningRules();
  // }, [])

  const rootSubinmenuKeys = ['sub1', 'sub2'];
  const [openKeys, setOpenKeys] = useState(['sub1']);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubinmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const items = [

    SuperUser !== 'Advisor' && SuperUser !== 'Admin' && getItem('Home', '', <MdAutoGraph />),

    
//    getItem('Mapping', 'mapping', <RiMoneyDollarCircleLine />),



//    getItem('Social Media', 'social_media', <aifillfileadd />),

//    getItem('Facts Sheets', 'facts_sheets', <RiMoneyDollarCircleLine />),

 //   getItem('Reports', 'reports', <RiMoneyDollarCircleLine />),

  //  getItem('Resources', 'Resources', <RiMoneyDollarCircleLine />),

    UserRole !== 'User' && getItem('Role', 'sub1', <SiMoneygram />, [

      SuperUser !== 'Advisor' && getItem('Register', '/register', <SiMoneygram />),

      getItem('Consumer List', '', <MdPreview />),

      SuperUser !== 'Advisor' && getItem('Advisor List', '/advisorlist', <SiHomeadvisor />),

    ]),
    UserRole == 'User' && getItem('Rule of 100', '/ViewPlanning',  <GiCash />),
    UserRole == 'User' && getItem('My Income', '/view_income',  <LiaMoneyCheckAltSolid/>),
    UserRole == 'User' && getItem('My Liability', '/View_Liabilities',  <GiExpense />),
    UserRole == 'User' && getItem('My Assets', '/view_Assets',  <PiBankBold />),
    UserRole == 'User' && getItem('My NetWorth', '/Networth',  <SiHomeadvisor/>),
    UserRole == 'User' && getItem('My Profile', '/Viewperson',  <ImProfile/>),

//    getItem('Facts Sheets', 'facts_sheets', <RiMoneyDollarCircleLine />),
    // UserRole !== 'User' && getItem('My E-Plan Details', 'EplanDetails', <TbBusinessplan />),

    // UserRole !== 'User' && getItem('Income Planner', 'IncomePlanner', <GiReceiveMoney />),



    // getItem('Survive', 'Survive', <GiReceiveMoney />),
    // getItem('Young Accum', 'YoungAccum', <FaCoins />),
    // getItem('Retire Accum', 'RetireAccum', <RiHandCoinLine />),

  ]

  const navigate = useNavigate();

  const onClick = ({ key }) => {

    if (key === null) {
      console.log('no navigate')

    }
    else {
      navigate(`${key}/`)
    }
  }
  return (
    <>

      <Menu
        onClick={onClick}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultSelectedKeys={['']}
        // onClick={()=> onOopenSub()}
        defaultOpenKeys={['sub1']}
        mode="inline"
        items={items}
      />
    </>
  )
}

export default SideMenu
