import { connect } from "react-redux";
import selectors from "../../PlanningSelectors";
import PlanningDetailsMain from "../index";
import {RejinPalnningDeatails} from '../../actions'

const mapStateToProps = state =>({
    getPlanningRules:selectors.getPlanningRules(state),
})
const mapDispatchToProps = {
    RejinPalnningDeatails

}

export default connect(mapStateToProps, mapDispatchToProps)(PlanningDetailsMain)

  