import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import Flex from '../../../Components/Flex'
import Button from '../../../Components/Form/Button'
import { Modal } from '../../../Components/Modal'
import { Table } from '../../../Components/Table'
import request from '../../../utils/request'
import MyincomePage from './MyincomePage'
import TableDelet from './Deletable'
import EditIncomePage from './EditIncome'
import { Row } from '../../../Components/Row'
import { TopTitle } from '../../../Components/Form/TopTitle'
import Label from '../../../Components/Form/Label'
import Input from '../../../Components/Form/Input'
import { Col, Form } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { CustomCard } from '../../../Components/CustomCard'
import BackButton from '../../../Components/Form/BackButton'
import { FaArrowCircleLeft } from 'react-icons/fa'

const ConsumerView = ({ incomeData, GetClientName, setGetdata, getdata }) => {

    const [form] = Form.useForm();

    const navigate = useNavigate();
    const [dataSource, setDataSource] = useState([])
    const [viewPeople, setViewPeople] = useState({})

    const { id } = useParams();

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchText, setSearchText] = useState([]);

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {

        setIsModalOpen(false);
    };

    function customFormat(amount) {
        const parts = amount.toString().split('.');
        parts[0] = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace(/(\d{2})(?=\d{2,})/, '$1,').replace(/(\d{2})(?=\d{2,})/, '$1,');
        return parts.join('.');
    }

    const columns = [
        {
            title: 'SI No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Client Name',
            dataIndex: 'client_name',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.client_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.client_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: ' Description',
            dataIndex: 'description',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (value) =>`$ ${customFormat(value)}` ,
        },

    ]

    useEffect(() => {
        GetPeople();
    }, [])

    const GetPeople = () => {
        request.get(`advisor/view_consumer_profile/${id}`)
            .then(function (response) {
                setDataSource(response.data?.income)
                setViewPeople(response.data.people)

            })
            .catch(function (error) {
                console.log(error);
            });
    }
//==================People incomegoal================================//

useEffect(() => {
    if (viewPeople?.income_goal !== undefined) {
        const formattedIncomeGoal = customFormat(viewPeople.income_goal);
        form.setFieldsValue({ income_goal: formattedIncomeGoal.toString() });
    }
}, [viewPeople]);

    return (
        <div>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                autoComplete="off">
                <Flex gap={'15px'}>
                    <BackButton text={<FaArrowCircleLeft style={{ fontSize: '26px' }} />} onClick={() => navigate(`/View/${id}`)} />
                    <TopTitle Heading={'Household Income'} />
                </Flex>
                <CustomCard>
                    <Row>
                        <Col span={24} md={12} sm={24} xs={24}>
                            <Flex centerVertically start >
                                <Row>
                                    <Col span={24} md={9}  >
                                        <Flex centerVertically style={{ marginTop: '9px' }}>
                                        <Label>Income Goal <span style={{ fontSize: '17px', marginLeft: '5px' }}>$</span> :&nbsp;&nbsp;</Label>
                                        </Flex>
                                    </Col>
                                    <Col span={24} md={12} >
                                        <Input
                                            placeholder={'Income Goal'} name={'income_goal'} disabled
                                        />
                                    </Col>

                                </Row>
                            </Flex>
                        </Col>
                        <Col span={24} md={12} sm={24} xs={24} >
                            <Flex centerVertically end  >
                                <Row>
                                    <Col span={24} md={12} >
                                    <Flex center style={{ marginTop: '12px' }}>
                                            <Label>Search by Client Name :&nbsp;&nbsp;</Label>
                                        </Flex>

                                    </Col>
                                    <Col span={24} md={12} >

                                        <Input
                                            placeholder="Search by Client Name"
                                            value={searchText}
                                            onSearch={handleSearch}
                                            onChange={(e) => handleSearch(e.target.value)}
                                        />

                                    </Col>
                                </Row>

                            </Flex  >
                        </Col>

                    </Row>
                </CustomCard>
            </Form>

            <Table columns={columns} data={dataSource} />
            {/* <Button.Success text={'Back'} onClick={() => navigate(`/View/${id}`)} /> */}
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={500} modalTitle={modalTitle} modalContent={modalContent} />
            {/* </CustomCard> */}

        </div>
    )
}

export default ConsumerView