import React from 'react'
import { MdProductionQuantityLimits, MdShoppingCartCheckout } from 'react-icons/md'
import { AiOutlineShoppingCart } from 'react-icons/ai'

export const DashboardData = [
  {
    key: '1',
    icon: <MdProductionQuantityLimits/>,
    h1: 'Sales',
    Amount: '3000',
    p: 'Monthly Sales',
    Total: '0',

  },
  {
    key: '2',
    icon: <MdProductionQuantityLimits />,
    h1: 'Sales Return',
    Amount: '4500',
    p: 'Monthly Sales Return',
    Total: '0'

  },
  {
    key: '3',
    icon: <AiOutlineShoppingCart />,
    h1: 'Purchase',
    Amount: '1000',
    p: 'Monthly Purchase',
    Total: '0'

  },

  {
    key: '4',
    icon: <MdShoppingCartCheckout />,
    h1: 'Purchase Return',
    Amount: '4000',
    p: 'Monthly Purchase Return',
    Total: '0'

  },
]
export const DashSupplierData = [
  {
    h1: 'Brigestone',
    h3: '9894297542',
    aumd: '₹ 2002'
  },
  {
    h1: 'Ceat rtt',
    h3: '9894297542',
    aumd: '₹ 20022222'
  },

  {
    h1: 'FIRESTONE',
    h3: '9894297542',
    aumd: '₹ 2002'
  },

  {
    h1: 'MICHELIN TRUCK',
    h3: '9894297542',
    aumd: '₹ 2002'
  },
  {
    h1: 'MICHELIN TWO WHEELER',
    h3: '9894297542',
    aumd: '₹ 2002'
  },
  {
    h1: 'TVS',
    h3: '9894297542',
    aumd: '₹ 2002'
  },
  {
    h1: '8.5 tube',
    h3: '9894297542',
    aumd: '₹ 2002'
  },

]
export const CustomercreditData = [
  {
    h1: 'Richn',
    h3: '9894297542',
    aumd: '₹ 2002'
  },
  {
    h1: 'Ceat',
    h3: '9894297542',
    aumd: '₹ 2002'
  },

  {
    h1: 'FIRESTONE',
    h3: '9894297542',
    aumd: '₹ 2002'
  },

  {
    h1: 'MICHELIN TRUCK',
    h3: '9894297542',
    aumd: '₹ 2002'
  },
  {
    h1: 'MICHELIN TWO WHEELER',
    h3: '9894297542',
    aumd: '₹ 2002'
  },
  {
    h1: 'TVS',
    h3: '9894297542',
    aumd: '₹ 2002'
  },
  {
    h1: '8.5 tube',
    h3: '9894297542',
    aumd: '₹ 2002'
  },
]
export const HomepageRouter = [
  {
    h1: 'My Profile',
  },
  {
    h1: 'Income',
  },
  {
    h1: 'Liabilities',
  },
  {
    h1: 'Assets',
  },
  {
    h1: ' NetWorth',
  },
  {
    h1: 'My-eplan',
  },

]
export const parographData =[
  {
    protectedcontent:'This is money that must be protected so you can guarantee your income in retirement..',
    protectedcontent2:'Ex.Some insured bonds,Fixed,Index or Hybrid Annuities -NOT Variable Annuities',
    bank:'Safe and liquid typically this is money used to pay for bills and short term needs.',
    bank2:'Ex.Checking & Savings accounts,CDs and Money Markets & Credit Unions',
    wallstreet:'If you can lose money....then it goes in the red column.Include Stocks,IRAs/401(k)s.403(b)s.',
    wallstreet2:'Ex,Mutal,bond & Index Funds,ETF s,Stocks,Variable Annuities,Gold etc.,',
  },
]

export const Childrens = [
  {
    Childname:'child_name',
    childage:'child_age',
  },
  
  
  
  
]