import React from 'react'
import { FaArrowCircleLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import Flex from '../../Components/Flex'
import BackButton from '../../Components/Form/BackButton'
import { TopTitle } from '../../Components/Form/TopTitle'
import BasicTable from './Partials/BasicTable'

const Basic = () => {
    const navigate = useNavigate()
    return (
        <div>
            <Flex gap={'15px'}>
                <BackButton text={<FaArrowCircleLeft style={{ fontSize: '26px' }} />} onClick={() => navigate(`/`)} />
                <TopTitle Heading={'Basic'} />
            </Flex>
            <BasicTable />
        </div>
    )
}

export default Basic