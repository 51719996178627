import { Col, Form } from 'antd';
import React, { Fragment, useEffect, useState } from 'react'
import { Select } from '../../../Components/Form/Select';
import { CustomInputNumber } from '../../../Components/Form/CustomInputNumber';
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';
import { Row } from '../../../Components/Row';
import request from '../../../utils/request';
import Input from '../../../Components/Form/Input';
import { toast } from 'react-toastify';
import { Modal } from '../../../Components/Modal';


const EditLiabilities = ({ Libilititydatas, HandleGetAssets, GetTabelTypes, handleOks, handleCloseCancel, Getliabilities, getdata, setGetdata, selectedClient }) => {

    const [form] = Form.useForm();

    const [typeData, setTypeData] = useState([])


    const URL = 'liabilites/add_liabilities/'

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        if (Getliabilities) {
            Getliabilities(Libilititydatas);
        }
    };


    const onFinish = (values) => {

        const record = { ...values };
        let result = {
            current_balance: record.current_balance || 0,
            client_name: record.client_name,
            type: record.type,
            people: record.people,
            account: record.account,
            monthly_payments: record.monthly_payments
        }

        EditTableLiabilities(result, Libilititydatas.id)
        handleOk(false)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onReset = () => {
        handleOks()
        form.resetFields();
        Getliabilities();
        GetTypesData();
        HandleGetAssets();
    }

    //======================Client Name==========================//
    useEffect(() => {
        form.setFieldsValue({ client_name: selectedClient.person_name })
        form.setFieldsValue({ people: selectedClient.id })
    }, [selectedClient])

    useEffect(() => {
        setGetdata(getdata)
    }, [getdata])

    const Clientoption = [{ label: getdata?.person_name, value: getdata?.person_name }, { label: getdata?.partner_name, value: getdata?.partner_name }]
 
    const TypeOptionss = typeData?.map((com) => ({ label: com.type, value: com.type }));

    useEffect(() => {
        form.setFieldsValue(Libilititydatas)
    }, [Libilititydatas])

    //=====================Table edit form==========//
    const EditTableLiabilities = (record, id) => {
        request.put(`liabilites/liabilities_edit/${id}/`, record)
            .then(function (response) {
                toast.info('Updated Successfully')
                HandleGetAssets();
                handleCloseCancel()
                GetTypesData();
                GetTabelTypes();
                setIsModalOpen(false);


            })
            .catch(function (error) {
                console.log(error);
                // toast.error('Faild')
            });
    }
    //====================select option type get request===================//
    const GetTypesData = () => {
        request.get('liabilites/add_type/')
            .then(function (response) {
                setTypeData(response.data)

            })
            .catch(function (error) {
                console.error(error)
            })
    }
    useEffect(() => {
        GetTypesData();
    }, [])


    return (
        <div>
            <Fragment>
                <Form
                    form={form}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}

                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">
                    {/* <CustomCard> */}
                    <Row gutter={[12, 12]}>

                        <Col span={24} md={12}>

                            <Select label={'Client Name'}
                                options={Clientoption}
                                placeholder={'Client Name'} name={'client_name'}
                                disabled
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select the Client Name !'
                                    }
                                ]} />
                        </Col>
                        <Col span={24} md={12}>
                            <Select label={'Type'} options={TypeOptionss} name={'type'} placeholder={'Type'}
                                showSearch

                                rules={[
                                    {
                                        required: true,
                                        message: 'This is required field!'
                                    }
                                ]} />

                        </Col>

                        <Col span={24} md={12}>
                            <Input label={'Account'} placeholder={'Account'} name={'account'}
                            />
                        </Col>

                        <Input name={'people'} display={'none'} />

                        <Col span={24} md={12}>
                            <CustomInputNumber 
                             label={
                                <>
                    Monthly Payment <span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>
                                    
                                </>
                            }
                             placeholder={'Monthly Payment'} name={'monthly_payments'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This is Required field!',
                                    }
                                ]} />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={
                                    <>
                                        Current Balance <span style={{ fontSize: '20px', marginLeft: '5px' }}>$</span>

                                    </>
                                }
                                defaultValue={0} precision={2}
                                placeholder={'Current Balance'} name={'current_balance'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter Current Balance!',
                                    }
                                ]} />
                        </Col>
                    </Row>
                    <Flex center gap={'20px'} style={{ margin: '20px 0px' }}>
                        <Button.Primary text={'SAVE'} htmlType={'submit'} />
                        <Button.Danger text={'CANCEL'} onClick={() => onReset()} />
                    </Flex>
                </Form>
                <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={500} modalTitle={modalTitle} modalContent={modalContent} />
            </Fragment>

        </div>
    )
}

export default EditLiabilities