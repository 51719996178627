import { combineReducers } from 'redux'
import authReducer from '../Modules/Auth/reducers'
import CompanyProfileReducer from '../Modules/People/CompanyReducers'
import navreducers from '../Layout/partials/NavHeader/navreducers';
import reducerAssts from '../Modules/AddAssets/reducerAssts';
import PlanningReducers from '../Modules/PlannningDetailsTotal/PlanningReducers';

export default combineReducers({
  auth: authReducer,
  companyprofile:CompanyProfileReducer,
  planningrules:PlanningReducers,
  navigationheader:navreducers,
  tabledata : reducerAssts,
})
