import React from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import styled from 'styled-components';
import { THEME } from '../../theme';




const StyledButtons = styled.button`

  color:red ;
  border: none;
  border-radius: 8px;
  /* border: 1px solid #0c343d; */
  padding: 8px 12px;
  font-size: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.2s, transform 0.2s;

  &:hover {
    background-color: red ;
    transform: scale(1.05);
    color:white;
  }

  &:focus {
    outline: none;
  }
  & svg{
    font-size: 23px;
  }
`;

const ButtonText = styled.span`
  margin-left: 0px;
`;

const DeleteButton = ({ onClick,text }) => (
  <StyledButtons onClick={onClick}>
    <ButtonText>{text}</ButtonText>
  </StyledButtons>
);
export default DeleteButton;