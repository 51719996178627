import React from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import styled from 'styled-components';
import { THEME } from '../../theme';

const StyledButton = styled.button`
  color:#0c343d ;
  border: none;
  border-radius: 8px;
  font-size: 50px;
  background: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.2s, transform 0.2s;

  &:hover {
  
      transform: scale(1.5); /* Zoom in effect */
    
  }

  /* &:focus {
    outline: none;
  } */
`;

const ButtonText = styled.span`
  margin-right: 0px;
`;

const BackButton = ({ onClick,text }) => (
  <StyledButton onClick={onClick}>
    <ButtonText>{text}</ButtonText>
  </StyledButton>
);

export default BackButton;

