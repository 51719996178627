import React, { Fragment, useEffect, useLayoutEffect } from 'react'
import Routers from './routes'
import GlobalStyle from './theme/GlobalStyle';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SignInSuccess } from './Modules/Auth/actions';
import initializeApp from './utils/initializeApp';

const App = ({token}) => {

  const location = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])


  const dispatch = useDispatch();

  const Auth = localStorage.getItem("data")


  // if (Auth) {
  //   initializeApp(Auth)
  //   dispatch(SignInSuccess(Auth))
  // }
  
  useEffect(() => {
    initializeApp(token);
  }, [token])
  

  return (
    <Fragment>
      <GlobalStyle />
      <Routers token={token} />
      <ToastContainer />
    </Fragment>
  )
}

export default App
