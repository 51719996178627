import React, { useEffect, useState } from 'react'
import { CustomCard } from '../../Components/CustomCard'
import { TopTitle } from '../../Components/Form/TopTitle'
import ToalAssets from './Partials/ToalAssets'
import BarChart from './Partials/BarChart'
import request from '../../utils/request'

const TotalAssetsAllocation = () => {

    const [currentData, setCurrentData] = useState([])
    const [graphtData, setGraphtData] = useState([])


    const GETURL = 'my_plan/e_planning/'


    const GetPlanDetails = () => {
        request.get(`${GETURL}`)
            .then((response) => {
                setCurrentData(response.data?.Table)
                setGraphtData(response.data?.Graph)
                console.log(response.data,'gggggggggggggg')
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        GetPlanDetails()
    }, [])

    return (
        <div>
            <TopTitle Heading={'Total Assets Allocation'} />
            <BarChart graphtData={graphtData} /><br /><br />
            <ToalAssets currentData={currentData} />

        </div>
    )
}

export default TotalAssetsAllocation