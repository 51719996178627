import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import Flex from '../../../Components/Flex'
import Button from '../../../Components/Form/Button'
import { TopTitle } from '../../../Components/Form/TopTitle'
import { Modal } from '../../../Components/Modal'
import { Table } from '../../../Components/Table'
import request from '../../../utils/request'
import DeleteLiabilities from '../Partials/DeleteLiabilities'
import EditLiabilities from '../Partials/EditLiabilities'
import LiabilitiesFom from '../Partials/LiabilitiesForm'
import { Row } from '../../../Components/Row'
import { Col } from 'antd'
import Label from '../../../Components/Form/Label'
import Input from '../../../Components/Form/Input'
import { useNavigate, useParams } from 'react-router-dom'
import BackButton from '../../../Components/Form/BackButton'
import { FaArrowCircleLeft } from 'react-icons/fa'
import { customFormat } from '../../../utils/customFormat'


const ConsumerLiabilities = () => {

    const [tableData, setTableData] = useState([])

    const [getdata, setGetdata] = useState([])

    const [searchText, setSearchText] = useState([]);

    const [selectedClient, setSelectedClient] = useState({});
    const {id} = useParams();

    const URLS = `advisor/view_consumer_profile/${id}`
    const navigate = useNavigate();

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        HandleGetAssets();

    };

    const handleCloseCancel = () => {
        handleOk();
    }
    const handleSearch = (value) => {
        setSearchText(value);
    };

 
    const columns = [
        {
            title: 'SI No',
            render: (text, value, index) => index + 1,
        },
        {
            title: 'Client Name',
            dataIndex: 'client_name',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.client_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.client_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',

        },
        {
            title: 'Account',
            dataIndex: 'account',

        },
        {
            title: 'Monthly Payments',
            dataIndex: 'monthly_payments',
            render: (value) =>`$ ${customFormat(value)}` ,

        },
        {
            title: 'Current Balance',
            dataIndex: 'current_balance',
            render: (value) =>`$ ${customFormat(value)}` ,
        },
   

    ]

    const HandleGetAssets = () => {
        request.get(`${URLS}`)
            .then(function (response) {
                setTableData(response.data?.liability)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        HandleGetAssets();
    }, [])

    
    const Getliabilities = () => {
        request.get('assets/assets_people_values/')
            .then(function (response) {
                setGetdata(response.data)
                setSelectedClient(response.data)
            })
            .catch(function (error) {
                console.error(error)
            })
    }

    useEffect(() => {
        Getliabilities()
    }, [])

    return (
        <div>
               <Row>
                <Col span={24} md={8}>
                    <Flex gap={'15px'}>
                        <BackButton text={<FaArrowCircleLeft style={{fontSize:'26px'}}  />} onClick={() => navigate(`/View/${id}`)}  />
                        <TopTitle Heading={'Liabilities'} />
                    </Flex>
                </Col>
                <Col span={24} sm={10}  md={16}>
                    <Flex end centerVertically  >

                        <Label>Search by Client Name : &nbsp;&nbsp;</Label>
                        <Input
                            placeholder="Search by Client Name"
                            value={searchText}
                            onSearch={handleSearch}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </Flex>
                </Col>
            </Row>
            <Table columns={columns} data={tableData} />
            {/* <Button.Success text={'Back'} onClick={() => navigate(`/View/${id}`)} /> */}
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={500} modalTitle={modalTitle} modalContent={modalContent} />

        </div>
    )
}

export default ConsumerLiabilities